/* eslint-disable linebreak-style */
import defaultScreenPopSearchConfiguration from "./defaultConfiguration/screenPopSearch.json";
import LCIDUtils from "./language";
import { getScreenPopConfiguration, isConfigurationLoaded, needToPreventScreenPopOnDialing, screenpop, updateDisposition } from "./partner";
import { addDynamicsCrmURLtoParams, isSidebarHtmlLoad, shouldShowSoftphoneOnSingleTab, showMessagePanel, showSidebar } from "./root";
import { searchEntity } from "./support";
import { debounce, wait } from "./utils";
import Constant from "./variables/constants";
import FunctionsVariable from "./variables/functions";
import Global from "./variables/global";
import PartnerVariable from "./variables/partner";
import SupportVariable from "./variables/support";
import { compareVersions } from "compare-versions";
import $ from "jquery";
import { LRUCache } from "lru-cache";
import platformClient from "purecloud-platform-client-v2/dist/node/purecloud-platform-client-v2.js";
const clickToDialAssociationCache = new LRUCache({ max: 1000 });
function setScriptId() {
    FunctionsVariable.scriptId = s4();
    FunctionsVariable.logPostfix = " - IS Log (" + FunctionsVariable.scriptId + ") -";
}
setScriptId();
export function log(...args) {
    let LineNumber = new Error().stack.split("\n")[2].match(/:([0-9]+):/)[1];
    LineNumber = "[@LineNo. " + LineNumber + "]";
    const LOG_PREFIX = getCurrentTime() + FunctionsVariable.logPostfix;
    // 1. Prepend log prefix log string
    args.unshift(LOG_PREFIX + " ");
    args.push(LineNumber);
    args.unshift(args.pop());
    // 2. Pass along arguments to console.log
    console.log(...args);
}
export function isInternal(interaction) {
    const InteractionDirection = "internal";
    return (interaction.direction && interaction.direction.toString().trim().toLowerCase() == InteractionDirection) || (interaction.ani && interaction.ani.toString().trim().toLowerCase() == InteractionDirection && interaction.calledNumber && interaction.calledNumber.toString().trim().toLowerCase() == InteractionDirection && interaction.isInternal && interaction.queueName == undefined);
}
export function shouldCreateActivity(interaction) {
    if (interaction.attributes != undefined && interaction.attributes.is_activityid != undefined && interaction.attributes.is_activityid == "NONE") {
        return false;
    }
    else {
        return true;
    }
}
export function isEmailAddress(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email.match(re);
}
export function isFirefox() {
    return typeof window.InstallTrigger != "undefined";
}
export function isInternetExplorer() {
    const d = window.document;
    return !!window.MSInputMethodContext && !!d.documentMode;
}
function isEdge() {
    return /Edge/.test(navigator.userAgent);
}
export function getCurrentTime() {
    let a_p = "";
    const d = new Date();
    let curr_hour = d.getHours();
    if (curr_hour < 12) {
        a_p = "AM";
    }
    else {
        a_p = "PM";
    }
    if (curr_hour == 0) {
        curr_hour = 12;
    }
    if (curr_hour > 12) {
        curr_hour = curr_hour - 12;
    }
    let curr_min = d.getMinutes().toString();
    curr_min = curr_min + "";
    if (curr_min.length == 1) {
        curr_min = "0" + curr_min;
    }
    let curr_second = d.getSeconds().toString();
    curr_second = curr_second + "";
    if (curr_second.length == 1) {
        curr_second = "0" + curr_second;
    }
    let curr_ms = d.getMilliseconds().toString();
    curr_ms = curr_ms + "";
    if (curr_ms.length == 1) {
        curr_ms = "00" + curr_ms;
    }
    else if (curr_ms.length == 2) {
        curr_ms = "0" + curr_ms;
    }
    return curr_hour + ":" + curr_min + ":" + curr_second + "_" + curr_ms + " " + a_p;
}
function setActivityPartiesUpdate(associationObj, otherId, myId) {
    // directioncode = "true": Outbound
    // participationtypemake = 1: "From"
    // participationtypemake = 2: "To"
    const to = {};
    to["partyid_systemuser@odata.bind"] = "/systemusers(" + myId + ")";
    to["participationtypemask"] = associationObj.directioncode == "true" ? 1 : 2;
    if (otherId !== "") {
        const recordType = associationObj.assocEntityName.replace(/ /g, "").toLowerCase();
        const from = {};
        from["partyid_" + recordType + "@odata.bind"] = "/" + recordType + "s(" + otherId + ")";
        from["participationtypemask"] = associationObj.directioncode == "true" ? 2 : 1;
        return [to, from];
    }
    return [to];
}
function mergeProps(obj1, obj2) {
    const obj3 = {};
    for (const attrname in obj1) {
        obj3[attrname] = obj1[attrname];
    }
    for (const attrname in obj2) {
        obj3[attrname] = obj2[attrname];
    }
    return obj3;
}
export function setActivityParties(associationObj, entity, entityType) {
    const myId = getStorageItem(Constant.ssUserId);
    let otherId = "";
    if (associationObj.assocId != undefined && associationObj.assocId != null && associationObj.assocId != "{undefined}" && associationObj.assocId != "{}") {
        otherId = associationObj.assocId.replace(/[{}]/g, "");
    }
    try {
        entity[entityType + "_activity_parties"] = setActivityPartiesUpdate(associationObj, otherId, myId);
    }
    catch (e) {
        // nop
    }
}
function resetCustomAttributesValue(interactionId) {
    const index = FunctionsVariable.resetCustomAttributeValue.findIndex((x) => x.id === interactionId);
    if (index > -1) {
        FunctionsVariable.resetCustomAttributeValue.splice(index, 1);
    }
}
export function setMappingData(entity, interaction, mappingData, entitySchemaName) {
    for (let i = 0; i < mappingData.length; i++) {
        let interactionValue = null;
        const pureCloudFieldname = mappingData[i].pureCloudField;
        const pureCloudFieldnameLower = pureCloudFieldname.toLowerCase();
        const customAttribute = searchInJSON(FunctionsVariable.resetCustomAttributeValue, "id", interaction.id);
        if (interaction.attributes && Object.hasOwn(interaction.attributes, pureCloudFieldnameLower) && interaction.attributes[pureCloudFieldnameLower] && customAttribute.length == 0) {
            interactionValue = interaction.attributes[pureCloudFieldnameLower];
        }
        if (i == mappingData.length - 1 && interaction.attributes && customAttribute.length == 0) {
            const customAttributeValue = { id: null, value: false };
            customAttributeValue.value = true;
            customAttributeValue.id = interaction.id;
            FunctionsVariable.resetCustomAttributeValue.push(customAttributeValue);
        }
        if (Object.hasOwn(interaction, pureCloudFieldname) && interaction[pureCloudFieldname]) {
            interactionValue = interaction[pureCloudFieldname];
        }
        if (pureCloudFieldname == "formattedDuration" && interaction.interactionDurationSeconds) {
            interactionValue = getDurationIfMessagingInteraction(interaction, parseInt(interaction.interactionDurationSeconds));
        }
        if (pureCloudFieldname == "interactionUrl") {
            interactionValue = getInteractionURL() + interaction.id;
        }
        if (pureCloudFieldname == "screenrecordingUrl") {
            const screenRecordingUrl = getInteractionURL();
            interactionValue = screenRecordingUrl.split("/directory/#/engage/admin/interactions/")[0] + "/screen-recording-ui/#/interactions/" + interaction.id;
        }
        if (interactionValue) {
            const activityFieldName = mappingData[i].activityFields;
            const activityFieldType = mappingData[i].activityFieldsVal;
            const entitysetname = mappingData[i].lookupfieldentitysetname;
            if (activityFieldType.toLowerCase() == "lookup") {
                entity[mappingData[i].activityFields + "_" + entitySchemaName + "@odata.bind"] = conversionDataTypes(interactionValue, activityFieldType, activityFieldName, entitysetname);
            }
            else {
                entity[mappingData[i].activityFields.toLowerCase()] = conversionDataTypes(interactionValue, activityFieldType, activityFieldName, entitysetname);
            }
        }
    }
}
function saveEntity(entityType, entity) {
    const deferred = $.Deferred();
    createRecord(entityType, entity)
        .done(function (result) {
        deferred.resolve(result);
    })
        .fail(function (error) {
        deferred.reject(error);
    });
    return deferred.promise();
}
export function updateEntity(entityType, recordid, entity) {
    const deferred = $.Deferred();
    updateRecord(entityType, recordid, entity)
        .then(deferred.resolve, deferred.reject)
        .catch((e) => deferred.reject(e));
    return deferred.promise();
}
function reopenEmailIfClosed(entityId) {
    const deferred = $.Deferred();
    retrieveRecord("email", entityId, "?$select=statecode,statuscode")
        .done(function (result) {
        result = JSON.parse(result);
        const statuscode = result["statuscode"];
        if (result["statecode"] == 1) {
            //Email is currently closed.  Need to take back to draft
            const entity = {};
            entity.statecode = 0; //Open activity
            updateEntity("email", entityId, entity)
                .done(function () {
                log("reopened email activity:" + entityId);
                deferred.resolve(true, statuscode);
            })
                .fail(function (error) {
                error = returnErrorMessageForApiFailed(error);
                log(error);
                deferred.resolve(false, null);
            });
        }
        else {
            deferred.resolve(true, statuscode);
        }
    })
        .fail(function (error) {
        error = returnErrorMessageForApiFailed(error);
        log(error);
    });
    return deferred.promise();
}
export function setRegardingRelationship(associationObj, entity) {
    if (associationObj.regardingid != "{undefined}" && associationObj.regardingid != "{}" && associationObj.regardingid != "") {
        const regardingid = associationObj.regardingid.replace(/[{}]/g, "");
        const associationJson = getAssociationJson();
        if (associationJson.length > 0) {
            setLastAssociationObjInSessionStorage(associationObj);
            const findEntity = searchInJSON(associationJson, "entitylogicalname", associationObj.regardingentityname.toString().trim());
            if (findEntity.length > 0)
                entity["regardingobjectid_" + findEntity[0].entitylogicalname + "@odata.bind"] = "/" + findEntity[0].entitylogicalcollectionname + "(" + regardingid + ")";
        }
    }
    else {
        if (associationObj.regardingid == "{undefined}" && getEntityTypeByInteractionIdForDisAssociation(associationObj))
            entity["regardingobjectid_" + getEntityTypeByInteractionIdForDisAssociation(associationObj) + "@odata.bind"] = null;
    }
}
function searchInContact(searchString) {
    const deferred = $.Deferred();
    const searchIS = encodeSearchString(searchString);
    const searchOnly = true;
    // In this sample, we are retrieving the 'fullname' attribute of the record
    let query = "?$select=emailaddress1,fullname,mobilephone,telephone1,telephone2,telephone3&$filter=";
    query += "contains(fullname, '" + searchIS + "') or contains(mobilephone, '" + searchIS + "') or contains(telephone1, '" + searchIS + "') or contains(telephone2, '" + searchIS + "') or contains(telephone3, '" + searchIS + "')&$top=5";
    searchAndOpenRecords("contact", query, searchOnly)
        .done(function (results) {
        if (results != "{}") {
            const resultArr = [];
            results = JSON.parse(results);
            for (let i = 0; i < Object.keys(results).length; i++) {
                const fullname = results[i]["fullname"];
                const mobilephone = results[i]["mobilephone"];
                const telephone1 = results[i]["telephone1"];
                const telephone2 = results[i]["telephone2"];
                const telephone3 = results[i]["telephone3"];
                const contactid = results[i]["contactid"];
                resultArr.push(filterResultData(fullname, "contact", contactid, mobilephone, telephone1, telephone2, telephone3));
            }
            deferred.resolve(resultArr);
        }
        else {
            deferred.resolve([]);
        }
    })
        .fail(function (error) {
        error = returnErrorMessageForApiFailed(error);
        log(error);
        deferred.resolve(0);
    });
    return deferred.promise();
}
function searchInSystemUser(searchString) {
    const deferred = $.Deferred();
    const searchIS = encodeSearchString(searchString);
    const searchOnly = true;
    let query = "?$select=address1_telephone1,fullname,homephone,mobilephone&$filter="; //In this sample, we are retrieving the 'fullname' attribute of the record
    query += "contains(fullname, '" + searchIS + "') or contains(homephone, '" + searchIS + "') or contains(mobilephone, '" + searchIS + "') or contains(address1_telephone1, '" + searchIS + "')&$top=5";
    searchAndOpenRecords("systemuser", query, searchOnly)
        .done(function (results) {
        if (results != "{}") {
            const resultArr = [];
            results = JSON.parse(results);
            for (let i = 0; i < Object.keys(results).length; i++) {
                const address1_telephone1 = results[i]["address1_telephone1"];
                const fullname = results[i]["fullname"];
                const homephone = results[i]["homephone"];
                const mobilephone = results[i]["mobilephone"];
                const systemuserid = results[i]["systemuserid"];
                resultArr.push(filterResultData(fullname, "systemuser", systemuserid, mobilephone, homephone, address1_telephone1, null));
            }
            deferred.resolve(resultArr);
        }
        else {
            deferred.resolve([]);
        }
    })
        .fail(function (error) {
        error = returnErrorMessageForApiFailed(error);
        log(error);
        deferred.resolve(0);
    });
    return deferred.promise();
}
function searchInLead(searchString) {
    const deferred = $.Deferred();
    const searchIS = encodeSearchString(searchString);
    const searchOnly = true;
    // In this sample, we are retrieving the 'fullname' attribute of the record
    let query = "?$select=fullname,mobilephone,telephone1,telephone2,telephone3&$filter=";
    query += "statecode eq 0 and (contains(telephone1,'" + searchIS + "') or contains(mobilephone,'" + searchIS + "') or contains(telephone2,'" + searchIS + "') or contains(telephone3,'" + searchIS + "') or contains(fullname,'" + searchIS + "'))&$top=5";
    searchAndOpenRecords("lead", query, searchOnly)
        .done(function (results) {
        if (results != "{}") {
            const resultArr = [];
            results = JSON.parse(results);
            for (let i = 0; i < Object.keys(results).length; i++) {
                const fullname = results[i]["fullname"];
                const leadid = results[i]["leadid"];
                const mobilephone = results[i]["mobilephone"];
                const telephone1 = results[i]["telephone1"];
                const telephone2 = results[i]["telephone2"];
                const telephone3 = results[i]["telephone3"];
                resultArr.push(filterResultData(fullname, "lead", leadid, mobilephone, telephone1, telephone2, telephone3));
            }
            deferred.resolve(resultArr);
        }
        else {
            deferred.resolve([]);
        }
    })
        .fail(function (error) {
        error = returnErrorMessageForApiFailed(error);
        log(error);
        deferred.resolve(0);
    });
    return deferred.promise();
}
function searchInAccount(searchString) {
    const deferred = $.Deferred();
    const searchIS = encodeSearchString(searchString);
    const searchOnly = true;
    // In this sample, we are retrieving the 'fullname' attribute of the record
    let query = "?$select=name,telephone1,telephone2,telephone3&$filter=";
    query += "contains(name, '" + searchIS + "') or contains(telephone1, '" + searchIS + "') or contains(telephone2, '" + searchIS + "') or contains(telephone3, '" + searchIS + "')&$top=5";
    searchAndOpenRecords("account", query, searchOnly)
        .done(function (results) {
        if (results != "{}") {
            const resultArr = [];
            results = JSON.parse(results);
            for (let i = 0; i < Object.keys(results).length; i++) {
                const name = results[i]["name"];
                const telephone1 = results[i]["telephone1"];
                const telephone2 = results[i]["telephone2"];
                const telephone3 = results[i]["telephone3"];
                const accountid = results[i]["accountid"];
                resultArr.push(filterResultData(name, "account", accountid, null, telephone1, telephone2, telephone3));
            }
            deferred.resolve(resultArr);
        }
        else {
            deferred.resolve([]);
        }
    })
        .fail(function (error) {
        error = returnErrorMessageForApiFailed(error);
        log(error);
        deferred.resolve(0);
    });
    return deferred.promise();
}
function showResultToSidebar(searchString, searchResultArry) {
    checkValueTypeSearchResultArry(searchResultArry);
    const callbackObject = {
        messageType: "contactSearch",
        searchValue: searchString,
        contactSearchArray: searchResultArry
    };
    FunctionsVariable.makeCallFromScriptId = FunctionsVariable.scriptId;
    sendPostMessage(callbackObject);
}
function getPostMessageTarget() {
    if (document.getElementById(Constant.isyncCIF)) {
        const e = document.getElementById(Constant.isyncCIF);
        return e.contentWindow;
    }
    else {
        return undefined;
    }
}
function sendPostMessage(data) {
    if (getPostMessageTarget() != undefined) {
        getPostMessageTarget().postMessage(data, "*");
    }
}
export function queuePostMessage(data) {
    data.key = newGuid();
    data.count = 0;
    let messages = getStorageItem(Constant.ssPostMessages);
    if (messages != null)
        messages = JSON.parse(messages);
    else
        messages = {};
    messages[data.key] = data;
    setStorageItem(Constant.ssPostMessages, JSON.stringify(messages));
    sendQueuedPostMessages();
}
export function removeOutgoingPostMessage(key) {
    let messages = getStorageItem(Constant.ssPostMessages);
    if (messages == null)
        return;
    messages = JSON.parse(messages);
    delete messages[key];
    setStorageItem(Constant.ssPostMessages, JSON.stringify(messages));
}
function startPostMessageQueue() {
    if (FunctionsVariable.postMessageTimeoutId !== null)
        return;
    FunctionsVariable.postMessageTimeoutId = setTimeout(function () {
        FunctionsVariable.postMessageTimeoutId = null;
        sendQueuedPostMessages();
    }, 1000);
}
function sendQueuedPostMessages() {
    let messages = getStorageItem(Constant.ssPostMessages);
    if (messages == null) {
        return;
    }
    let updateList = false;
    messages = JSON.parse(messages);
    for (const key in messages) {
        const item = messages[key];
        sendPostMessage(item);
        item.count++;
        messages[key] = item;
        if (item.count > 20) {
            updateList = true;
            log("sendQueuedPostMessages, removing msg from queue:" + item.messageType + " key:" + item.key);
            delete messages[key];
        }
    }
    if (updateList) {
        setStorageItem(Constant.ssPostMessages, JSON.stringify(messages));
    }
    if (Object.keys(messages).length > 0) {
        startPostMessageQueue();
    }
}
export function sendAck(key) {
    const keydata = {
        messageType: "ack",
        key: key
    };
    sendPostMessage(keydata);
}
function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}
function newGuid() {
    return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
}
export function addAssociation() {
    getEnvironment()
        .done(function (data) {
        if (doesPrerequisiteAvailableForAssociation(data)) {
            if (isSidebarHtmlLoad()) {
                if (isCIFV2()) {
                    executeAssociation(data, null, null);
                    checkAndManageSessionTabArrayForWST(data);
                }
                else if (isAssociationExistForCurrentTab(data)) {
                    executeAssociation(data, null, null);
                }
            }
            else {
                sendCurrentPageToMainTab(data);
            }
        }
        else {
            shouldCallAddAssociationAgain();
        }
    })
        .fail(function () {
        shouldCallAddAssociationAgain();
    });
    return false;
}
function shouldCallAddAssociationAgain() {
    if (FunctionsVariable.flagNewRecordPop) {
        return;
    }
    let returnValue = false;
    setTimeout(() => {
        if (FunctionsVariable.checkCountAddAssociation < 10) {
            addAssociation();
            returnValue = true;
        }
        FunctionsVariable.checkCountAddAssociation++;
    }, 1000);
    return returnValue;
}
export function shouldPreventLogUpdateAfterWrapUp(interactionData, eventName) {
    eventName = eventName.toString().trim().toLowerCase();
    const closeActivitiesOnEvent = getSettingFromConfig("closeactivitiesonevent");
    const closeOnWrap = closeActivitiesOnEvent && closeActivitiesOnEvent.toString().trim().toLowerCase() === "wrap";
    const dispositionSet = interactionData.disposition && interactionData.disposition.toString().length > 0;
    const isCallback = interactionData?.isCallback || false;
    const isInteractionDiconnected = eventName === "interactiondisconnected";
    const isOpenCallLog = eventName === "opencalllog";
    const isInteractionChanged = eventName === "interactionchanged";
    const isInteractionRemoved = eventName === "interactionremoved";
    const isInteractionChangedAfterDisconnect = eventName === "interactionchangedafterdisconnect";
    const isInteractionChangedAfterDisconnected = eventName === "interactionchangedafterdisconnected";
    return closeOnWrap && dispositionSet && (isOpenCallLog || isInteractionChanged || isInteractionDiconnected || isInteractionRemoved || isInteractionChangedAfterDisconnect || isInteractionChangedAfterDisconnected) && isCallback;
}
export function shouldScreenPopInternalInteractions() {
    const screenPopForInternal = getSettingFromConfig("screenpopforinternalcalls");
    return screenPopForInternal == true || screenPopForInternal == "true";
}
export function shouldCreateActivityOnUserSelection() {
    const interactionactivities = getSettingFromConfig("interactionactivities");
    return interactionactivities != null && interactionactivities == "User Selected";
}
function shouldAutoAssociate() {
    return JSON.parse(getParticipantCustomAttribute("autoAssociate"));
}
function getFullClientURL() {
    return getParticipantCustomAttribute("fullclient");
}
export function shouldIsDirtyScreenPopOnNewTab() {
    const screenPopNewTabIsDirty = getSettingFromConfig("screenpopnewtabisdirty");
    return screenPopNewTabIsDirty == true || screenPopNewTabIsDirty == "true";
}
export function shouldHideSoftphone() {
    const minimizesSftphoneDefault = getSettingFromConfig("minimizesoftphonedefault");
    const enableMinimizeSoftphone = getSettingFromConfig("minimizesoftphonedefaultforV2");
    const enableMinimizeSoftphoneCheck = enableMinimizeSoftphone && enableMinimizeSoftphone.toString().replace(/\s/g, "").toLowerCase() === "defaultforallusers";
    if (minimizesSftphoneDefault || enableMinimizeSoftphoneCheck) {
        return true;
    }
}
export function shouldHideSoftphoneInteraction() {
    const minimizeSoftphoneSllTabs = getSettingFromConfig("minimizesoftphonealltabs");
    return minimizeSoftphoneSllTabs == true || minimizeSoftphoneSllTabs == "true";
}
export function enableMinimizeSoftphoneChecbox() {
    const enableMinimizeSoftphone = getSettingFromConfig("minimizesoftphonedefaultforV2");
    const enableMinimizeSoftphoneCheck = enableMinimizeSoftphone && enableMinimizeSoftphone.toString().replace(/\s/g, "").toLowerCase() === "agentconfigurableoption";
    return enableMinimizeSoftphoneCheck;
}
export function getSettingFromConfig(settingName) {
    if (isConfigurationLoaded()) {
        const jsonSettingData = getParticipantCustomAttribute("settingsJson");
        if (jsonSettingData) {
            const settingJsonValue = JSON.parse(jsonSettingData);
            if (Object.hasOwn(settingJsonValue, settingName)) {
                return settingJsonValue[settingName];
            }
        }
    }
    return null;
}
export function getCurrentInteractionId() {
    const currentInteraction = getStorageItem(Constant.lsCurrentInteractionId);
    if (currentInteraction != null && currentInteraction != undefined && currentInteraction != "null" && currentInteraction != "undefined") {
        return currentInteraction;
    }
    return null;
}
export function setCurrentInteractionId(interactionId) {
    if (interactionId == "" || interactionId == undefined || interactionId == null) {
        interactionId = null;
    }
    setStorageItem(Constant.lsCurrentInteractionId, interactionId);
}
export function getDefaultQueueId() {
    const defaultqueueid = getSettingFromConfig("defaultqueueid");
    return defaultqueueid != "" ? defaultqueueid : null;
}
function getNewInteractionId() {
    return getStorageItem(Constant.lsNewInteractionId);
}
export function setNewInteractionId(interactionId) {
    setStorageItem(Constant.lsNewInteractionId, interactionId);
}
function removeNewInteractionId() {
    removeStorageItem(Constant.lsNewInteractionId);
}
export function setActivityData(activityId, interactionId) {
    if (interactionId == null || interactionId == undefined) {
        interactionId = getCurrentInteractionId();
    }
    setItemDataOnInteractionId(activityId, interactionId, Constant.lsActivityData);
}
export function getActivityData(interactionId) {
    return getItemBasedOnInteractionId(interactionId, Constant.lsActivityData);
}
function removeActivityData(interactionId) {
    removeItemDataOnInteractionId(interactionId, Constant.lsActivityData);
}
function setActivityEntityCache(activityEntity, interactionId) {
    const currentActivityCache = getActivityEntityCache(interactionId);
    let combinedActivityCache;
    if (currentActivityCache != null) {
        combinedActivityCache = $.extend({}, currentActivityCache, activityEntity);
    }
    else {
        combinedActivityCache = activityEntity;
    }
    setItemDataOnInteractionId(combinedActivityCache, interactionId, Constant.lsActivityEntityCache);
}
export function getActivityEntityCache(interactionId) {
    return getItemBasedOnInteractionId(interactionId, Constant.lsActivityEntityCache);
}
function removeEntityCache(interactionId) {
    removeItemDataOnInteractionId(interactionId, Constant.lsActivityEntityCache);
}
function setActivityChatTranscriptCache(activityEntity, interactionId) {
    const currentActivityCache = getActivityChatTranscriptCache(interactionId);
    let combinedActivityCache;
    if (currentActivityCache != null) {
        combinedActivityCache = $.extend({}, currentActivityCache, activityEntity);
    }
    else {
        combinedActivityCache = activityEntity;
    }
    setItemDataOnInteractionId(combinedActivityCache, interactionId, Constant.ssActivityChatTranscriptCache);
}
function getActivityChatTranscriptCache(interactionId) {
    return getItemBasedOnInteractionId(interactionId, Constant.ssActivityChatTranscriptCache);
}
function removeChatTranscriptCache(interactionId) {
    removeItemDataOnInteractionId(interactionId, Constant.ssActivityChatTranscriptCache);
}
function hasHandledChatTranscriptBasedOnInteractionId(interactionId) {
    return getItemBasedOnInteractionId(interactionId, Constant.ssHasChatTranscript);
}
function removeHasHandledChatTranscript(interactionId) {
    removeItemDataOnInteractionId(interactionId, Constant.ssHasChatTranscript);
}
export function setItemDataOnInteractionId(itemDataObject, interactionId, ssItem) {
    if (itemDataObject == undefined || itemDataObject == null) {
        itemDataObject = "";
    }
    const itemDataObjectNew = {
        interactionId: interactionId,
        data: itemDataObject
    };
    removeItemDataOnInteractionId(interactionId, ssItem);
    const ssItemData = getStorageItem(ssItem);
    let itemDataList = [];
    if (ssItemData != null) {
        itemDataList = JSON.parse(ssItemData);
    }
    itemDataList.push(itemDataObjectNew);
    setStorageItem(ssItem, JSON.stringify(itemDataList));
}
export function getItemBasedOnInteractionId(interactionId, ssItem) {
    const ssItemData = getStorageItem(ssItem);
    if (ssItemData == null || ssItemData == "[]")
        return null;
    const itemDataList = JSON.parse(ssItemData);
    const result = itemDataList.filter((obj) => {
        return obj.interactionId === interactionId;
    });
    if (result.length >= 1) {
        return result[0].data;
    }
    return null;
}
export function removeItemDataOnInteractionId(interactionId, ssItem) {
    const ssItemData = getStorageItem(ssItem);
    if (ssItemData == null || ssItemData == "[]")
        return;
    const itemDataList = JSON.parse(ssItemData);
    const result = itemDataList.filter((obj) => {
        return obj.interactionId === interactionId;
    });
    if (result.length >= 1) {
        itemDataList.splice(itemDataList.indexOf(result[0]), 1);
        setStorageItem(ssItem, JSON.stringify(itemDataList));
    }
}
export function setStorageItem(item, value) {
    if (isLocalStorageItem(item)) {
        localStorage.setItem(item, value);
    }
    else {
        sessionStorage.setItem(item, value);
    }
}
export function getStorageItem(item) {
    let itemData = null;
    if (isLocalStorageItem(item)) {
        itemData = localStorage.getItem(item);
    }
    else {
        itemData = sessionStorage.getItem(item);
    }
    if (itemData == "null" || itemData == "undefined" || itemData == null || itemData == undefined) {
        itemData = null;
    }
    return itemData;
}
export function removeStorageItem(item) {
    if (isLocalStorageItem(item)) {
        localStorage.removeItem(item);
    }
    else {
        sessionStorage.removeItem(item);
    }
}
export function openEntityForm(entityName, guid, newPage, interaction) {
    const entityFormOptions = {};
    entityFormOptions["entityName"] = entityName;
    if (guid != undefined && guid != "") {
        entityFormOptions["entityId"] = guid;
    }
    openForm(entityFormOptions, null, newPage, interaction);
}
export function miniClientTabOpen(shouldShowIsyncIframe) {
    if (isConfigurationLoaded()) {
        const softphoneWidth = getSettingFromConfig("softphoneWidth");
        if (softphoneWidth) {
            window.Microsoft.CIFramework.setWidth(softphoneWidth);
        }
        if (getStorageItem(Constant.lsCheckSetModeValue) != "0") {
            setSoftPhonePanelMode(1);
        }
        else {
            if (!shouldShowIsyncIframe) {
                showHideEmbeddedClient(false, false);
            }
        }
    }
}
export function upsertActivity(interaction, entityType, entityId, entityData, forceSaveFromCache) {
    const deferred = $.Deferred();
    const currentActivityAssociation = getActivityData(interaction.id);
    if (shouldCreateActivityOnUserSelection() && (currentActivityAssociation == null || currentActivityAssociation.includes(interaction.id))) {
        //User selected activity configuration and the activity hasn't yet been created so just update the cache
        setActivityEntityCache(entityData, interaction.id);
        if (interaction.chatTranscript) {
            setActivityChatTranscriptCache(interaction.chatTranscript, interaction.id);
        }
        if (forceSaveFromCache) {
            saveActivity(interaction.id, entityType, getActivityEntityCache(interaction.id))
                .done(function (activityData) {
                if (shouldSaveTranscript(interaction)) {
                    saveChatOrMessagingTranscriptFromCache(activityData, interaction);
                }
                deferred.resolve(activityData);
            })
                .fail(function (error) {
                deferred.reject(error);
            });
        }
        else {
            deferred.resolve(entityType + "|" + interaction.id);
        }
    }
    else if (entityId != null) {
        log("current activity entityId  is : " + entityId);
        if (entityData.description != null) {
            log("Description notes value is : " + entityData.description);
            // Taks:15784,15787(CIF)
            //setDescription(entityType, entityId, interaction.id, entityData.description).done(function (Description) {
            //    entityData.description = Description;
            updateActivity(entityType, entityId, entityData)
                .done(function (activityData) {
                deferred.resolve(activityData);
            })
                .fail(function (error) {
                deferred.reject(error);
            });
            //});
        }
        else {
            updateActivity(entityType, entityId, entityData)
                .done(function (activityData) {
                deferred.resolve(activityData);
            })
                .fail(function (error) {
                deferred.reject(error);
            });
        }
    }
    else {
        saveActivity(interaction.id, entityType, entityData)
            .done(function (activityData) {
            deferred.resolve(activityData);
        })
            .fail(function (error) {
            deferred.reject(error);
        });
    }
    return deferred.promise();
}
export function saveChatTranscriptToActivity(interaction, activityId) {
    const deferred = $.Deferred();
    if (interaction.chatTranscript && shouldSaveTranscript(interaction)) {
        if (activityId == interaction.id) {
            deferred.resolve();
        }
        let content = "";
        const messages = interaction.chatTranscript.messages;
        if (messages.length > 0 && hasHandledChatTranscriptBasedOnInteractionId(interaction.id) == null) {
            for (let i = 0; i < messages.length; i++) {
                content = content + messages[i].name + ": " + messages[i].body + "\n";
            }
            createMessagingTranscriptForSaveActivity(content, activityId, interaction)
                .done(function (result) {
                deferred.resolve(result);
            })
                .fail(function (error) {
                deferred.reject(error);
            });
        }
        else {
            deferred.resolve();
        }
    }
    else {
        deferred.resolve();
    }
    return deferred.promise();
}
function getEntityById(entitySetName, id, selectFields) {
    let select = "";
    const deferred = $.Deferred();
    if (selectFields.length > 0)
        select = "?$select=" + selectFields;
    retrieveRecord(entitySetName, id, select)
        .done(function (result) {
        let fieldVal = "";
        result = JSON.parse(result);
        if (result[selectFields] != null) {
            fieldVal = result[selectFields] + " - ";
        }
        deferred.resolve(fieldVal + entitySetName);
    })
        .fail(function (error) {
        deferred.reject(error);
    });
    return deferred.promise();
}
export function fetchItemsFromGetEnvironmentForLogs() {
    getEnvironment().done(function (data) {
        data = JSON.parse(data);
        setStorageItem(Constant.ssUserId, data.userId.slice(1, -1));
        setStorageItem(Constant.ssOrgName, data.orgUniqueName);
        setStorageItem(Constant.ssURL, data.clientUrl);
    });
}
export function onPageNavigate(eventData) {
    log("onPageNavigate:" + eventData);
    try {
        FunctionsVariable.checkCountAddAssociation = 0;
        if (isJSON(eventData)) {
            eventData = JSON.parse(eventData).value;
        }
        if (eventData && eventData.includes("etn=") && eventData.includes("&id=")) {
            executeAssociationBasedOnEventDataValue(eventData);
            if (isCIFV2()) {
                updateSessionMappingTabArrayBasedOnEventData(eventData);
            }
        }
        else {
            addAssociation();
        }
    }
    catch (e) {
        log(e.message);
    }
    return Promise.resolve();
}
export function onModeChanged(eventData) {
    const eventDataJSON = JSON.parse(eventData);
    setStorageItem(Constant.lsCheckSetModeValue, eventDataJSON.value);
    if (isCIFV2() && eventDataJSON && eventDataJSON.value == 2) {
        setSoftPhonePanelMode(1);
    }
    else if (eventDataJSON && eventDataJSON.value == 1) {
        showHideEmbeddedClient(true, true);
    }
    else {
        showHideEmbeddedClient(false, false);
        closeDialpad(0);
    }
    return Promise.resolve();
}
export function onClickToAct(data) {
    log("Triggered onClickToAct, data : " + JSON.stringify(data));
    removeAssociationFromSessionForCTD();
    data = JSON.parse(data);
    const entityName = data.entityLogicalName;
    let query = "";
    const clickToDialFromClosedActivities = getSettingFromConfig("clicktodialfromclosedactivities");
    const attributes = data;
    let number = data.value;
    let queue = null;
    if (data.queueId)
        queue = data.queueId;
    if (data.entityLogicalName && data.entityId) {
        attributes["IS_EntityType"] = data.entityLogicalName;
        attributes["IS_EntityId"] = data.entityId;
        attributes["IS_OutboundCheckForAssociation"] = true;
    }
    if (data && Object.hasOwn(data, "IS_SearchPop")) {
        attributes.IS_SearchPop = data.IS_SearchPop;
    }
    else {
        attributes["IS_SearchPop"] = "N";
    }
    fillNumberAndQueueOutboundCall(number, queue);
    if (isRunningOnUSD()) {
        number = number.split(":")[1];
        FunctionsVariable.isClick2Act = true;
    }
    getEntityMetadata(entityName, "")
        .then(function (result) {
        if (result) {
            const isActivity = result.IsActivity;
            if (isActivity) {
                query = "?$select=statecode,activityid &$filter= activityid eq '" + data.entityId + "' and statecode eq 1";
            }
            else {
                query = "?$select=*&$top=1";
            }
            searchAndOpenRecords(data.entityLogicalName, query, true)
                .done(function (value) {
                if (value && value != "{}" && clickToDialFromClosedActivities == false && isActivity) {
                    sendNotification("Cannot initiate a call from closed activities.", "error");
                    return;
                }
                else {
                    makeCall(number, attributes);
                    return Promise.resolve();
                }
            })
                .fail(function (error) {
                error = returnErrorMessageForApiFailed(error);
                log(error);
            });
        }
    })
        .fail(function (error) {
        log(error);
    });
}
export function makeCall(number, attributes, isStorageHandlerRequest, currentRequestID) {
    log("Invoked makeCall, number : " + number + ", attributes : " + JSON.stringify(attributes) + ", isStorageHandlerRequest : " + isStorageHandlerRequest + ", currentRequestID : " + currentRequestID);
    miniClientTabOpen(true);
    if (isSidebarHtmlLoad()) {
        setSoftPhonePanelMode(1);
        let isValidCallRequest = true;
        if (isStorageHandlerRequest === true) {
            const lastRequestID = getStorageItem(Constant.ssLastRequestID);
            if (lastRequestID != null && lastRequestID != undefined) {
                if (lastRequestID != currentRequestID) {
                    setLastCallRequestID(currentRequestID);
                }
                else {
                    isValidCallRequest = false;
                }
            }
            else {
                setLastCallRequestID(currentRequestID);
            }
        }
        if (isValidCallRequest) {
            miniClientTabOpen(true);
            const dialData = {
                messageType: "clickToDial",
                number: number,
                autoPlace: true,
                attributes: attributes
            };
            if (getDefaultQueueId()) {
                dialData["queueId"] = getDefaultQueueId();
            }
            FunctionsVariable.makeCallFromScriptId = FunctionsVariable.scriptId;
            log("Sending PostMessage to Framework.js from makeCall for making a call, dialData : " + JSON.stringify(dialData));
            sendPostMessage(dialData);
        }
        return false;
    }
    else {
        getEnvironment().done(function (data) {
            data = JSON.parse(data);
            const newRequestID = Math.floor(100000 + Math.random() * 900000);
            const data2 = {
                number: number,
                pageInfo: {
                    etn: data.etn,
                    id: data.id
                },
                callRequestID: newRequestID
            };
            setStorageItem(Constant.lsSendMakeCallToSoftphoneTab, JSON.stringify(data2));
            return false;
        });
    }
}
function secondsToTime(duration) {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration - hours * 3600) / 60);
    let seconds = duration - hours * 3600 - minutes * 60;
    seconds = Math.round(seconds * 100) / 100;
    let result = hours < 10 ? "0" + hours : hours;
    result += ":" + (minutes < 10 ? "0" + minutes : minutes);
    result += ":" + (seconds < 10 ? "0" + seconds : seconds);
    return result;
}
export function searchAndOpenRecords(entityname, query, searchOnly) {
    const deferred = $.Deferred();
    try {
        window.Microsoft.CIFramework.searchAndOpenRecords(entityname, query, searchOnly).then(function (results) {
            deferred.resolve(results);
        }, function (error) {
            log("searchAndOpenRecords failed: " + entityname + " query:" + query + " error:" + returnErrorMessageForApiFailed(error));
            return deferred.reject(error);
        });
    }
    catch (ex) {
        deferred.reject(ex.message);
    }
    return deferred.promise();
}
export function openForm(entityFormOptions, formParameters, newPage, interaction) {
    if (newPage) {
        setStorageItem(Constant.lsPopNewPage, newPage);
        entityFormOptions.openInNewWindow = true;
        setShouldCallAssociation();
    }
    if (formParameters === null) {
        window.Microsoft.CIFramework.openForm(JSON.stringify(entityFormOptions)).then(function () {
            if (interaction != null && !isPopFromOptionHasOpenInNewWindow(interaction) && !shouldScreenPopInNewPage(interaction)) {
                if (!newPage) {
                    if (!entityFormOptions && Object.hasOwn(entityFormOptions, "useQuickCreateForm")) {
                        addAssociation();
                    }
                }
            }
            removeRenderSearchCache();
        }, function (error) {
            log("openForm failed: " + JSON.stringify(entityFormOptions) + " error:" + returnErrorMessageForApiFailed(error));
        });
    }
    else if (entityFormOptions && formParameters) {
        checkInteractionHaveInternalValue(formParameters, interaction);
        window.Microsoft.CIFramework.openForm(JSON.stringify(entityFormOptions), JSON.stringify(formParameters)).then(function () {
            if (interaction != null && !isPopFromOptionHasOpenInNewWindow(interaction) && !shouldScreenPopInNewPage(interaction)) {
                if (!newPage) {
                    if (!entityFormOptions && Object.hasOwn(entityFormOptions, "useQuickCreateForm")) {
                        addAssociation();
                    }
                }
            }
            removeRenderSearchCache();
        }, function (error) {
            log("openForm failed: " + JSON.stringify(entityFormOptions) + " error:" + returnErrorMessageForApiFailed(error));
        });
    }
}
export function getEnvironment() {
    const deferred = $.Deferred();
    try {
        window.Microsoft.CIFramework.getEnvironment().then(function (results) {
            deferred.resolve(results);
        }, function (error) {
            log("getEnvironment failed: " + returnErrorMessageForApiFailed(error));
            return deferred.reject(error);
        });
    }
    catch (ex) {
        deferred.reject(ex.message);
    }
    return deferred.promise();
}
export function getUserDynamicsInstanceInformation() {
    getEnvironment().done(function (data) {
        data = JSON.parse(data);
        setStorageItem(Constant.ssUserId, data.userId.slice(1, -1));
        setStorageItem(Constant.ssOrgName, data.orgUniqueName);
        setStorageItem(Constant.ssURL, data.clientUrl);
    });
}
function retrieveRecord(entityName, guidId, query) {
    const deferred = $.Deferred();
    window.Microsoft.CIFramework.retrieveRecord(entityName, guidId, query).then(function (results) {
        log("retrieveRecord success. entity:" + results);
        deferred.resolve(results);
    }, function (error) {
        error = "retrieveRecord failed. entity:" + entityName + " id:" + guidId + " query:" + query + " error:" + returnErrorMessageForApiFailed(error);
        log(error);
        return deferred.reject(error);
    });
    return deferred.promise();
}
function sendAddAssociation(associationType, entityId, text, interactionId) {
    if (interactionId !== null) {
        const callbackObject = {
            messageType: "addAssociation",
            id: entityId,
            type: associationType,
            text: text,
            select: shouldAutoAssociate(),
            interactionId: interactionId
        };
        if (isCIFV2()) {
            let sessionInteractionMapping = getStorageItem(Constant.ssSessionMapping);
            if (sessionInteractionMapping && sessionInteractionMapping != "[]") {
                sessionInteractionMapping = JSON.parse(sessionInteractionMapping);
                getFocusedSession().done(function success(result) {
                    if (result) {
                        let isPresent = null;
                        if (FunctionsVariable.currentSession == result) {
                            isPresent = searchInJSON(sessionInteractionMapping, "sessionId", FunctionsVariable.currentSession);
                        }
                        else {
                            isPresent = searchInJSON(sessionInteractionMapping, "sessionId", result);
                        }
                        if (isPresent && isPresent.length > 0) {
                            callbackObject.interactionId = isPresent[0].interactionId;
                        }
                        else {
                            callbackObject.interactionId = interactionId;
                        }
                        log("sendAddAssociation. associationType:" + associationType + " entityid:" + entityId + " interaction:" + interactionId);
                        queuePostMessage(callbackObject);
                        if (associationType == "relation") {
                            currentInteractionAssociation(interactionId, null, null, JSON.stringify(callbackObject));
                        }
                    }
                });
            }
            else if (sessionInteractionMapping && sessionInteractionMapping == "[]") {
                sessionInteractionMapping = JSON.parse(sessionInteractionMapping);
                const isSessionIdPresent = searchInJSON(sessionInteractionMapping, "interactionId", interactionId);
                if (isSessionIdPresent.length == 0) {
                    log("sendAddAssociation. associationType:" + associationType + " entityid:" + entityId + " interaction:" + interactionId);
                    queuePostMessage(callbackObject);
                    if (associationType == "relation") {
                        currentInteractionAssociation(interactionId, null, null, JSON.stringify(callbackObject));
                    }
                }
            }
            else {
                getFocusedSession().done(function success(result) {
                    if (result == "session-id-1") {
                        queuePostMessage(callbackObject);
                        if (associationType == "relation") {
                            currentInteractionAssociation(interactionId, null, null, JSON.stringify(callbackObject));
                        }
                    }
                });
            }
        }
        else if (isRunningOnUSD()) {
            let sessionInteractionMapping = getStorageItem(Constant.ssSessionMapping);
            if (sessionInteractionMapping && sessionInteractionMapping != "[]") {
                sessionInteractionMapping = JSON.parse(sessionInteractionMapping);
                const currentSessionUSD = getStorageItem(Constant.ssCurrentSessionUSD);
                if (currentSessionUSD) {
                    const isPresent = searchInJSON(sessionInteractionMapping, "sessionId", currentSessionUSD);
                    if (isPresent && isPresent.length > 0) {
                        if (isPresent[0].interactionId != interactionId) {
                            callbackObject.interactionId = isPresent[0].interactionId;
                        }
                    }
                }
            }
            log("sendAddAssociation. associationType:" + associationType + " entityid:" + entityId + " interaction:" + interactionId);
            queuePostMessage(callbackObject);
            if (associationType == "relation") {
                currentInteractionAssociation(interactionId, null, null, JSON.stringify(callbackObject));
            }
        }
        else {
            log("sendAddAssociation. associationType:" + associationType + " entityid:" + entityId + " interaction:" + interactionId);
            queuePostMessage(callbackObject);
            if (associationType == "relation") {
                currentInteractionAssociation(interactionId, null, null, JSON.stringify(callbackObject));
            }
        }
    }
}
export function renderSearchPage(entityname, search, newPage) {
    const deferred = $.Deferred();
    if (newPage) {
        setStorageItem(Constant.lsPopNewPage, newPage);
        saveRenderSearchAndOpenContactPageInNewTab(entityname, search, newPage);
    }
    else {
        try {
            const searchType = SpecifiedTypeOfSearchBasedOnConfigSetting();
            const guid = newGuid();
            window.Microsoft.CIFramework.renderSearchPage(entityname, search, guid, searchType).then(function (results) {
                deferred.resolve(results);
            }, function (error) {
                error = "renderSearchPage failed. entity:" + entityname + " search:" + search + " error:" + returnErrorMessageForApiFailed(error);
                log(error);
                return deferred.reject(error);
            });
        }
        catch (ex) {
            deferred.reject(ex.message);
        }
    }
    return deferred.promise();
}
function storeCalledNumberForCallback(entity, interaction, entityName) {
    if (Object.hasOwn(interaction, "calledNumber") && interaction.calledNumber != undefined) {
        const number = interaction.calledNumber.replace(/\D/g, "");
        if (number.length > 9) {
            if (!isInteractionForCustomActivity(interaction) && entityName.toLowerCase() == "phonecall") {
                entity.phonenumber = interaction.calledNumber;
            }
            else {
                entity.isgc_phonenumber = interaction.calledNumber;
            }
        }
    }
}
export function searchInJSON(JsonObj, key, val) {
    if (JsonObj && key && val) {
        return JsonObj.filter(function (JsonObj) {
            return JsonObj[key] == val;
        });
    }
}
function getAssociationJson() {
    const jsonSettingData = getParticipantCustomAttribute("associationMapping");
    if (jsonSettingData) {
        return JSON.parse(jsonSettingData);
    }
    return null;
}
function assignBasicActivityValuesForCustomActivity(entity, interaction, callLog, entityName) {
    if (getDefaultfieldsValues("subject", entityName)) {
        entity["subject"] = callLog.subject;
    }
    if (getDefaultfieldsValues("actualstartend", entityName) && !getActualStartSet(interaction.id)) {
        entity["actualstart"] = new Date().toISOString();
        setActualStartSet(true, interaction.id);
    }
    return entity;
}
function assignBasicActivityValues(entity, interaction, callLog) {
    entity["isgc_interactionid"] = interaction.id;
    entity["subject"] = getSubjectIfMessagingInteraction(interaction, callLog.subject);
    entity["actualstart"] = new Date().toISOString();
    setActualStartSet(true, interaction.id);
    entity["isgc_queuename"] = interaction["queueName"];
    entity["isgc_remotename"] = interaction["remoteName"];
    entity.isgc_interactionurl = getInteractionURL() + interaction.id;
    return entity;
}
export function loadISyncSolution() {
    const searchOnly = true;
    const query = "?$select=uniquename,version,friendlyname&$filter=contains(uniquename,'InteractionSyncforGenesysCloud')&$orderby=version desc";
    searchAndOpenRecords("solution", query, searchOnly)
        .done(function (results) {
        if (results != "{}") {
            results = JSON.parse(results);
            let version = results[0]["version"];
            log("Solution Version:" + version);
            setStorageItem(Constant.ssSolutionVersionFull, version);
            version = parseVersionNumber(version);
            setStorageItem(Constant.ssSolutionVersion, version);
            getISyncConfig();
        }
    })
        .fail(function () {
        getISyncConfig();
    });
}
function parseVersionNumber(version) {
    const matches = /\d+\.\d+\.(\d+)\.\d+/.exec(version);
    return matches[1];
}
function getISyncConfig() {
    getEnvironment().done(function (data) {
        data = JSON.parse(data);
        FunctionsVariable.languageTag = LCIDUtils.getCultureName(data.userLcid);
        let customParams = data.customParams || data.msdyn_customparams;
        let options = "";
        if (isRunningMinimumFullVersion("2.92.9.0")) {
            options = "?$select=isgc_activitymapping,isgc_autoassociate,isgc_clienttype,isgc_configname,isgc_customattributes,isgc_interactionsyncconfigid,isgc_token,isgc_urlforfullclient,isgc_urlforsoftphonecrmwebclient,isgc_associationmapping,isgc_screenpop,isgc_urlforinteractionlink,isgc_defaultfields,isgc_urlforembeddedwindow,isgc_newinteractionsettings,isgc_overridenewinteraction,isgc_screenpopsettingjson,isgc_notificationmapping,isgc_updatecallbacksubject";
        }
        else if (isRunningMinimumFullVersion("2.92.3.0")) {
            options = "?$select=isgc_activitymapping,isgc_autoassociate,isgc_clienttype,isgc_configname,isgc_customattributes,isgc_interactionsyncconfigid,isgc_token,isgc_urlforfullclient,isgc_urlforsoftphonecrmwebclient,isgc_associationmapping,isgc_screenpop,isgc_urlforinteractionlink,isgc_defaultfields,isgc_urlforembeddedwindow,isgc_newinteractionsettings,isgc_overridenewinteraction,isgc_screenpopsettingjson,isgc_notificationmapping";
        }
        else if (isRunningMinimumFullVersion("2.92.2.0")) {
            options = "?$select=isgc_activitymapping,isgc_autoassociate,isgc_clienttype,isgc_configname,isgc_customattributes,isgc_interactionsyncconfigid,isgc_token,isgc_urlforfullclient,isgc_urlforsoftphonecrmwebclient,isgc_associationmapping,isgc_screenpop,isgc_urlforinteractionlink,isgc_defaultfields,isgc_urlforembeddedwindow,isgc_newinteractionsettings,isgc_overridenewinteraction,isgc_screenpopsettingjson";
        }
        else if (isRunningMinimumFullVersion("2.92.1.0")) {
            options = "?$select=isgc_activitymapping,isgc_autoassociate,isgc_clienttype,isgc_configname,isgc_customattributes,isgc_interactionsyncconfigid,isgc_token,isgc_urlforfullclient,isgc_urlforsoftphonecrmwebclient,isgc_associationmapping,isgc_screenpop,isgc_urlforinteractionlink,isgc_defaultfields,isgc_urlforembeddedwindow";
        }
        if (isCIFV2()) {
            customParams = customParams + " (V2)";
        }
        if (customParams != null && customParams != undefined) {
            options += "&$filter=isgc_configname eq '" + customParams + "' or isgc_token eq 'mylist'";
        }
        else {
            options += "&$filter=isgc_token eq 'mylist'";
        }
        searchAndOpenRecords("isgc_interactionsyncconfig", options, true)
            .done(function (results) {
            let isyncConfigData = null;
            const result = JSON.parse(results);
            const providerCount = Object.keys(result).length;
            if (providerCount == 1) {
                isyncConfigData = result[0];
            }
            else if (providerCount > 1) {
                for (let j = 0; j < Object.keys(result).length; j++) {
                    if (result[j].isgc_token != "mylist") {
                        isyncConfigData = result[j];
                        break;
                    }
                }
            }
            if (isyncConfigData == null) {
                $("#isynccif").remove();
                showMessagePanel(true, "Could not load InteractionSync for Genesys Cloud configuration. Contact your system administrator.");
            }
            else {
                getIsyncQueueConfig(isyncConfigData)
                    .then((isyncConfigData) => {
                    log("ISync Configuration Name: " + isyncConfigData.isgc_configname);
                    loadISyncConfig(isyncConfigData);
                    loadEmbeddableFrameworkPage();
                    createDynamicSceenpopSearchFunctionArray();
                })
                    .catch(() => {
                    $("#isynccif").remove();
                    showMessagePanel(true, "Could not load InteractionSync for Genesys Cloud queue configuration. Contact your system administrator.");
                });
            }
        })
            .fail(function () {
            $("#isynccif").remove();
            showMessagePanel(true, "Could not load InteractionSync for Genesys Cloud configuration. Contact your system administrator.");
        });
    });
}
function getIsyncQueueConfig(isyncConfigData) {
    const deferred = new Promise((resolve, reject) => {
        if (isRunningMinimumFullVersion("2.93.0")) {
            const configId = isyncConfigData.isgc_interactionsyncconfigid;
            const query = "$filter=isgc_configrecordid eq " + configId;
            searchAndOpenRecords("isgc_interactionsyncqueueconfig", query, true)
                .done(function (resultJson) {
                const result = JSON.parse(resultJson);
                const queueCount = Object.keys(result).length;
                const queueData = [];
                for (let i = 0; i < queueCount; i++) {
                    queueData.push({
                        queueIdField: result[i].isgc_queueid,
                        queueNameField: result[i].isgc_queuename,
                        screenPop: JSON.parse(result[i].isgc_screenpopconfig)
                    });
                }
                isyncConfigData.isgc_queueconfig = JSON.stringify(queueData);
                resolve(isyncConfigData);
            })
                .fail((err) => {
                reject(err);
            });
        }
        else {
            log("Solution version is not at least 2.93.0. Queue configs are unavailable.");
            isyncConfigData.isgc_queueconfig = null;
            resolve(isyncConfigData);
        }
    });
    return deferred;
}
function loadISyncConfig(isyncConfigData) {
    let activitymappingdata = [];
    const allTypeMappingData = {};
    const participantCustomAttribute = [];
    if (isyncConfigData.isgc_activitymapping != null && isyncConfigData.isgc_activitymapping != undefined) {
        activitymappingdata = JSON.parse(isyncConfigData.isgc_activitymapping);
    }
    if (isyncConfigData.isgc_customattributes != null && isyncConfigData.isgc_customattributes != undefined) {
        FunctionsVariable.jsonSettingData = isyncConfigData.isgc_customattributes;
    }
    if (activitymappingdata !== null && activitymappingdata !== undefined) {
        participantCustomAttribute.push("IS_AttributesInit");
        participantCustomAttribute.push("IS_TabArray");
        participantCustomAttribute.push("IS_AnnotationId");
        participantCustomAttribute.push("IS_OutboundCheckForAssociation");
        for (let t = 0; t < activitymappingdata.length; t++) {
            setLogicalAndSchemaNameOfActivityEntity(activitymappingdata[t].activityType.replace(/ /g, ""), activitymappingdata[t]);
            const activityTypeName = activitymappingdata[t].activityTypeLogicalName.replace(/ /g, "");
            if (Object.hasOwn(allTypeMappingData, activityTypeName)) {
                allTypeMappingData[activityTypeName].push(activitymappingdata[t]);
            }
            else {
                allTypeMappingData[activityTypeName] = [activitymappingdata[t]];
            }
            if (activitymappingdata[t].pureCloudField) {
                const purecloudfld = activitymappingdata[t].pureCloudField;
                participantCustomAttribute.push(purecloudfld);
            }
        }
    }
    const JsonObject = {};
    JsonObject["activityMappingData"] = allTypeMappingData;
    JsonObject["customInteractionAttributes"] = participantCustomAttribute;
    // TODO: jsonSettingData is defined as an [] but used as a string
    const jsonSettingsString = FunctionsVariable.jsonSettingData;
    const objSetting = JSON.parse(jsonSettingsString);
    if (isRunningOnUSD()) {
        objSetting.embeddedInteractionWindow = true;
    }
    JsonObject["settingsJson"] = JSON.stringify(objSetting);
    if (isyncConfigData.isgc_autoassociate != null && isyncConfigData.isgc_autoassociate != undefined) {
        JsonObject["autoAssociate"] = isyncConfigData.isgc_autoassociate;
    }
    if (isyncConfigData.isgc_associationmapping != null && isyncConfigData.isgc_associationmapping != undefined) {
        JsonObject["associationMapping"] = isyncConfigData.isgc_associationmapping;
    }
    JsonObject["userLanguage"] = FunctionsVariable.languageTag;
    if (Object.hasOwn(isyncConfigData, "isgc_screenpop") && isyncConfigData["isgc_screenpop"] != null && isyncConfigData["isgc_screenpop"] != undefined) {
        JsonObject["screenpopJson"] = isyncConfigData["isgc_screenpop"];
    }
    if (isyncConfigData.isgc_urlforinteractionlink != null && isyncConfigData.isgc_urlforinteractionlink != undefined) {
        JsonObject["interactionLink"] = isyncConfigData.isgc_urlforinteractionlink;
    }
    if (isyncConfigData.isgc_defaultfields != null && isyncConfigData.isgc_defaultfields != undefined) {
        JsonObject["defaultfields"] = isyncConfigData.isgc_defaultfields;
    }
    if (isyncConfigData.isgc_urlforfullclient != null && isyncConfigData.isgc_urlforfullclient != undefined) {
        JsonObject["fullclient"] = isyncConfigData["isgc_urlforfullclient"];
        setClientIdBasedOnSelectedRegion(isyncConfigData["isgc_urlforfullclient"]);
    }
    if (isyncConfigData.isgc_urlforsoftphonecrmwebclient != null && isyncConfigData.isgc_urlforsoftphonecrmwebclient != undefined) {
        JsonObject["embeddableclienturl"] = isyncConfigData["isgc_urlforsoftphonecrmwebclient"];
    }
    if (isyncConfigData.isgc_urlforembeddedwindow != null && isyncConfigData.isgc_urlforembeddedwindow != undefined) {
        JsonObject["embeddedWindowUrl"] = isyncConfigData["isgc_urlforembeddedwindow"];
    }
    if (isyncConfigData.isgc_newinteractionsettings != null && isyncConfigData.isgc_newinteractionsettings != undefined) {
        JsonObject["newInteractionSettingJson"] = isyncConfigData["isgc_newinteractionsettings"];
    }
    if (isyncConfigData.isgc_overridenewinteraction != null && isyncConfigData.isgc_overridenewinteraction != undefined) {
        JsonObject["overrideNewinteraction"] = isyncConfigData["isgc_overridenewinteraction"];
    }
    if (isyncConfigData.isgc_screenpopsettingjson != null && isyncConfigData.isgc_screenpopsettingjson != undefined) {
        JsonObject["screenpopsettingjson"] = isyncConfigData["isgc_screenpopsettingjson"];
    }
    if (isyncConfigData.isgc_notificationmapping != null && isyncConfigData.isgc_notificationmapping != undefined) {
        JsonObject["notificationmapping"] = isyncConfigData["isgc_notificationmapping"];
    }
    if (isyncConfigData.isgc_updatecallbacksubject != null && isyncConfigData.isgc_updatecallbacksubject != undefined) {
        JsonObject["updateCallbackSubject"] = isyncConfigData.isgc_updatecallbacksubject;
    }
    if (isyncConfigData.isgc_queueconfig != null && isyncConfigData.isgc_queueconfig != undefined) {
        JsonObject["queueConfig"] = isyncConfigData["isgc_queueconfig"];
    }
    if (isyncConfigData.isgc_interactionsyncconfigid != null && isyncConfigData.isgc_interactionsyncconfigid != undefined) {
        JsonObject["interactionsyncConfigId"] = isyncConfigData.isgc_interactionsyncconfigid;
    }
    const configurationSettingData = JSON.stringify(JsonObject);
    setStorageItem(Constant.lsConfigurationSetting, configurationSettingData);
    window.participantCustomAttribute = configurationSettingData;
}
export function loadEmbeddableFrameworkPage() {
    let url = getParticipantCustomAttribute("embeddableclienturl");
    const ssoSettings = getSettingFromConfig("sso");
    const dedicatedLoginWindow = getSettingFromConfig("dedicatedLoginWindow");
    if (ssoSettings.orgName.trim() != "" && ssoSettings.provider.trim() != "") {
        url += "?dedicatedLoginWindow=" + dedicatedLoginWindow + "&provider=" + ssoSettings.provider + "&orgName=" + ssoSettings.orgName;
    }
    url = addDynamicsCrmURLtoParams(url);
    log("set framework page:" + url);
    $("#isynccif").attr("src", url);
    miniClientTabOpen();
    let minimizeSoftphoneForCIFV2check = "";
    let enableMinimizeSoftphone = getSettingFromConfig("minimizesoftphonedefaultforV2");
    enableMinimizeSoftphone = enableMinimizeSoftphone ? enableMinimizeSoftphone.toString().replace(/\s/g, "").toLowerCase() : "";
    if (enableMinimizeSoftphone == "none" || enableMinimizeSoftphone == "defaultforallusers") {
        removeStorageItem(Constant.lsMinimizeSoftphoneForCIFV2);
    }
    else {
        minimizeSoftphoneForCIFV2check = getStorageItem(Constant.lsMinimizeSoftphoneForCIFV2);
    }
    if (shouldHideSoftphone() || shouldHideSoftphoneInteraction() || minimizeSoftphoneForCIFV2check) {
        setSoftPhonePanelMode(0);
    }
    if (shouldShowSoftphoneOnSingleTab()) {
        FunctionsVariable.noResponseTimeout = setTimeout(function () {
            showSidebar();
        }, 2000);
    }
}
function isValidContactTypeEntity(entityName) {
    const validEntityNames = ["Contact", "Account", "Lead", "Systemuser"];
    const EntityNameIndex = validEntityNames.findIndex((item) => entityName.toLowerCase() === item.toLowerCase());
    if (EntityNameIndex >= 0) {
        return true;
    }
    else {
        return false;
    }
}
function addAssociationByCurrentPage(interactionid) {
    getEnvironment().done(function (data) {
        data = JSON.parse(data);
        if (data != null && data.etn != null && data.id) {
            log("add association: navigated to entity page.  name: " + data.etn + " id:" + data.id);
            if (data.id !== null && data.id !== undefined && data.etn !== null && data.etn !== undefined) {
                currentInteractionAssociation(interactionid, data.id, data.etn, null);
            }
            if (isCIFV2()) {
                executeAssociation(JSON.stringify(data), null, null);
            }
            else if (isAssociationExistForCurrentTab(JSON.stringify(data))) {
                executeAssociation(JSON.stringify(data), null, null);
            }
        }
    });
}
function getAssociationDisplayFieldValue(associationType, entityName, guid) {
    const deferred = $.Deferred();
    const fieldName = getAssociationDisplayFieldName(associationType, entityName);
    if (fieldName != null) {
        if (isValidContactTypeEntity(entityName)) {
            entityName = entityName.toLowerCase();
        }
        else {
            const associationJson = getAssociationJson();
            if (associationJson && associationJson.length > 0) {
                const findEntity = searchInJSON(associationJson, "entitylogicalname", entityName);
                if (findEntity.length <= 0)
                    return null;
                entityName = findEntity[0].entitylogicalname;
            }
        }
        getEntityById(entityName, guid, fieldName)
            .done(function (result) {
            deferred.resolve(result);
        })
            .fail(function (error) {
            log("getAssociationDisplayFieldValue failed. entity:" + entityName + " guid:" + guid + " fieldName:" + fieldName + " error:" + error);
            deferred.reject(error);
        });
    }
    return deferred.promise();
}
function getAssociationDisplayFieldName(associationType, entityName) {
    if (associationType == "contact" && isValidContactTypeEntity(entityName)) {
        return getEntityFieldName(entityName, false);
    }
    else {
        const associationJson = getAssociationJson();
        if (associationJson && associationJson.length > 0) {
            const findEntity = searchInJSON(associationJson, "entitylogicalname", entityName);
            if (findEntity.length > 0) {
                return findEntity[0].fieldassociatesval;
            }
        }
    }
    return null;
}
export function addWhoAssociation(whoId, whoType, interactionId) {
    if (isValidContactTypeEntity(whoType)) {
        const deferred = $.Deferred();
        const associationType = "contact";
        if (whoId !== null && whoId !== undefined && whoType !== null && whoType !== undefined) {
            currentInteractionAssociation(interactionId, whoId, whoType, null);
        }
        getAssociationDisplayFieldValue(associationType, whoType, whoId)
            .done(function (fieldNameData) {
            sendAddAssociation(associationType, whoId, fieldNameData, interactionId);
            deferred.resolve(null);
        })
            .fail(function (error) {
            deferred.reject(error);
        });
        return deferred.promise();
    }
}
function addRegardingAssociation(regardingId, regardingType, interactionId) {
    const deferred = $.Deferred();
    const associationType = "relation";
    getAssociationDisplayFieldValue(associationType, regardingType, regardingId)
        .done(function (fieldNameData) {
        sendAddAssociation(associationType, regardingId, fieldNameData, interactionId);
        deferred.resolve(null);
    })
        .fail(function (error) {
        deferred.reject(error);
    });
    return deferred.promise();
}
export function sendNotification(message, type) {
    log("sendNotification. message:" + message + " type:" + type);
    const notificationDetails = {
        messageType: "notifyUser",
        message: message,
        type: type
    };
    queuePostMessage(notificationDetails);
}
export function setInteractionAttributes(interactionId, attributes) {
    const attributeObject = {
        messageType: "addCustomAttributes",
        interactionId: interactionId,
        attributes: attributes
    };
    log("setInteractionAttributes. interactionId:" + interactionId + " attributes:" + JSON.stringify(attributes));
    queuePostMessage(attributeObject);
}
export function preventScreenPop(interaction) {
    if (interaction.attributes !== undefined && interaction.attributes !== null && interaction.attributes !== "") {
        if (Object.hasOwn(interaction.attributes, "is_nopop") && interaction.attributes.is_nopop != "" && interaction.attributes.is_nopop != null) {
            return true;
        }
        return false;
    }
    return false;
}
export function getInteractionActivityType(interaction) {
    const jsonData = {};
    const interactionType = checkInteractionTypeIsMessage(interaction);
    if (interaction.attributes != (undefined || null) && isInteractionForCustomActivity(interaction)) {
        jsonData.entityName = interaction.attributes.is_activitytype;
        jsonData.entityPluralname = null;
    }
    else if (interaction.attributes != (undefined || null) && interaction.attributes.is_activitytype != (undefined || null) && interaction.attributes.is_activitytypeplural != (undefined || null)) {
        jsonData.entityName = interaction.attributes.is_activitytype;
        jsonData.entityPluralname = interaction.attributes.is_activitytypeplural;
    }
    else if (interaction.isCallback && !interaction.isDialerPreview) {
        jsonData.entityPluralname = Constant.callbacksName;
        jsonData.entityName = Constant.callbackName;
    }
    else if (interaction.isChat) {
        jsonData.entityPluralname = Constant.chatsName;
        jsonData.entityName = Constant.chatName;
    }
    else if (interaction.isEmail) {
        jsonData.entityPluralname = Constant.emailsName;
        jsonData.entityName = Constant.emailName;
    }
    else if (interactionType && interactionType.toLowerCase() == "webmessaging") {
        jsonData.entityPluralname = Constant.webMessagesName;
        jsonData.entityName = Constant.webMessageName;
    }
    else if (interaction.isMessage) {
        jsonData.entityPluralname = Constant.messagesName;
        jsonData.entityName = Constant.messageName;
    }
    else {
        jsonData.entityPluralname = Constant.phonecallsName;
        jsonData.entityName = Constant.phonecallName;
    }
    return jsonData;
}
function conversionDataTypes(pureCloudFieldValue, activityFieldType, activityFieldName, entitysetname) {
    const message = "Failed to save activity with data conversion issue with field " + activityFieldName;
    const errorType = "error";
    let datatype = activityFieldType;
    const value = pureCloudFieldValue;
    datatype = datatype.toLowerCase();
    switch (datatype) {
        case "string":
        case "memo":
        case "picklist":
            try {
                return value;
            }
            catch (e) {
                sendNotification(message, errorType);
                return value;
            }
        case "boolean":
            try {
                if (value.toString().trim().toLowerCase() === "true" || value.toString().trim() === "1") {
                    return true;
                }
                else {
                    return false;
                }
            }
            catch (e) {
                sendNotification(message, errorType);
                return value;
            }
        case "datetime":
            try {
                if (isEdge()) {
                    return new Date(new Date(value)).toISOString();
                }
                else {
                    return new Date(value);
                }
            }
            catch (e) {
                sendNotification(message, errorType);
                return value;
            }
        case "integer":
            try {
                return parseInt(value);
            }
            catch (e) {
                sendNotification(message, errorType);
                return value;
            }
        case "double":
        case "decimal":
        case "money":
            try {
                return parseFloat(value);
            }
            catch (e) {
                sendNotification(message, errorType);
                return value;
            }
        case "lookup":
            try {
                if (value.includes("/")) {
                    return value;
                }
                else {
                    return "/" + entitysetname + "(" + value + ")";
                }
            }
            catch (e) {
                sendNotification(message, errorType);
                return value;
            }
        default:
            return value;
    }
}
function getDescription(enitity, activityid) {
    const deferred = $.Deferred();
    const query = "?$select=description";
    retrieveRecord(enitity, activityid, query)
        .done(function (results) {
        let fieldVal = "";
        const result = JSON.parse(results);
        if (result["description"] != null) {
            fieldVal = result["description"];
        }
        deferred.resolve(fieldVal);
    })
        .fail(function (error) {
        deferred.reject(error);
    });
    return deferred.promise();
}
function removeDescription(interactionId) {
    const getDescriptionData = getStorageItem(Constant.ssActivityDescription);
    if (getDescriptionData) {
        const getJsonDescriptionData = JSON.parse(getDescriptionData);
        SupportVariable.activityDescriptionArray = removeByAttr(getJsonDescriptionData, "interactionId", interactionId);
        setStorageItem(Constant.ssActivityDescription, JSON.stringify(SupportVariable.activityDescriptionArray));
    }
}
function removeByAttr(arr, attr, value) {
    let i = arr.length;
    while (i--) {
        if (arr[i] && Object.hasOwn(arr[i], attr) && arguments.length > 2 && arr[i][attr] === value) {
            arr.splice(i, 1);
        }
    }
    return arr;
}
export function shouldPopFormUsingParameters(interaction) {
    const attributes = interaction.attributes;
    if (isCIFV2() && Object.hasOwn(interaction.attributes, "is_tabarray")) {
        return false;
    }
    if (Object.hasOwn(attributes, "is_popformoptions") && Object.hasOwn(attributes, "is_popformparameters") && typeof attributes.is_popformoptions === "string" && typeof attributes.is_popformparameters === "string") {
        attributes.is_popformoptions = JSON.parse(attributes.is_popformoptions);
        attributes.is_popformparameters = JSON.parse(attributes.is_popformparameters);
        return true;
    }
    else if (Object.hasOwn(attributes, "is_popformoptions") && Object.hasOwn(attributes, "is_popformparameters") && typeof attributes.is_popformoptions === "object" && typeof attributes.is_popformparameters === "object") {
        return true;
    }
    else if (Object.hasOwn(attributes, "is_popformoptions") && !Object.hasOwn(attributes, "is_popformparameters") && typeof attributes.is_popformoptions === "object") {
        attributes.is_popformparameters = null;
        return true;
    }
    else if (Object.hasOwn(attributes, "is_popformoptions") && !Object.hasOwn(attributes, "is_popformparameters")) {
        attributes.is_popformoptions = JSON.parse(attributes.is_popformoptions);
        attributes.is_popformparameters = null;
        return true;
    }
    return false;
}
export function shouldPopNewEntity(interaction) {
    if (hasEntityIdTypeAttributes(interaction.attributes)) {
        interaction.attributes.is_entitytype = interaction.attributes.is_entitytype.toLowerCase();
        if (interaction.attributes.is_entityid.toString().trim() == "NEW" && !Object.hasOwn(interaction.attributes, "is_tabarray")) {
            interaction.attributes.is_entityobject = {};
            interaction.attributes.is_entityobject.parameters = null;
            interaction.attributes.is_entityobject.entityFormOptions = {};
            interaction.attributes.is_entityobject.entityFormOptions["entityName"] = interaction.attributes.is_entitytype.toString().trim();
            const is_entityvalues = interaction.attributes.is_entityvalues;
            if (is_entityvalues != null && is_entityvalues != undefined) {
                interaction.attributes.is_entityobject.parameters = {};
                const entityvalues = is_entityvalues.split("||");
                for (let i = 0; i < entityvalues.length; i++) {
                    const keyvalue = entityvalues[i].split("=");
                    if (keyvalue.length > 1) {
                        interaction.attributes.is_entityobject.parameters[keyvalue[0].toString().trim()] = keyvalue[1];
                    }
                }
            }
            return true;
        }
    }
    return false;
}
export function shouldPopActivityBeingCreatedForInteraction(interaction) {
    if (interaction.attributes.is_entitytype !== undefined && interaction.attributes.is_entityid !== undefined) {
        interaction.attributes.is_entitytype = interaction.attributes.is_entitytype.toLowerCase();
        if (interaction.attributes.is_entityid.toString().trim() == "NEW" && interaction.attributes.is_entitytype.toString().trim() == "activity" && !Object.hasOwn(interaction.attributes, "is_tabarray")) {
            return true;
        }
    }
    return false;
}
export function shouldPopSpecificEntity(interaction) {
    if (hasEntityIdTypeAttributes(interaction.attributes)) {
        interaction.attributes.is_entitytype = interaction.attributes.is_entitytype.toLowerCase();
        if (interaction.attributes.is_entityid.toString().trim() != "NEW" && !Object.hasOwn(interaction.attributes, "is_tabarray")) {
            return true;
        }
        return false;
    }
}
/*export function isRunningMinimumVersion(version) {
  const storedSolutionVersion = getStorageItem(Constant.ssSolutionVersion);
  if (storedSolutionVersion) {
    if (parseInt(storedSolutionVersion) >= version) {
      //Need to check condition based on Max version first
      return true;
    }
  }
  return false;
}*/
export function isRunningMinimumFullVersion(version) {
    const storedSolutionVersion = getStorageItem(Constant.ssSolutionVersionFull);
    if (storedSolutionVersion) {
        const isRunningMinimum = compareSolutionVersions(version, storedSolutionVersion);
        return isRunningMinimum;
    }
    return false;
}
//Returns True when stored version is gte test version
export function compareSolutionVersions(testVersion, storedVersion) {
    const comparison = compareVersions(storedVersion, testVersion);
    return comparison === 0 || comparison === 1;
}
export function saveActivity(currentInteractionId, entityName, entityData) {
    const deferred = $.Deferred();
    trackEventBeforSaveActivity(entityData, entityName);
    saveEntity(entityName, entityData)
        .done(function (result) {
        result = JSON.parse(result);
        trackEventAfterSaveActivity(result);
        const activityData = entityName + "|" + result.id;
        log("savedActivity:" + activityData);
        const activityid = result.id;
        if (currentInteractionId == null) {
            currentInteractionId = getCurrentInteractionId();
        }
        if (currentInteractionId != entityData.isgc_interactionid) {
            currentInteractionId = entityData.isgc_interactionid;
        }
        const interactionData = getInteractionData(currentInteractionId);
        if (getPopActivityOnSave(currentInteractionId) || (getSettingFromConfig("activityscreenpopforoutboundinteraction") && interactionData && Object.hasOwn(interactionData, "direction") && interactionData.direction.toLowerCase() != "inbound")) {
            log("popping new activity created:" + activityData);
            if (isCIFV2()) {
                openRecordForOmnichannel(entityName, result.id, currentInteractionId, null);
            }
            else {
                openEntityForm(entityName, result.id, false, null);
            }
        }
        setActivityData(activityData, currentInteractionId);
        getDescription(entityName, activityid).done(function () {
            const descriptionObj = {
                activityId: activityid,
                interactionId: entityData.isgc_interactionid,
                initialDescription: null
            };
            const getDescriptionDataTemp = getStorageItem(Constant.ssActivityDescription);
            if (getDescriptionDataTemp) {
                const getDescriptionDataTempParse = JSON.parse(getDescriptionDataTemp);
                if (getDescriptionDataTempParse.length > 0) {
                    SupportVariable.activityDescriptionArray = getDescriptionDataTempParse;
                    SupportVariable.activityDescriptionArray.push(descriptionObj);
                }
                else {
                    SupportVariable.activityDescriptionArray.push(descriptionObj);
                }
            }
            else {
                SupportVariable.activityDescriptionArray.push(descriptionObj);
            }
            setStorageItem(Constant.ssActivityDescription, JSON.stringify(SupportVariable.activityDescriptionArray));
        });
        deferred.resolve(activityData);
    })
        .fail(function (error) {
        log(error);
        deferred.reject(error);
    });
    return deferred.promise();
}
export function updateActivity(entityName, entityId, entityData) {
    const deferred = $.Deferred();
    trackEventBeforUpdateActivity(entityData, entityName, entityId);
    updateEntity(entityName, entityId, entityData)
        .done(function (result) {
        const activityData = entityName + "|" + entityId;
        log("updated activity:" + activityData);
        result = JSON.parse(result);
        trackEventAfterUpdateActivity(result);
        deferred.resolve(activityData);
    })
        .fail((e) => {
        log(e);
        deferred.reject(e);
    });
    return deferred.promise();
}
function currentInteractionAssociation(interactionId, whoId, whoType, relationData) {
    const existingArray = null;
    const asssociationDataTemp = getStorageItem(Constant.ssAssociationObject);
    if (asssociationDataTemp == undefined || asssociationDataTemp == null) {
        addAssociationObjectToStorage(interactionId, whoId, whoType, relationData, existingArray);
    }
    else if (asssociationDataTemp) {
        const getasssociationDataTempParse = JSON.parse(asssociationDataTemp);
        if (getasssociationDataTempParse.length > 0) {
            let findAssoObjBasedInteractionId = searchInJSON(getasssociationDataTempParse, "interactionId", interactionId);
            if (!findAssoObjBasedInteractionId)
                findAssoObjBasedInteractionId = [];
            if (findAssoObjBasedInteractionId.length > 0) {
                if (whoId !== null) {
                    findAssoObjBasedInteractionId[0].IS_EntityId = whoId;
                }
                if (whoType !== null) {
                    findAssoObjBasedInteractionId[0].IS_EntityType = whoType;
                }
                if (relationData !== null) {
                    findAssoObjBasedInteractionId[0].IS_ActivityAssociation = relationData;
                }
                for (let i = 0; i < getasssociationDataTempParse.length; i++) {
                    if (getasssociationDataTempParse[i].interactionId == interactionId) {
                        getasssociationDataTempParse[i] = findAssoObjBasedInteractionId[0];
                        break;
                    }
                }
                setStorageItem(Constant.ssAssociationObject, JSON.stringify(getasssociationDataTempParse));
            }
            else if (findAssoObjBasedInteractionId.length == 0) {
                addAssociationObjectToStorage(interactionId, whoId, whoType, relationData, getasssociationDataTempParse);
            }
        }
        else if (getasssociationDataTempParse.length == 0) {
            addAssociationObjectToStorage(interactionId, whoId, whoType, relationData, existingArray);
        }
    }
    else if (asssociationDataTemp == "[]") {
        addAssociationObjectToStorage(interactionId, whoId, whoType, relationData, existingArray);
    }
}
function addAssociationObjectToStorage(interactionId, whoId, whoType, relationData, existingArray) {
    const associationObjForScheduleCall = {
        interactionId: null,
        IS_EntityId: null,
        IS_EntityType: null,
        IS_ActivityAssociation: null
    };
    associationObjForScheduleCall.interactionId = interactionId;
    if (whoId !== null) {
        associationObjForScheduleCall.IS_EntityId = whoId;
    }
    if (whoType !== null) {
        associationObjForScheduleCall.IS_EntityType = whoType;
    }
    if (relationData !== null) {
        associationObjForScheduleCall.IS_ActivityAssociation = relationData;
    }
    if (existingArray == null) {
        const associationDataBasedOnInteraction = [];
        associationDataBasedOnInteraction.push(associationObjForScheduleCall);
        setStorageItem(Constant.ssAssociationObject, JSON.stringify(associationDataBasedOnInteraction));
    }
    else {
        existingArray.push(associationObjForScheduleCall);
        setStorageItem(Constant.ssAssociationObject, JSON.stringify(existingArray));
    }
}
function removeAssociationDataFromCache(interactionId) {
    removeItemDataOnInteractionId(interactionId, Constant.ssAssociationObject);
}
function checkForScreenPopOnOutboundCall(interaction) {
    let newPage = false;
    let interactionType = checkInteractionTypeIsMessage(interaction);
    if (interactionType) {
        interactionType = interactionType.toLowerCase();
    }
    if (interaction && (interaction.calledNumber || interactionType == "sms") && !interaction.isInternal && interaction.attributes) {
        if (preventScreenPop(interaction)) {
            return;
        }
        else if (hasEntityIdTypeAttributes(interaction.attributes) && interaction.attributes.is_searchpop == "Y") {
            const needToPreventScreenPop = needToPreventScreenPopOnDialing(interaction);
            if (needToPreventScreenPop) {
                updateAttributeIsScreenpop(interaction.id);
                return;
            }
            const entityNameToSearch = interaction.attributes.is_entitytype;
            const recordGuidToSearch = interaction.attributes.is_entityid;
            PartnerVariable.newOutboundInteraction = false;
            const interactionId = interaction.id;
            const screenPopConfig = getScreenPopConfiguration(interaction);
            if (isCIFV2() && getScreenpopFunctionArrayinteraction(interaction).length > 0 && !screenPopConfig.oneRecord.noPop && !getSettingFromConfig("activityscreenpopforoutboundinteraction")) {
                updateAttributeIsScreenpop(interactionId);
                openRecordForOmnichannel(entityNameToSearch, recordGuidToSearch, interactionId, null);
            }
            else {
                if (isRunningOnUSD()) {
                    updateAttributeIsScreenpop(interactionId);
                    const EntityData = "LogicalName=" + entityNameToSearch + "%0Aid=" + recordGuidToSearch;
                    executeOnUsd("http://event/?eventname=" + Constant.usdEventNewSessionRecord + "&EntityData=" + EntityData);
                }
                else {
                    if (shouldIsDirtyScreenPopOnNewTab() && getScreenpopFunctionArrayinteraction(interaction).length > 0) {
                        isDirtyCurrentPage()
                            .done(function success(isDirty) {
                            if (isDirty) {
                                newPage = true;
                            }
                            openEntityForm(entityNameToSearch, recordGuidToSearch, newPage, interaction);
                            updateAttributeIsScreenpop(interactionId);
                        })
                            .fail(function (error) {
                            error = returnErrorMessageForApiFailed(error);
                            log(error);
                        });
                    }
                    else if (!getSettingFromConfig("activityscreenpopforoutboundinteraction")) {
                        if (!screenPopConfig.oneRecord.noPop && getScreenpopFunctionArrayinteraction(interaction).length > 0) {
                            openEntityForm(entityNameToSearch, recordGuidToSearch, newPage, interaction);
                            updateAttributeIsScreenpop(interactionId);
                        }
                    }
                }
            }
        }
        else if (isCIFV2()) {
            const sessionInteractionMapping = getStorageItem(Constant.ssSessionMapping);
            if (sessionInteractionMapping != null && sessionInteractionMapping != "[]") {
                const isPresent = searchInJSON(JSON.parse(sessionInteractionMapping), "interactionId", interaction.id);
                if (isPresent.length == 0) {
                    forceScreenpopForOutbound(interaction);
                }
            }
            else {
                forceScreenpopForOutbound(interaction);
            }
        }
        else if (isRunningOnUSD()) {
            performScreenPopOrAssociationBasedOnInteraction(interaction);
        }
        else {
            if (interaction.isEmail && interaction.direction.toLowerCase() == "outbound") {
                forceScreenpopForOutbound(interaction);
            }
            if (interactionType == "sms") {
                FunctionsVariable.flagCallForScreenpop = false;
                forceScreenpopForOutbound(interaction);
            }
            if (!isCIFV2() && !isNaN(interaction.calledNumber)) {
                performScreenPopOrAssociationBasedOnInteraction(interaction);
            }
        }
    }
}
export function interactionAcwEvent(e) {
    if (e.data.interaction.disposition) {
        if (!checkObjectForInteractionDisposition(e.data.interaction)) {
            const isActiveInteraction = isActiveInteractionExists();
            if (!isActiveInteraction && $("#embeddedMainDiv").css("display") == "block") {
                hideEmbeddedInteractionWindow();
            }
            updateDisposition(e.data.interaction); // need to be added
            closeActivityBasedOnConfigSettingValue(e.data.interaction.id, "wrap");
        }
    }
}
export function interactionAddEvent(e) {
    const interaction = e.data.interaction;
    Global.openActivityTabForExistingActivity = true;
    setStorageItem(Constant.lsStoreIntercationIdForAssocitionFromNewWindow, interaction.id);
    setInteractionData(interaction, interaction.id);
    if (interaction.direction.toString().trim().toLowerCase() == "outbound") {
        if (!interaction.isDialer) {
            setInteractionAttributes(interaction.id, { IS_AttributesInit: "Y" });
        }
        setNewInteractionId(interaction.id);
        checkAndRemoveRecordNewInteractionForOutboundOnNotDirty();
        if (FunctionsVariable.makeCallFromOtherTabPageInfo != null) {
            executeAssociation(JSON.stringify(FunctionsVariable.makeCallFromOtherTabPageInfo), interaction.id, true);
            setStorageItem(Constant.lsSendMakeCallToSoftphoneResponse, interaction.id);
            FunctionsVariable.makeCallFromOtherTabPageInfo = null;
            FunctionsVariable.makeCallFromScriptId = null;
        }
        else if (!interaction.isEmail) {
            if (FunctionsVariable.scriptId == FunctionsVariable.makeCallFromScriptId) {
                PartnerVariable.newOutboundInteraction = true;
                FunctionsVariable.makeCallFromScriptId = null;
                if (isRunningOnUSD() && FunctionsVariable.isClick2Act) {
                    FunctionsVariable.isClick2Act = false;
                    const sessionId = getStorageItem(Constant.ssCurrentSessionUSD);
                    mapNewSessionUSD(sessionId);
                }
            }
            else {
                const interactionType = checkInteractionTypeIsMessage(interaction);
                if (interactionType && interactionType.toLowerCase() == "sms") {
                    PartnerVariable.newOutboundInteraction = true;
                }
            }
        }
        else {
            if (getStorageItem(Constant.lsForEmailOutbound) == FunctionsVariable.scriptId) {
                isInteractionTypeEmailOutbound(interaction);
            }
        }
    }
    if (FunctionsVariable.processingInteractionId != interaction.id) {
        FunctionsVariable.processingInteractionId = null;
    }
}
export function interactionChangedEvent(e) {
    const interaction = e.data.interaction.new;
    if (interaction.attributes && interaction.attributes.is_entitytype != undefined && interaction.attributes.is_entityid != undefined && interaction.attributes.is_outboundcheckforassociation && interaction.attributes.is_outboundcheckforassociation && !clickToDialAssociationCache.has(interaction.id)) {
        const entityName = interaction.attributes.is_entitytype;
        const entityId = interaction.attributes.is_entityid;
        clickToDialAssociationCache.set(interaction.id, true);
        executeAssociationBasedOnEntityGuid(entityName, entityId, null, interaction.id);
    }
    if (PartnerVariable.newOutboundInteraction) {
        log("interactionChangedEvent: newOutboundInteraction");
        checkForScreenPopOnOutboundCall(interaction);
    }
}
export function interactionDeallocationEvent(e) {
    const interactionId = e.data.interaction.id;
    closeActivityBasedOnConfigSettingValue(interactionId, "deallocate");
    setTimeout(function () {
        removeState(interactionId);
        removeMakeCallInteractionId(interactionId);
        removeActivityData(interactionId);
        removeHasHandledChatTranscript(interactionId);
        removeDescription(interactionId);
        removeEntityCache(interactionId);
        removeChatTranscriptCache(interactionId);
        removeAssociationDataFromCache(interactionId);
        removeScreenPopData(interactionId);
        removeActualStartSet(interactionId);
        removeInteractionData(interactionId);
        removeStorageItem(Constant.ssPopNewPage);
        removeAssociationObjectFromNewPopWindow(interactionId);
        removeProcessedNewInteractionIdForAssociation(interactionId);
        removePopActivityOnSave(interactionId);
        setStorageItem(Constant.lsRemovedAssociationFromSession, interactionId);
        removeMessagingTranscriptCache(interactionId);
        removeSubjectAndDurationForMessaging(interactionId);
        removewrapup(interactionId, Constant.ssCheckDispositionObj);
        removeInteractionStateSequenceMapping("interactionId", interactionId);
        removeRegardingAssociationFromSessionStorage(interactionId);
        resetCustomAttributesValue(interactionId);
        if (isCIFV2()) {
            removeInteractionTabId(interactionId);
            removeSessionMapping("interactionId", interactionId);
            removeInteractionStateMapping("interactionId", interactionId);
            removeInteractionIdsFromGlobalArray(interactionId);
            removeSessionTabsForWSTFromSession("interactionId", interactionId);
        }
    }, 1000);
}
/*
 * requestAuthToken, setAuthcode, getAuthcode added for invoking purecloud api from ribbon button or crm web resource
 * Token getting on login then setting to session storage but this will unable to use on different domain
 * Getting CROS issue when using this token on crm web resource for invoking purecloud api
 * Because token requested by isgc_sidebar.js CIF URL and using by CRM web resource URL
 */
export function requestAuthToken() {
    const msg = {
        messageType: "getAuthToken",
        text: "token"
    };
    queuePostMessage(msg);
}
export function setAuthcode(data) {
    const authTokenKey = data.data.token;
    setStorageItem(Constant.ssAuthToken, authTokenKey);
}
export function getAuthcode() {
    const authTokenCache = getStorageItem(Constant.ssAuthToken);
    const tokenObject = {
        type: "isync_getAuthTokenResponse",
        token: authTokenCache
    };
    window.top.postMessage(tokenObject, window.location.ancestorOrigins[0]);
}
export function setScreenPopData(screenpopevent, interactionId) {
    setItemDataOnInteractionIdByLocation(JSON.stringify(screenpopevent), interactionId, Constant.ssScreenPopEvent);
}
export function getScreenPopData(interactionId) {
    return getItemBasedOnInteractionIdByLocation(interactionId, Constant.ssScreenPopEvent);
}
function removeScreenPopData(interactionId) {
    removeItemDataOnInteractionId(interactionId, Constant.ssScreenPopEvent);
}
function setActualStartSet(isActualStartSet, interactionId) {
    const actualStart = isActualStartSet ? "true" : null;
    setItemDataOnInteractionIdByLocation(actualStart, interactionId, Constant.ssActualStartSet);
}
function getActualStartSet(interactionId) {
    const actualStart = getItemBasedOnInteractionIdByLocation(interactionId, Constant.ssActualStartSet);
    if (actualStart == null || actualStart != "true") {
        return false;
    }
    return true;
}
function removeActualStartSet(interactionId) {
    removeItemDataOnInteractionId(interactionId, Constant.ssActualStartSet);
}
export function setPopActivityOnSave(isPopActivity, interactionId) {
    const popActivity = isPopActivity ? "true" : null;
    setItemDataOnInteractionIdByLocation(popActivity, interactionId, Constant.ssPopActivityOnSave);
}
function getPopActivityOnSave(interactionId) {
    const popActivity = getItemBasedOnInteractionIdByLocation(interactionId, Constant.ssPopActivityOnSave);
    if (popActivity == null || popActivity != "true") {
        return false;
    }
    return true;
}
function removePopActivityOnSave(interactionId) {
    removeItemDataOnInteractionId(interactionId, Constant.ssPopActivityOnSave);
}
function setItemDataOnInteractionIdByLocation(screenpopEventData, interactionId, ssScreenPopEvent) {
    let shouldUpdate = false;
    const getItemDataOnInteractionIdByLocation = getStorageItem(ssScreenPopEvent);
    if (getItemDataOnInteractionIdByLocation) {
        const itemDataOnInteractionIdByLocationArray = JSON.parse(getItemDataOnInteractionIdByLocation);
        if (itemDataOnInteractionIdByLocationArray.length > 0) {
            const finditemDataOnInteractionIdByLocation = searchInJSON(itemDataOnInteractionIdByLocationArray, "interactionId", interactionId);
            if (finditemDataOnInteractionIdByLocation.length > 0) {
                addItemDataOnInteractionIdByLocation(itemDataOnInteractionIdByLocationArray, interactionId, screenpopEventData, ssScreenPopEvent, shouldUpdate);
            }
            else if (finditemDataOnInteractionIdByLocation.length === 0) {
                shouldUpdate = true;
                addItemDataOnInteractionIdByLocation(itemDataOnInteractionIdByLocationArray, interactionId, screenpopEventData, ssScreenPopEvent, shouldUpdate);
            }
        }
        else if (itemDataOnInteractionIdByLocationArray.length == 0) {
            shouldUpdate = true;
            addItemDataOnInteractionIdByLocation(itemDataOnInteractionIdByLocationArray, interactionId, screenpopEventData, ssScreenPopEvent, shouldUpdate);
        }
    }
    else {
        addItemDataOnInteractionIdByLocation(null, interactionId, screenpopEventData, ssScreenPopEvent, shouldUpdate);
    }
}
function getItemBasedOnInteractionIdByLocation(interactionId, ssScreenPopEvent) {
    const getItemDataOnInteractionIdByLocation = getStorageItem(ssScreenPopEvent);
    if (getItemDataOnInteractionIdByLocation) {
        const itemDataOnInteractionIdByLocationArray = JSON.parse(getItemDataOnInteractionIdByLocation);
        if (itemDataOnInteractionIdByLocationArray.length > 0) {
            const finditemDataOnInteractionIdByLocationg = searchInJSON(itemDataOnInteractionIdByLocationArray, "interactionId", interactionId);
            if (finditemDataOnInteractionIdByLocationg.length === 1) {
                return finditemDataOnInteractionIdByLocationg[0].screenpopEventData;
            }
        }
        return null;
    }
}
function addItemDataOnInteractionIdByLocation(existingArray, interactionId, screenpopEventData, ssScreenPopEvent, shouldUpdate) {
    let saveItemDataOnInteractionIdByLocationArray = [];
    if (existingArray && existingArray.length > 0 && shouldUpdate === false) {
        saveItemDataOnInteractionIdByLocationArray = existingArray;
        for (let i = 0; i < saveItemDataOnInteractionIdByLocationArray.length; i++) {
            if (saveItemDataOnInteractionIdByLocationArray[i].interactionId == interactionId) {
                saveItemDataOnInteractionIdByLocationArray[i].screenpopEventData = screenpopEventData;
                break;
            }
        }
        setStorageItem(ssScreenPopEvent, JSON.stringify(saveItemDataOnInteractionIdByLocationArray));
    }
    else if (existingArray && existingArray.length === 0 && shouldUpdate) {
        saveItemDataOnInteractionIdByLocationArray = existingArray;
        const saveItemDataOnInteractionIdByLocationObject = {
            interactionId: interactionId,
            screenpopEventData: screenpopEventData
        };
        saveItemDataOnInteractionIdByLocationArray.push(saveItemDataOnInteractionIdByLocationObject);
        setStorageItem(ssScreenPopEvent, JSON.stringify(saveItemDataOnInteractionIdByLocationArray));
    }
    else {
        const saveItemDataOnInteractionIdByLocationObject = {
            interactionId: interactionId,
            screenpopEventData: screenpopEventData
        };
        saveItemDataOnInteractionIdByLocationArray.push(saveItemDataOnInteractionIdByLocationObject);
        setStorageItem(ssScreenPopEvent, JSON.stringify(saveItemDataOnInteractionIdByLocationArray));
    }
}
export function screenPopCurrentInteraction() {
    const currentInteraction = getCurrentInteractionId();
    if (currentInteraction != null) {
        let screenpopEventData = getScreenPopData(currentInteraction);
        if (screenpopEventData != null) {
            log("manually requested screen pop from user");
            screenpopEventData = JSON.parse(screenpopEventData);
            screenpop(screenpopEventData, true);
        }
    }
}
function addRenderSearchValuesToCache(entityname, search) {
    const renderSearchObject = {
        entityname: entityname,
        search: search
    };
    setStorageItem(Constant.ssRenderSearchParams, JSON.stringify(renderSearchObject));
}
export function checkIsRenderSearchInCache() {
    let renderSearchValue = getStorageItem(Constant.ssRenderSearchParams);
    if (renderSearchValue) {
        renderSearchValue = JSON.parse(renderSearchValue);
        if (renderSearchValue.entityname && renderSearchValue.search) {
            renderSearchPage(renderSearchValue.entityname, renderSearchValue.search, false).done(function () {
                removeRenderSearchCache();
            });
        }
    }
}
function saveRenderSearchAndOpenContactPageInNewTab(entityname, search, newPage) {
    addRenderSearchValuesToCache(entityname, search);
    const entityFormOptions = {};
    entityFormOptions["entityName"] = "contact";
    entityFormOptions["openInNewWindow"] = true;
    openForm(entityFormOptions, null, newPage, null);
}
function removeRenderSearchCache() {
    removeStorageItem(Constant.ssRenderSearchParams);
}
function setShouldCallAssociation() {
    const ShouldCallAssociationForNewTab = true;
    setStorageItem(Constant.ssShouldCallAddAssociation, JSON.stringify(ShouldCallAssociationForNewTab));
}
export function checkAndRemoveShouldCallAddAssociation() {
    let ShouldCallAddAssociationValue = getStorageItem(Constant.ssShouldCallAddAssociation);
    if (ShouldCallAddAssociationValue) {
        ShouldCallAddAssociationValue = JSON.parse(ShouldCallAddAssociationValue);
        if (ShouldCallAddAssociationValue) {
            addAssociation();
            removeStorageItem(Constant.ssShouldCallAddAssociation);
        }
    }
    checkLsNewBlankRecordPop();
}
const debounceCallFunctionForExecuteAssociation = debounce(function (data, interactionId, CTDFromSoftphoneNotPresent) {
    let isNewInteraction = false;
    if (shouldShowSoftphoneOnSingleTab()) {
        interactionId = checkForInteractionIdFromLsCollectionInteractionIdsForSoftPhToAllTabFalse(data, interactionId);
        if (interactionId == getNewInteractionId()) {
            addProcessedNewInteractionIdForAssociation(interactionId);
            removeNewInteractionId();
        }
    }
    else if (interactionId && !shouldShowSoftphoneOnSingleTab()) {
        addProcessedNewInteractionIdForAssociation(interactionId);
        if (interactionId == getNewInteractionId()) {
            removeNewInteractionId();
        }
    }
    else if (FunctionsVariable.recordNewInteraction && FunctionsVariable.recordNewInteraction.scriptId == FunctionsVariable.scriptId && interactionId == null && FunctionsVariable.flagNewRecordPop) {
        interactionId = FunctionsVariable.recordNewInteraction.interactionId;
        addProcessedNewInteractionIdForAssociation(interactionId);
        FunctionsVariable.recordNewInteraction = null;
        FunctionsVariable.flagNewRecordPop = false;
    }
    if (interactionId == null || interactionId == undefined) {
        interactionId = getNewInteractionId();
        removeNewInteractionId();
        const lsProcessedNewInteractionIdCollection = getStorageItem(Constant.lsProcessedNewInteractionIds);
        if (lsProcessedNewInteractionIdCollection && isJSON(lsProcessedNewInteractionIdCollection) && JSON.parse(lsProcessedNewInteractionIdCollection).length > 0) {
            const isPresentInteractionId = searchInJSON(JSON.parse(lsProcessedNewInteractionIdCollection), "interactionId", interactionId);
            if (isPresentInteractionId && isPresentInteractionId.length > 0) {
                interactionId = null;
            }
        }
        if (FunctionsVariable.setNewInteractionBelongToNewTab && interactionId != null) {
            interactionId = null;
        }
        if (FunctionsVariable.newPopRecordNotSaved) {
            interactionId = null;
            FunctionsVariable.newPopRecordNotSaved = false;
        }
        if (interactionId !== null) {
            addProcessedNewInteractionIdForAssociation(interactionId);
            removeNewInteractionId();
        }
        else {
            interactionId = getCurrentInteractionId();
        }
    }
    else if (CTDFromSoftphoneNotPresent) {
        log("CTDFromSoftphoneNotPresent value is" + CTDFromSoftphoneNotPresent);
    }
    if (interactionId == null || interactionId == "") {
        return true;
    }
    else {
        isNewInteraction = true;
    }
    data = JSON.parse(data);
    const entityName = data.etn;
    if (entityName == undefined) {
        if (!shouldCallAddAssociationAgain()) {
            if (isNewInteraction) {
                setNewInteractionId(interactionId);
            }
            return true;
        }
    }
    let guidId = data.id;
    if (!isGuid(guidId)) {
        getEnvironment()
            .done(function (data) {
            data = JSON.parse(data);
            guidId = data.id;
            executeAssociationBasedOnEntityGuid(entityName, guidId, isNewInteraction, interactionId);
        })
            .fail(function (error) {
            log(error);
        });
    }
    else {
        executeAssociationBasedOnEntityGuid(entityName, guidId, isNewInteraction, interactionId);
    }
}, 200);
export function executeAssociation(data, interactionId, CTDFromSoftphoneNotPresent) {
    debounceCallFunctionForExecuteAssociation(data, interactionId, CTDFromSoftphoneNotPresent);
}
function getInteractionURL() {
    return getParticipantCustomAttribute("interactionLink");
}
export function getFieldByEntityLogicalName(entityName, guidId) {
    const deferred = $.Deferred();
    const entityField = getEntityFieldName(entityName, true);
    if (entityName != undefined && guidId != undefined && entityField != null) {
        const qry = "?$select=" + entityField;
        retrieveRecord(entityName, guidId, qry)
            .done(function (result) {
            result = JSON.parse(result);
            const entityFieldValue = result[entityField];
            deferred.resolve(entityFieldValue);
        })
            .fail(function (error) {
            deferred.reject(error);
        });
    }
    else if (entityField == null) {
        try {
            const attributes = ["DisplayName"];
            getEntityMetadata(entityName, attributes)
                .done(function (entity) {
                deferred.resolve(entity.DisplayName);
            })
                .fail(function (error) {
                deferred.reject(error);
            });
        }
        catch (ex) {
            return entityName;
        }
    }
    return deferred.promise();
}
function getEntityFieldName(EntityLogicalName, isWorkSpaceTransfer) {
    switch (EntityLogicalName) {
        case "account":
            return "name";
        case "contact":
            return "fullname";
        case "lead":
            if (isWorkSpaceTransfer) {
                return "subject";
            }
            else {
                return "fullname";
            }
        case "incident":
            return "title";
        case "opportunity":
            return "name";
        default:
            return null;
    }
}
export function shouldScreenPopInNewPage(interaction) {
    if (Object.hasOwn(interaction, "attributes") && interaction.attributes != undefined) {
        if (Object.hasOwn(interaction.attributes, "is_popnewpage") && interaction.attributes.is_popnewpage != null && interaction.attributes.is_popnewpage != undefined && interaction.attributes.is_popnewpage != "") {
            return true;
        }
    }
    return false;
}
export function shouldPopQueryEntity(interaction) {
    if (Object.hasOwn(interaction.attributes, "is_popqueryentity") && Object.hasOwn(interaction.attributes, "is_popqueryparameters") && Object.hasOwn(interaction.attributes, "is_popquerysearchstring") && !Object.hasOwn(interaction.attributes, "is_tabarray")) {
        if (interaction.attributes.is_popqueryentity != (null || undefined) && interaction.attributes.is_popqueryparameters != (null || undefined) && interaction.attributes.is_popquerysearchstring != (null || undefined)) {
            return true;
        }
    }
    return false;
}
export function executeQueryRecord(is_popqueryentity, is_popqueryparameters, is_popquerysearchstring, newPage, interaction) {
    searchAndOpenRecords(is_popqueryentity, is_popqueryparameters, true)
        .done(function (results) {
        results = JSON.parse(results);
        openMatchRecord(results, is_popqueryentity, is_popquerysearchstring, newPage, false, false, interaction);
    })
        .fail(function (error) {
        sendNotification("Failed to execute screen pop query. Contact your system administrator. Error: " + error, "error");
    });
}
export function openMatchRecord(results, is_popqueryentity, is_popquerysearchstring, newPage, prefillData, isEmail, interaction) {
    const screenPopConfig = getScreenPopConfiguration(interaction);
    switch (Object.keys(results).length) {
        case 1: //1 match
            log("inside 1 matched Record");
            createSessionForSingleMatchRecord(results, is_popqueryentity, screenPopConfig, prefillData, interaction, newPage);
            break;
        case 0: //0 matches
            log("inside 0 matched Record");
            createSessionForNoMatchRecord(screenPopConfig, is_popqueryentity, is_popquerysearchstring, interaction, prefillData, isEmail, newPage);
            break;
        default: //Many matches
            log("inside Multiple matches Record");
            createSessionForMultipleMatchesRecord(screenPopConfig, is_popqueryentity, is_popquerysearchstring, interaction, newPage);
            break;
    }
}
export function createAssociationObject(interaction, callLog) {
    let subject = "";
    let description = null;
    let regardingEntityName = "";
    let regardingEntityText = "";
    let regardingEntityId = "";
    let contactEntityName = "";
    let contactText = "";
    let contactId = "";
    const direction = getInteractionDirection(interaction);
    if (Object.hasOwn(callLog, "subject")) {
        subject = getSubjectIfMessagingInteraction(interaction, callLog.subject);
    }
    if (Object.hasOwn(callLog, "notes")) {
        description = callLog.notes;
    }
    if (callLog.selectedRelation != undefined) {
        regardingEntityText = callLog.selectedRelation.text;
        regardingEntityId = callLog.selectedRelation.id;
        const splitregardingentity = regardingEntityText.split("-");
        if (splitregardingentity.length > 1) {
            regardingEntityName = splitregardingentity.pop();
            regardingEntityText = splitregardingentity.join("-");
        }
        else {
            if (regardingEntityText.toString().trim() != "") {
                regardingEntityName = regardingEntityText.trim();
            }
        }
    }
    if (callLog.attributes && callLog.attributes.is_activitywhoid && callLog.attributes.is_activitywhotype) {
        contactEntityName = callLog.attributes.is_activitywhotype;
        contactText = "";
        contactId = callLog.attributes.is_activitywhoid;
    }
    else if (callLog.selectedContact !== undefined) {
        contactEntityName = "contact";
        contactText = callLog.selectedContact.text;
        contactId = callLog.selectedContact.id;
        const spliteentity = contactText.split("-");
        if (spliteentity.length > 1) {
            const arr = contactText.split("-");
            contactEntityName = arr.pop();
            contactText = arr.join("-");
        }
    }
    const associationObj = {
        regardingid: "{" + regardingEntityId + "}",
        regardingname: regardingEntityText,
        regardingentityname: regardingEntityName,
        subject: subject,
        description: description,
        interactionID: interaction.id,
        phonecallupdate: true,
        directioncode: direction == "Inbound" ? "false" : "true",
        assocId: "{" + contactId + "}",
        assocName: contactText,
        assocEntityName: contactEntityName,
        direction: direction
    };
    if (direction == "Inbound") {
        if (interaction.isMessage) {
            associationObj.inboundPhoneNumber = interaction.displayAddress;
        }
        else {
            associationObj.inboundPhoneNumber = interaction.ani;
        }
    }
    return associationObj;
}
function getMappingBasedEntityName(entityName) {
    const mappingData = getParticipantCustomAttribute("activityMappingData");
    if (mappingData && Object.hasOwn(mappingData, entityName)) {
        return mappingData[entityName];
    }
    return null;
}
function getSchemaNameByMappingData(entityName) {
    const mappingData = getParticipantCustomAttribute("activityMappingData");
    if (mappingData && Object.hasOwn(mappingData, entityName)) {
        const schemaname = mappingData[entityName][0].activityTypeSchemaName;
        return schemaname;
    }
    else {
        return null;
    }
}
export function isInteractionForCustomActivity(interaction) {
    if (interaction.attributes && Object.hasOwn(interaction.attributes, "is_activityid") && Object.hasOwn(interaction.attributes, "is_activitytype") && interaction.attributes.is_activitytype && !Constant.defaultEntityCollection.includes(interaction.attributes.is_activitytype)) {
        return true;
    }
    return false;
}
export function isGuid(stringToTest) {
    if (stringToTest[0] === "{") {
        stringToTest = stringToTest.substring(1, stringToTest.length - 1);
    }
    const regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}$/gi;
    return regexGuid.test(stringToTest);
}
export function initilizeMappingDetectCreate(interaction, entity, activityId, associationObj, direction, entityName) {
    let initilizeMappingDetectCreateObject = {
        entitySchemaName: getSchemaNameByMappingData(entityName),
        entityMappingdata: getMappingBasedEntityName(entityName),
        isCreate: activityId == null ? true : false,
        entity: entity
    };
    const interactionType = checkInteractionTypeIsMessage(interaction);
    if (interaction.isCallback && !interaction.isDialerPreview) {
        if (activityId != null) {
            storeCalledNumberForCallback(entity, interaction, entityName);
        }
        if (interaction.isVoicemail) {
            initilizeMappingDetectCreateObject.entity.isgc_isvoicemail = true;
        }
    }
    else if (!isInteractionForCustomActivity(interaction) && interaction.isEmail) {
        if (activityId == null) {
            const data = {
                isgc_directioncode: associationObj.directioncode,
                isgc_emailaddress: associationObj.directioncode == "true" ? interaction.calledNumber : interaction.ani
            };
            initilizeMappingDetectCreateObject.entity = mergeProps(entity, data);
        }
    }
    else if (interactionType && interactionType.toLowerCase() == "webmessaging") {
        if (activityId == null) {
            initilizeMappingDetectCreateObject = assignDirectionCodeForMessageTypeInteraction(direction, initilizeMappingDetectCreateObject);
        }
    }
    else if (interaction.isMessage) {
        const msgType = ["sms", "facebook", "twitter", "whatsapp", "line", "open"];
        const msgTypeVal = msgType.indexOf(interaction.messageType.toString().trim().toLowerCase()) + 1;
        if (activityId == null) {
            initilizeMappingDetectCreateObject.entity.isgc_messagetype = msgTypeVal;
            initilizeMappingDetectCreateObject = assignDirectionCodeForMessageTypeInteraction(direction, initilizeMappingDetectCreateObject);
        }
    }
    else {
        if (!isInteractionForCustomActivity(interaction) && !interaction.isChat) {
            if (activityId == null) {
                let data = {};
                if (direction == "Inbound") {
                    data = {
                        directioncode: false,
                        phonenumber: associationObj.inboundPhoneNumber,
                        isgc_callednumber: interaction.calledNumber
                    };
                }
                else {
                    if (interaction.isDialer) {
                        data = {
                            directioncode: true,
                            phonenumber: interaction.ani
                        };
                    }
                    else {
                        data = {
                            directioncode: true,
                            phonenumber: interaction.calledNumber,
                            isgc_callednumber: interaction.calledNumber
                        };
                    }
                }
                initilizeMappingDetectCreateObject.entity = mergeProps(entity, data);
            }
            else {
                if (associationObj.inboundPhoneNumber != "") {
                    initilizeMappingDetectCreateObject.entity.phonenumber = associationObj.inboundPhoneNumber;
                }
                if (interaction.isDialerPreview) {
                    initilizeMappingDetectCreateObject.entity.phonenumber = interaction.calledNumber;
                }
            }
        }
    }
    return initilizeMappingDetectCreateObject;
}
export function fetchmappingForWrapupForCustomActivity(entityName) {
    const mappingCustomActivity = getMappingBasedEntityName(entityName);
    let getCRMFieldName = null;
    if (mappingCustomActivity) {
        for (let i = 0; i < mappingCustomActivity.length; i++) {
            if (mappingCustomActivity[i].pureCloudField.toLowerCase().includes("interactionsync.wrapup")) {
                getCRMFieldName = mappingCustomActivity[i].activityFields;
                break;
            }
        }
        return getCRMFieldName;
    }
    return null;
}
export function getDefaultfieldsValues(key, activityName) {
    const jsonData = getParticipantCustomAttribute("defaultfields");
    if (jsonData != null && jsonData != undefined) {
        const defaultFields = JSON.parse(jsonData);
        const isExist = searchInJSON(defaultFields, "activityLogicalName", activityName);
        if (isExist.length > 0) {
            return isExist[0][key];
        }
    }
}
export function processingBasicActivityValues(activityId, interaction, entity, callLog, entityName) {
    if (activityId) {
        return entity;
    }
    if (!isInteractionForCustomActivity(interaction)) {
        entity = assignBasicActivityValues(entity, interaction, callLog);
    }
    else {
        entity = assignBasicActivityValuesForCustomActivity(entity, interaction, callLog, entityName);
    }
    return entity;
}
function isPopFromOptionHasOpenInNewWindow(interaction) {
    if (Object.hasOwn(interaction, "attributes") && Object.hasOwn(interaction, "attributes") != undefined && JSON.stringify(interaction.attributes) !== JSON.stringify({})) {
        if (interaction.attributes && Object.hasOwn(interaction.attributes, "is_popformoptions")) {
            if (Object.hasOwn(interaction.attributes.is_popformoptions, "openInNewWindow") && !isCIFV2()) {
                return (interaction.attributes.is_popformoptions.openInNewWindow = JSON.parse(interaction.attributes.is_popformoptions.openInNewWindow));
            }
            else if (isCIFV2()) {
                return true;
            }
        }
    }
    return false;
}
function isLocalStorageItem(item) {
    return item.substring(0, 7) == "isgc_ls";
}
function setLogicalAndSchemaNameOfActivityEntity(entityName, mappingData) {
    switch (entityName) {
        case "Phonecall":
            mappingData["activityTypeLogicalName"] = Constant.phonecallName;
            mappingData["activityTypeSchemaName"] = Constant.phonecallSchemaName;
            break;
        case "Chat":
            mappingData["activityTypeLogicalName"] = Constant.chatName;
            mappingData["activityTypeSchemaName"] = Constant.chatSchemaName;
            break;
        case "Chat(isgc_chat)":
            mappingData["activityTypeLogicalName"] = Constant.chatName;
            mappingData["activityTypeSchemaName"] = Constant.chatSchemaName;
            break;
        case "Callback":
            mappingData["activityTypeLogicalName"] = Constant.callbackName;
            mappingData["activityTypeSchemaName"] = Constant.callbackSchemaName;
            break;
        case "Callback(isgc_callback)":
            mappingData["activityTypeLogicalName"] = Constant.callbackName;
            mappingData["activityTypeSchemaName"] = Constant.callbackSchemaName;
            break;
        case "Email":
            mappingData["activityTypeLogicalName"] = Constant.emailName;
            mappingData["activityTypeSchemaName"] = Constant.emailSchemaName;
            break;
        case "GenesysCloudEmail(isgc_email)":
            mappingData["activityTypeLogicalName"] = Constant.emailName;
            mappingData["activityTypeSchemaName"] = Constant.emailSchemaName;
            break;
        case "Message":
            mappingData["activityTypeLogicalName"] = Constant.messageName;
            mappingData["activityTypeSchemaName"] = Constant.messageSchemaName;
            break;
        case "GenesysCloudMessage(isgc_message)":
            mappingData["activityTypeLogicalName"] = Constant.messageName;
            mappingData["activityTypeSchemaName"] = Constant.messageSchemaName;
            break;
        case "WebMessaging(isgc_webmessaging)":
            mappingData["activityTypeLogicalName"] = Constant.webMessageName;
            mappingData["activityTypeSchemaName"] = Constant.webMessageSchemaName;
            break;
    }
}
export function isDirtyCurrentPage() {
    const deferred = $.Deferred();
    getEnvironment()
        .done(function (data) {
        data = JSON.parse(data);
        deferred.resolve(data.isDirty);
    })
        .fail(function (error) {
        deferred.reject(error);
    });
    return deferred.promise();
}
function getClientIdBasedOnSelectedRegion(recallCounter) {
    let clientRegionObject = null;
    const regionURL = getFullClientURL();
    if (regionURL) {
        clientRegionObject = {};
        switch (regionURL) {
            case "https://apps.mypurecloud.com.au":
                clientRegionObject.clientId = "cf581632-a0e6-4f09-8f72-8155f15d2040";
                clientRegionObject.region = "mypurecloud.com.au";
                break;
            case "https://apps.mypurecloud.jp":
                clientRegionObject.clientId = "8bc3f2fd-a2ac-4fcc-9f1b-04581acfdcfb";
                clientRegionObject.region = "mypurecloud.jp";
                break;
            case "https://apps.mypurecloud.de":
                clientRegionObject.clientId = "0f9ef94f-fa04-4318-863d-966268416131";
                clientRegionObject.region = "mypurecloud.de";
                break;
            case "https://apps.mypurecloud.ie":
                clientRegionObject.clientId = "8bf0eafa-b5c2-4fa2-8f05-db17418dd7d2";
                clientRegionObject.region = "mypurecloud.ie";
                break;
            case "https://apps.mypurecloud.com":
                clientRegionObject.clientId = "c641aa1d-9e06-4ffa-afca-44034b223ac2";
                clientRegionObject.region = "mypurecloud.com";
                break;
            case "https://apps.usw2.pure.cloud":
                clientRegionObject.clientId = "8cda3730-67e1-4457-a8c0-c49122e9e813";
                clientRegionObject.region = "usw2.pure.cloud";
                break;
            case "https://apps.euw2.pure.cloud":
                clientRegionObject.clientId = "8cda3730-67e1-4457-a8c0-c49122e9e813";
                clientRegionObject.region = "euw2.pure.cloud";
                break;
            case "https://apps.apne2.pure.cloud":
                clientRegionObject.clientId = "8cda3730-67e1-4457-a8c0-c49122e9e813";
                clientRegionObject.region = "apne2.pure.cloud";
                break;
            case "https://apps.cac1.pure.cloud":
                clientRegionObject.clientId = "8cda3730-67e1-4457-a8c0-c49122e9e813";
                clientRegionObject.region = "cac1.pure.cloud";
                break;
            case "https://apps.aps1.pure.cloud":
                clientRegionObject.clientId = "8cda3730-67e1-4457-a8c0-c49122e9e813";
                clientRegionObject.region = "aps1.pure.cloud";
                break;
        }
        let privateClientIds = getSettingFromConfig("privateDeploymentJson");
        if (privateClientIds) {
            privateClientIds = privateClientIds.clientIds;
            clientRegionObject.clientId = Object.values(privateClientIds)[0];
        }
        return clientRegionObject;
    }
    else {
        if (recallCounter < 10) {
            setTimeout(function () {
                recallCounter = recallCounter + 1;
                getClientIdBasedOnSelectedRegion(recallCounter);
            }, 500);
        }
        else {
            return clientRegionObject;
        }
    }
}
export function getConfigSettingsforVoiceTranscripts() {
    const clientId = getSettingFromConfig("clientIdforVoiceTranscripts");
    const clientSecret = getSettingFromConfig("clientSecretforVoiceTranscripts");
    const interactionsyncConfigId = getParticipantCustomAttribute("interactionsyncConfigId");
    const clientObject = {
        type: "isgc_configdata",
        shouldTriggerVoiceTranscriptsAPI: false,
        configId: interactionsyncConfigId,
        messageData: ""
    };
    if (clientId && clientSecret) {
        clientObject.shouldTriggerVoiceTranscriptsAPI = true;
    }
    if (isRunningOnUSD()) {
        executeOnUsd("http://uii/PhoneCall/RunScript?window.top.postMessage(" + JSON.stringify(clientObject) + ", window.location.origin);");
    }
    else {
        window.top.postMessage(clientObject, window.location.ancestorOrigins[0]);
    }
}
export function sendClientId(recallCounter) {
    const clientRegionObject = getClientIdBasedOnSelectedRegion(0);
    if (getStorageItem(Constant.lsIsSidebarLogIn) != null) {
        postClientIdToRecordingPage(clientRegionObject, JSON.parse(getStorageItem(Constant.lsIsSidebarLogIn)));
    }
    else {
        if (recallCounter < 20) {
            setTimeout(function () {
                recallCounter = recallCounter + 1;
                sendClientId(recallCounter);
            }, 500);
        }
        else {
            setStorageItem(Constant.lsIsSidebarLogIn, "false");
            postClientIdToRecordingPage(clientRegionObject, false);
        }
    }
}
function postClientIdToRecordingPage(clientRegionObject, isSidebarLogIn) {
    const clientObject = {
        type: "isgc_regions",
        clientRegionObject: clientRegionObject,
        loginState: isSidebarLogIn,
        messageData: ""
    };
    if (isRunningOnUSD()) {
        executeOnUsd("http://uii/PhoneCall/RunScript?window.top.postMessage(" + JSON.stringify(clientObject) + ", window.location.origin);");
    }
    else {
        window.top.postMessage(clientObject, window.location.ancestorOrigins[0]);
    }
}
export function getParticipantCustomAttribute(settingName) {
    if (isConfigurationLoaded()) {
        const jsonSettingData = JSON.parse(window.participantCustomAttribute);
        if (jsonSettingData) {
            if (Object.hasOwn(jsonSettingData, settingName)) {
                return jsonSettingData[settingName];
            }
            else {
                const lsConfigurationSettingData = getStorageItem(Constant.lsConfigurationSetting);
                if (lsConfigurationSettingData) {
                    const configurationSettingData = JSON.parse(lsConfigurationSettingData);
                    if (Object.hasOwn(configurationSettingData, settingName)) {
                        window.participantCustomAttribute = lsConfigurationSettingData;
                        return configurationSettingData[settingName];
                    }
                }
            }
        }
    }
    else {
        const lsConfigurationSettingData = getStorageItem(Constant.lsConfigurationSetting);
        if (lsConfigurationSettingData) {
            window.participantCustomAttribute = lsConfigurationSettingData;
            return getParticipantCustomAttribute(settingName);
        }
    }
    return null;
}
function closeActivity(interactionId) {
    if (interactionId != null && interactionId != undefined) {
        let entity = getActivityEntityCache(interactionId);
        let activityData = getActivityData(interactionId);
        log("close activity requested. interaction:" + interactionId + " activityData:" + activityData);
        if (entity == null)
            entity = {};
        if (activityData != null) {
            activityData = activityData.split("|");
            const entityType = activityData[0];
            const entityId = activityData[1];
            log("close activity. type:" + entityType + " id:" + entityId);
            if (entityType == "email" && entity != null) {
                reopenEmailIfClosed(entityId).done(function (opened, currentStatusCode) {
                    if (opened) {
                        entity.statuscode = currentStatusCode;
                        entity.statecode = 1;
                        updateActivity(entityType, entityId, entity).fail(function (error) {
                            error = returnErrorMessageForApiFailed(error);
                            log(error);
                        });
                    }
                    else {
                        log("email is closed and unable to reopen");
                    }
                });
            }
            else {
                entity = {};
                entity.statecode = 1;
                const currentActivityAssociation = getActivityData(interactionId);
                if (shouldCreateActivityOnUserSelection() && (currentActivityAssociation == null || currentActivityAssociation.includes(interactionId))) {
                    //User selected activity configuration and the activity hasn't yet been created so just update the cache
                    setActivityEntityCache(entity, interactionId);
                }
                else {
                    updateActivity(entityType, entityId, entity);
                }
            }
        }
    }
}
function filterResultData(name, entityType, entityId, mobilephone, telephone1, telephone2, telephone3) {
    const searchResultObj = {
        type: "external",
        name: name,
        phone: [],
        attributes: {
            IS_EntityType: entityType,
            IS_EntityId: entityId,
            IS_SearchPop: "Y"
        }
    };
    if (mobilephone) {
        const mobilephoneObj = {
            number: mobilephone,
            label: "Mobile Phone"
        };
        searchResultObj.phone.push(mobilephoneObj);
    }
    if (telephone1) {
        const telephone1Obj = {
            number: telephone1,
            label: "Business Phone"
        };
        searchResultObj.phone.push(telephone1Obj);
    }
    if (telephone2) {
        const telephone2Obj = {
            number: telephone2,
            label: "Telephone 2"
        };
        searchResultObj.phone.push(telephone2Obj);
    }
    if (telephone3) {
        const telephone3Obj = {
            number: telephone3,
            label: "Telephone 3"
        };
        searchResultObj.phone.push(telephone3Obj);
    }
    return searchResultObj;
}
function encodeSearchString(searchString) {
    if (searchString != null && searchString != undefined) {
        searchString = encodeURIComponent(searchString);
        if (searchString.indexOf("'") != -1) {
            searchString = searchString.split("'").join("''");
        }
        return searchString;
    }
}
function setLastCallRequestID(callRequestID) {
    setStorageItem(Constant.ssLastRequestID, callRequestID);
}
function sendCurrentPageToMainTab(data) {
    if (data) {
        data = JSON.parse(data);
        const isPopNewPage = getStorageItem(Constant.ssPopNewPage);
        let interactionId = getStorageItem(Constant.ssMakeCallFromOtherTabInteractionId);
        if (interactionId || isPopNewPage) {
            manageAssociationFromNewPage(interactionId, data);
        }
        else {
            interactionId = getStorageItem(Constant.lsStoreIntercationIdForAssocitionFromNewWindow);
            manageAssociationFromNewPage(interactionId, data);
        }
    }
}
function removeMakeCallInteractionId(interactionId) {
    setStorageItem(Constant.lsClearMakeCallInteractionId, interactionId);
}
export function manageInteractionStates(e) {
    let currentInteractionState = getStorageItem(Constant.ssInteractionStates);
    if (currentInteractionState == null) {
        currentInteractionState = [];
    }
    else {
        currentInteractionState = JSON.parse(currentInteractionState);
    }
    const interactionStates = {
        interactionId: e.data.interaction.id,
        state: e.data.category
    };
    const isInteractionPresent = searchInJSON(currentInteractionState, "interactionId", e.data.interaction.id);
    if (isInteractionPresent.length == 0) {
        setCurrentInteractionState(interactionStates, currentInteractionState);
    }
    else {
        if (e.data.category == "disconnect") {
            const isStateConnect = searchInJSON(isInteractionPresent, "state", "connect");
            if (isStateConnect.length == 0) {
                if (!isCIFV2()) {
                    removeNewInteractionId();
                    removeStorageItem(Constant.lsCurrentInteractionId);
                }
            }
        }
        const isStatePresent = searchInJSON(isInteractionPresent, "state", e.data.category);
        if (isStatePresent.length == 0) {
            setCurrentInteractionState(interactionStates, currentInteractionState);
        }
    }
}
function removeState(interactionId) {
    let dataArray = getStorageItem(Constant.ssInteractionStates);
    if (dataArray != "" && dataArray != "[]" && interactionId) {
        dataArray = JSON.parse(dataArray);
        if (dataArray && dataArray.length > 0) {
            dataArray = dataArray.filter(function (ele) {
                return ele.interactionId != interactionId;
            });
        }
        setStorageItem(Constant.ssInteractionStates, JSON.stringify(dataArray));
    }
    else {
        setStorageItem(Constant.ssInteractionStates, dataArray);
    }
}
function setCurrentInteractionState(interactionStates, currentInteractionState) {
    currentInteractionState.push(interactionStates);
    setStorageItem(Constant.ssInteractionStates, JSON.stringify(currentInteractionState));
}
export function shouldRemoveSidebarFromAnotherTab() {
    $("#isynccif").remove();
    showMessagePanel(false, "InteractionSync for Genesys Cloud is available only on one page.  Check your open Dynamics tabs for the softphone. If only one page is present, refresh the current page to enable the softphone on this page.");
}
export function shouldAssociateForNewPage(isPopNewPage) {
    if (isPopNewPage) {
        setStorageItem(Constant.ssPopNewPage, isPopNewPage);
        removeStorageItem(Constant.lsPopNewPage);
        isPopNewPage = JSON.parse(isPopNewPage);
        return isPopNewPage;
    }
    return false;
}
function setAssociationBasedOnPreviousObjectIntoSession(key, value) {
    setStorageItem(key, JSON.stringify(value));
}
function getEntityMetadata(entityName, attributes) {
    const deferred = $.Deferred();
    window.Microsoft.CIFramework.getEntityMetadata(entityName, attributes).then(function success(entityResult) {
        const newEntities = JSON.parse(entityResult);
        return deferred.resolve(newEntities);
    }, function (error) {
        error = "getEntityMetadata. entity" + entityName + " error:" + returnErrorMessageForApiFailed(error);
        log(error);
        return deferred.reject(error);
    });
    return deferred.promise();
}
function createRecord(entityType, entity) {
    const deferred = $.Deferred();
    const dataStr = JSON.stringify(entity);
    try {
        window.Microsoft.CIFramework.createRecord(entityType, dataStr).then(function success(newEntity) {
            const newEntities = JSON.parse(newEntity);
            log("createRecord. type:" + entityType + " id:" + newEntities.id);
            return deferred.resolve(newEntity);
        }, function (error) {
            showMessageBasedOnCreateAndUpdateActivityFailed(error, "createRecord", entityType, null);
            deferred.reject(error);
        });
    }
    catch (ex) {
        return deferred.reject(ex.message);
    }
    return deferred.promise();
}
async function updateRecord(entityType, recordid, entity) {
    if (entity && Object.keys(entity).length != 0) {
        for (let i = 0; i < 2; i++) {
            try {
                const newEntityJson = await window.Microsoft.CIFramework.updateRecord(entityType, recordid, JSON.stringify(entity));
                log("updatedRecord entityType :" + entityType + " id:" + recordid);
                return newEntityJson;
            }
            catch (e) {
                if (i == 1) {
                    showMessageBasedOnCreateAndUpdateActivityFailed(e, "updateRecord", entityType, recordid);
                    throw e;
                }
                await wait(100);
            }
        }
    }
    return true;
}
export function isRunningOnUSD() {
    return getStorageItem(Constant.ssIsUSD);
}
export function screenpopOnUsdCtiListener(interaction, searchString, newPage) {
    if (hasEntityIdTypeAttributes(interaction.attributes)) {
        const EntityData = "LogicalName=" + interaction.attributes.is_entitytype + "%0Aid=" + interaction.attributes.is_entityid;
        executeOnUsd("http://event/?eventname=" + Constant.usdEventNewSessionRecord + "&EntityData=" + EntityData);
    }
    else {
        const entityJsonData = getInteractionActivityType(interaction);
        const searchStringJson = getIfEmailAddressInAttributes(interaction, searchString);
        let remoteAddress = searchStringJson.searchString;
        if (remoteAddress.toLowerCase() == "internal") {
            remoteAddress = "";
        }
        let params = "ANI=" +
            interaction.ani +
            `
        DNIS=` +
            interaction.calledNumber +
            `
        appname= ` +
            Constant.usdControlCTIListener +
            `
        TYPE= ` +
            entityJsonData.entityName +
            `
        remoteaddress= ` +
            remoteAddress +
            `
        newPage= ` +
            newPage +
            `
        direction= ` +
            getInteractionDirection(interaction) +
            `
        `;
        for (const key in interaction) {
            try {
                if (key != "ani" && key != "direction" && interaction[key] != undefined && interaction[key] != null) {
                    let val = interaction[key];
                    if (typeof val == "object") {
                        val = JSON.stringify(val);
                    }
                    params +=
                        "" +
                            key +
                            "=" +
                            val +
                            `
                    `;
                }
            }
            catch (e) {
                log(e.message);
            }
        }
        params +=
            "interaction=" +
                JSON.stringify(interaction) +
                `
                  `;
        executeOnUsd("http://uii/CRM Global Manager/InvokeCTI?" + encodeURIComponent(params));
        updateFlagAndSetInteractionId(interaction);
    }
}
export function getIfEmailAddressInAttributes(interaction, searchString) {
    const jsonData = {};
    jsonData.isEmail = false;
    jsonData.searchString = searchString;
    if (searchString && searchString.indexOf("orgspan.com") > 0) {
        jsonData.searchString = "";
    }
    if (interaction.attributes.is_popsearch !== undefined) {
        jsonData.searchString = interaction.attributes.is_popsearch;
    }
    else if (interaction.attributes.is_emailaddress !== undefined) {
        jsonData.searchString = interaction.attributes.is_emailaddress;
    }
    if (jsonData.searchString && isEmailAddress(jsonData.searchString)) {
        jsonData.isEmail = true;
    }
    return jsonData;
}
function getInteractionDirection(interaction) {
    if (interaction.direction == undefined) {
        interaction.direction = getInteractionData(interaction.id).direction;
    }
    let direction = interaction.direction.toString().trim().toLowerCase() == "inbound" ? "Inbound" : "Outbound";
    if (interaction.isChat) {
        direction = "Inbound";
    }
    return direction;
}
function setInteractionData(interactionData, interactionId) {
    setItemDataOnInteractionId(interactionData, interactionId, Constant.ssInteractionData);
}
export function getInteractionData(interactionId) {
    return getItemBasedOnInteractionId(interactionId, Constant.ssInteractionData);
}
function removeInteractionData(interactionId) {
    removeItemDataOnInteractionId(interactionId, Constant.ssInteractionData);
}
export function executeOnUsd(url) {
    window.open(url);
}
export function shouldOpenInteractionWindow() {
    let isValidRequest = false;
    const currentInteraction = getCurrentInteractionId();
    if (currentInteraction != null) {
        const interactionData = getInteractionData(currentInteraction);
        if (interactionData != null && (interactionData.isChat || interactionData.isEmail || interactionData.isMessage)) {
            const currentInteractionState = getStorageItem(Constant.ssInteractionStates);
            if (currentInteractionState != null) {
                const JsonObj = JSON.parse(currentInteractionState);
                const isInteractionPresent = JsonObj.filter(function (JsonObj) {
                    return JsonObj["interactionId"] == currentInteraction && JsonObj["state"] == "disconnect";
                });
                if (isInteractionPresent.length == 0) {
                    isValidRequest = true;
                }
            }
        }
    }
    return isValidRequest;
}
export function usdSessions(e) {
    const sessionId = e.data.data.sessionid;
    if (e.data.data.eventName == "newsession") {
        setStorageItem(Constant.ssCurrentSessionUSD, sessionId);
        mapNewSessionUSD(sessionId);
    }
    else if (e.data.data.eventName == "switchsession") {
        setStorageItem(Constant.ssCurrentSessionUSD, sessionId);
    }
    else {
        removeSessionMapping("sessionId", sessionId);
        const currentSession = getStorageItem(Constant.ssCurrentSessionUSD);
        if (currentSession && currentSession == sessionId) {
            removeStorageItem(Constant.ssCurrentSessionUSD);
        }
    }
}
export function openActivityOnAnySession(LogicalName, id) {
    executeOnUsd("http://uii/CRM Global Manager/Open_CRM_Page?LogicalName=" + LogicalName + "%0Aid=" + id);
}
function mapNewSessionUSD(sessionId) {
    const interactionid = getNewInteractionId();
    if (interactionid && sessionId) {
        let sessionInteractionMapping = getStorageItem(Constant.ssSessionMapping);
        const sessionObj = {
            sessionId: sessionId,
            interactionId: interactionid
        };
        if (sessionInteractionMapping == null) {
            sessionInteractionMapping = [];
            sessionInteractionMapping.push(sessionObj);
        }
        else {
            sessionInteractionMapping = JSON.parse(sessionInteractionMapping);
            const isPresent = searchInJSON(sessionInteractionMapping, "interactionId", interactionid);
            if (isPresent && isPresent.length == 0) {
                sessionInteractionMapping.push(sessionObj);
            }
        }
        setStorageItem(Constant.ssSessionMapping, JSON.stringify(sessionInteractionMapping));
    }
}
function getQueryStringParameterByName(key, url) {
    key = key.replace(/[[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + key + "(=([^&#]*)|&|#|$)"), results = regex.exec(url);
    if (!results)
        return null;
    if (!results[2])
        return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}
//-----------------------------------------------------------------CIF V2 Start------------------------------------------------------------------------//
function createNotificationTemplateParameters(interaction, searchString) {
    const notificationmappingJson = getParticipantCustomAttribute("notificationmapping");
    const notificationmapping = notificationmappingJson ? JSON.parse(notificationmappingJson) : [];
    const input = { templateName: Constant.notificationTemplate, templateParameters: {} };
    if (notificationmapping.length > 0) {
        for (let i = 0; i < notificationmapping.length; i++) {
            if (notificationmapping[i].notificationfield == "from" && notificationmapping[i].interactionattribute == "searchString") {
                if (searchString) {
                    if (!searchString.includes("orgspan.com")) {
                        input.templateParameters.from = searchString;
                    }
                    else if (Object.hasOwn(interaction, "attributes") && interaction.attributes.is_emailaddress) {
                        input.templateParameters.from = interaction.attributes.is_emailaddress;
                    }
                }
            }
            else {
                if (eval(notificationmapping[i].interactionattribute)) {
                    input.templateParameters[notificationmapping[i].notificationfield] = eval(notificationmapping[i].interactionattribute);
                }
            }
        }
    }
    return input;
}
export function getRecordSessionTemplateFromInteractionId(interactionId) {
    const interaction = getInteractionData(interactionId);
    const queueConfig = getScreenPopConfigurationByQueue(interaction);
    if (!queueConfig || !Object.hasOwn(queueConfig, "recordSessionTemplate")) {
        return Constant.entityRecordSessionTemplateName;
    }
    return queueConfig.recordSessionTemplate;
}
export function getSearchSessionTemplateFromInteractionId(interactionId) {
    const interaction = getInteractionData(interactionId);
    const queueConfig = getScreenPopConfigurationByQueue(interaction);
    if (!queueConfig || !Object.hasOwn(queueConfig, "searchSessionTemplate")) {
        return Constant.entitySearchSessionTemplateName;
    }
    return queueConfig.searchSessionTemplate;
}
export function notify(interaction, searchString) {
    const input = createNotificationTemplateParameters(interaction, searchString);
    log("Notify parameters : " + JSON.stringify(input));
    input.cancellationToken = interaction.id;
    notifyEvent(input)
        .done(function success(result) {
        if (result) {
            if (JSON.parse(result).actionName.toLowerCase() == "accept") {
                selectInteractionBasedOnSession(interaction.id, "pickup");
            }
            else if (!JSON.parse(getStorageItem(Constant.ssNotifyInteractionState))) {
                selectInteractionBasedOnSession(interaction.id, "disconnect");
            }
        }
    })
        .fail(function (error) {
        log(error);
    });
}
function createSessionForOmniChannel(inputSessionData, interactionId) {
    const interactionData = getInteractionData(interactionId);
    shouldCreateSession(interactionId, interactionData, function callback(result) {
        if (result) {
            canCreateSession()
                .done(function success(result) {
                if (result) {
                    FunctionsVariable.counterForSession = 0;
                    const isPresent = searchInJSON(Global.interactionIdsArray, "interactionId", interactionId);
                    if ((isPresent && isPresent.length == 0) || (isPresent && isPresent.length > 0 && interactionData && interactionData.isCallback == true)) {
                        if (interactionId == null) {
                            interactionId = getCurrentInteractionId();
                        }
                        if (isPresent && isPresent.length == 0) {
                            Global.interactionIdsArray.push({ interactionId: interactionId });
                        }
                        callSessionCreate(inputSessionData, interactionId);
                    }
                    else {
                        PartnerVariable.newOutboundInteraction = true;
                        log("Interaction id found in Variables.interactionIdsArray :" + JSON.stringify(isPresent));
                    }
                }
                else {
                    sendInfoMessageForCIFV2("Unable to open new session for interaction. This could be because the maximum number of sessions has been hit.");
                }
            })
                .fail(function (error) {
                log(error);
            });
        }
    });
}
function callSessionCreate(inputSessionData, interactionId) {
    if (FunctionsVariable.counterForSession < FunctionsVariable.targetCountForSession) {
        inputSessionData.templateParameters[Constant.sessionContextInteractionData] = JSON.stringify(getInteractionData(interactionId));
        /* MAC-576: Added code for Providence Group Customer to make the interaction data available in templates as slugs -Start  */
        const interactionObj = getInteractionData(interactionId);
        if (Object.keys(interactionObj).length > 0) {
            for (const prop of Object.keys(interactionObj)) {
                inputSessionData.templateParameters[prop] = interactionObj[prop];
            }
            if (interactionObj.attributes && Object.keys(interactionObj.attributes).length > 0) {
                for (const prop of Object.keys(interactionObj.attributes)) {
                    inputSessionData.templateParameters[prop] = interactionObj.attributes[prop];
                }
            }
        }
        /* MAC-576: Added code for Providence Group Customer to make the interaction data available in templates as slugs -End  */
        createSession(inputSessionData)
            .done(function success(sessionId) {
            if (sessionId && sessionId.toLowerCase().includes("session")) {
                requestFocusSession(sessionId)
                    .done(function () {
                    log("requestFocusSession: " + sessionId);
                })
                    .fail(function (error) {
                    log(error);
                });
                if (isCIFV2() && shouldHideSoftphoneInteraction()) {
                    setSoftPhonePanelMode(0);
                }
                if (interactionId) {
                    updateSessionBasedOnInteractionId(sessionId, interactionId);
                }
                else {
                    updateSessionBasedOnInteractionId(sessionId, getCurrentInteractionId());
                }
                checkCanCreateSessionLimitForUpcomingInteraction();
            }
            else {
                callSessionCreate(inputSessionData, interactionId);
            }
            if (Global.transferSessionTabsArray.length > 1) {
                createTranferTabs();
            }
        })
            .fail(function (error) {
            log(error);
            callSessionCreate(inputSessionData, interactionId);
        });
        FunctionsVariable.counterForSession++;
    }
    else {
        sendNotification("Failed to Create Session");
    }
}
function selectSessionOnChange(interaction, event) {
    let sessionInteractionMapping = getStorageItem(Constant.ssSessionMapping);
    let interactionStateMapping = getStorageItem(Constant.ssSessionSwitchedInteractionState);
    if (sessionInteractionMapping && sessionInteractionMapping != "[]" && interactionStateMapping && interactionStateMapping != "[]") {
        let isSessionIdPresent = null;
        let isInteractionIdPresent = null;
        switch (event) {
            case "sessionSwitched":
                getFocusedSession()
                    .done(function success(result) {
                    sessionInteractionMapping = JSON.parse(sessionInteractionMapping);
                    const isPresent = searchInJSON(sessionInteractionMapping, "sessionId", result);
                    if (isPresent && isPresent.length > 0) {
                        interactionStateMapping = JSON.parse(interactionStateMapping);
                        isInteractionIdPresent = searchInJSON(interactionStateMapping, "interactionId", isPresent[0].interactionId);
                        if (isInteractionIdPresent && isInteractionIdPresent.length > 0) {
                            selectInteractionBasedOnSession(isPresent[0].interactionId, "pickup");
                        }
                    }
                })
                    .fail(function (error) {
                    log(error);
                });
                break;
            case "interactionselection":
                isSessionIdPresent = searchInJSON(JSON.parse(sessionInteractionMapping), "interactionId", interaction);
                if (isSessionIdPresent.length > 0) {
                    requestFocusSession(isSessionIdPresent[0].sessionId).fail(function (error) {
                        log(error);
                    });
                }
                break;
        }
    }
}
function requestFocusSession(sessionId) {
    const deferred = $.Deferred();
    window.Microsoft.CIFramework.requestFocusSession(sessionId).then(function (result) {
        return deferred.resolve(result);
    }, function (error) {
        error = returnErrorMessageForApiFailed(error);
        log(error);
        return deferred.reject(error);
    });
    return deferred.promise();
}
function focusTab(tabId) {
    const deferred = $.Deferred();
    window.Microsoft.CIFramework.focusTab(tabId).then(function (result) {
        return deferred.resolve(result);
    }, function (error) {
        error = returnErrorMessageForApiFailed(error);
        log(error);
        return deferred.reject(error);
    });
    return deferred.promise();
}
function refreshTab(tabId, activityName) {
    const deferred = $.Deferred();
    window.Microsoft.CIFramework.refreshTab(tabId).then(function (result) {
        if (activityName) {
            setTabTitle(returnEntityDisplayName(activityName), tabId)
                .done(function success(result1) {
                return deferred.resolve(result1);
            })
                .fail(function (error) {
                error = returnErrorMessageForApiFailed(error);
                log(error);
            });
        }
        return deferred.resolve(result);
    }, function (error) {
        error = returnErrorMessageForApiFailed(error);
        log(error);
        return deferred.reject(error);
    });
    return deferred.promise();
}
function removeClosedSessionIdFromSessionStorage(sessionId) {
    removeInteractionStateMappingBasedOnSessionId("sessionId", sessionId);
    removeSessionMapping("sessionId", sessionId);
    removeTabMapping("sessionId", sessionId);
}
function sessionSwitched(eventData) {
    const tempEventData = JSON.parse(eventData);
    FunctionsVariable.currentSession = tempEventData.sessionId;
    log("sessionSwitched:" + eventData);
    selectSessionOnChange(eventData, "sessionSwitched");
    return Promise.resolve();
}
export function isCIFV2() {
    const iscifSessionData = getStorageItem(Constant.lsIsCIFv2);
    if (iscifSessionData && iscifSessionData === "true") {
        return true;
    }
    else {
        return false;
    }
}
function getSelectedTabAndRefresh(activityTabId, activityName) {
    const deferred = $.Deferred();
    focusTab(activityTabId)
        .done(function () {
        refreshTab(activityTabId, activityName)
            .done(function success() {
            return deferred.resolve("success");
        })
            .fail(function (error) {
            error = returnErrorMessageForApiFailed(error);
            log(error);
        });
    })
        .fail(function (error) {
        log(error);
        return deferred.reject("fail");
    });
    return deferred.promise();
}
function removeInteractionTabId(interactionId) {
    let dataArray = getStorageItem(Constant.ssTabMapping);
    if (dataArray != null && dataArray != "[]") {
        dataArray = JSON.parse(dataArray);
        if (dataArray.length > 0) {
            dataArray = dataArray.filter(function (ele) {
                return ele.interactionId != interactionId;
            });
            setStorageItem(Constant.ssTabMapping, JSON.stringify(dataArray));
        }
    }
}
function setTabTitle(tabName, result) {
    const deferred = $.Deferred();
    const input = {
        title: tabName
    };
    window.Microsoft.CIFramework.setTabTitle(result, input).then(function success(result) {
        return deferred.resolve(result);
    }, function (error) {
        error = returnErrorMessageForApiFailed(error);
        log(error);
        return deferred.reject(error);
    });
    return deferred.promise();
}
function setMode(setModeValue) {
    const deferred = $.Deferred();
    window.Microsoft.CIFramework.setMode(setModeValue).then(function successCallback(data) {
        return deferred.resolve(data);
    }, function errorCallback(error) {
        error = returnErrorMessageForApiFailed(error);
        log(error);
        return deferred.reject(error);
    });
    return deferred.promise();
}
function createTab(input) {
    const deferred = $.Deferred();
    window.Microsoft.CIFramework.createTab(input).then(function (result) {
        if (result) {
            return deferred.resolve(result);
        }
    }, function (error) {
        error = returnErrorMessageForApiFailed(error);
        log(error);
        return deferred.reject(error);
    });
    return deferred.promise();
}
function createTabInput(templateName, entityName, entityId) {
    const input = {
        templateName: templateName,
        templateParameters: {
            entityName: entityName,
            entityId: entityId,
            isgcData: {}
        }
    };
    return input;
}
function selectInteractionBasedOnSession(interactionId, actionName) {
    //We can also switch the view of interaction list by User.setView.
    const data = {};
    data.messageType = "updateState";
    data.id = interactionId;
    data.action = actionName;
    queuePostMessage(data);
}
function shouldCreateSession(interactionId, interactionData, callback) {
    const sessionInteractionMapping = getStorageItem(Constant.ssSessionMapping);
    if (sessionInteractionMapping != null && sessionInteractionMapping != "[]") {
        const isPresent = searchInJSON(JSON.parse(sessionInteractionMapping), "interactionId", interactionId);
        if (isPresent.length > 0) {
            const sessionId = isPresent[0].sessionId;
            getFocusedSession()
                .done(function success(result) {
                if (sessionId == result && interactionData && interactionData.isCallback == false) {
                    PartnerVariable.newOutboundInteraction = true;
                    callback(false);
                }
                else {
                    callback(true);
                }
            })
                .fail(function (error) {
                error = returnErrorMessageForApiFailed(error);
                log(error);
            });
        }
        else {
            callback(true);
        }
    }
    else {
        callback(true);
    }
}
function returnEntityDisplayName(entityName) {
    switch (entityName) {
        case Constant.phonecallName:
            return "Phone Call";
        case Constant.callbackName:
            return "CallBack";
        case Constant.chatName:
            return "Chat";
        case Constant.emailName:
            return "Email";
        case Constant.messageName:
            return returnDisplayNameBasedOnEntityType();
        case Constant.webMessageName:
            return "Web Messaging";
        default:
            return entityName;
    }
}
export function openRecordForOmnichannel(entityName, entityId, interactionId, searchstring) {
    log("entityName : " + entityName + " entityId : " + entityId + " interactionId : " + interactionId + " searchstring : " + searchstring);
    let prefilldata = null;
    if (searchstring && !(searchstring == "Internal")) {
        prefilldata = returnPrefillDataValues(searchstring, entityName);
    }
    const templateName = getRecordSessionTemplateFromInteractionId(interactionId);
    const inputSessionData = {
        templateName: templateName,
        templateParameters: {
            entityName: entityName
        }
    };
    if (entityId) {
        if (typeof entityId == "string") {
            entityId = replaceEntityIdAdditionalChar(entityId);
            inputSessionData.templateParameters.entityId = entityId;
        }
        else if (typeof entityId == "object" && Object.hasOwn(entityId, "formId")) {
            inputSessionData.templateParameters.formId = replaceEntityIdAdditionalChar(entityId.formId);
        }
    }
    if (prefilldata) {
        inputSessionData.templateParameters.isgcData = prefilldata;
    }
    else {
        inputSessionData.templateParameters.isgcData = {};
    }
    if (entityId && Object.hasOwn(entityId, "formId")) {
        inputSessionData.templateParameters.formId = entityId.formId;
    }
    createSessionForOmniChannel(inputSessionData, interactionId);
}
export function performGlobalSearchForOmnichannel(searchString, interactionId) {
    const templateName = getSearchSessionTemplateFromInteractionId(interactionId);
    const inputSessionData = {
        templateName: templateName,
        templateParameters: {
            searchText: searchString
        }
    };
    const searchType = SpecifiedTypeOfSearchBasedOnConfigSetting();
    if (searchType != null) {
        inputSessionData.templateParameters.searchType = searchType;
    }
    createSessionForOmniChannel(inputSessionData, interactionId);
    removeNewInteractionId();
}
function removeSessionMapping(key, value) {
    let sessionInteractionMapping = getStorageItem(Constant.ssSessionMapping);
    if (sessionInteractionMapping != null && sessionInteractionMapping != "[]") {
        const isPresent = searchInJSON(JSON.parse(sessionInteractionMapping), key, value);
        if (isPresent.length > 0) {
            sessionInteractionMapping = JSON.parse(sessionInteractionMapping).filter(function (ele) {
                return ele.interactionId != isPresent[0].interactionId;
            });
            setStorageItem(Constant.ssSessionMapping, JSON.stringify(sessionInteractionMapping));
        }
    }
}
export function checkInteractionState(state) {
    if (state) {
        setStorageItem(Constant.ssNotifyInteractionState, state);
    }
}
function removeTabMapping(key, value) {
    let InteractionTabMapping = getStorageItem(Constant.ssTabMapping);
    if (InteractionTabMapping != null && InteractionTabMapping != "[]") {
        const isPresent = searchInJSON(JSON.parse(InteractionTabMapping), key, value);
        if (isPresent.length > 0) {
            InteractionTabMapping = JSON.parse(InteractionTabMapping).filter(function (ele) {
                return ele.interactionId != isPresent[0].interactionId;
            });
            setStorageItem(Constant.ssTabMapping, JSON.stringify(InteractionTabMapping));
        }
    }
}
export function setInteractionStateForSessionSwitched(interactionId, interactionState) {
    if (interactionState) {
        const stateObj = {
            interactionId: interactionId,
            interactionState: interactionState
        };
        let interactionStateArray = getStorageItem(Constant.ssSessionSwitchedInteractionState);
        if (interactionStateArray == null) {
            interactionStateArray = [];
        }
        else {
            interactionStateArray = JSON.parse(interactionStateArray);
        }
        interactionStateArray.push(stateObj);
        setStorageItem(Constant.ssSessionSwitchedInteractionState, JSON.stringify(interactionStateArray));
    }
}
function removeInteractionStateMapping(key, interactionId) {
    let InteractionStateMapping = getStorageItem(Constant.ssSessionSwitchedInteractionState);
    if (InteractionStateMapping != null && InteractionStateMapping != "[]") {
        const isPresent = searchInJSON(JSON.parse(InteractionStateMapping), key, interactionId);
        if (isPresent.length > 0) {
            InteractionStateMapping = JSON.parse(InteractionStateMapping).filter(function (ele) {
                return ele.interactionId != isPresent[0].interactionId;
            });
            setStorageItem(Constant.ssSessionSwitchedInteractionState, JSON.stringify(InteractionStateMapping));
        }
    }
}
function removeInteractionStateMappingBasedOnSessionId(key, value) {
    const sessionMappingObj = getStorageItem(Constant.ssSessionMapping);
    if (sessionMappingObj != null && sessionMappingObj != "[]") {
        const isPresent = searchInJSON(JSON.parse(sessionMappingObj), key, value);
        if (isPresent.length > 0) {
            removeInteractionStateMapping("interactionId", isPresent[0].interactionId);
        }
    }
}
function createTabForActivity(applicationTabTemplateName, interactionId, entityName, entityId, shouldFocusTab) {
    const input = createTabInput(applicationTabTemplateName, entityName, entityId);
    createTab(input)
        .done(function success(openTabId) {
        if (openTabId) {
            addIS_ActivityTabIdToCustomAttrubte(interactionId, openTabId);
            manageSessionStorageForCreatedTab(openTabId, interactionId, entityName, entityId);
            if (shouldFocusTab) {
                getSelectedTabAndRefresh(openTabId, entityName);
            }
        }
    })
        .fail(function error() {
        window.Microsoft.CIFramework.getTabs(applicationTabTemplateName).then(function (openTabId) {
            if (openTabId) {
                addIS_ActivityTabIdToCustomAttrubte(interactionId, openTabId);
                manageSessionStorageForCreatedTab(openTabId, interactionId, entityName, entityId);
                if (shouldFocusTab) {
                    getSelectedTabAndRefresh(openTabId, entityName);
                }
            }
        }, function (error) {
            log("Error in createTabForActivity", error);
        });
    });
}
function addIS_ActivityTabIdToCustomAttrubte(interactionId, openTabId) {
    const attributes = { IS_ActivityTabId: openTabId, IS_ActivityInteractionId: interactionId };
    setInteractionAttributes(interactionId, attributes);
}
function returnPrefillDataValues(searchstring, entityName) {
    let prefilldata = {};
    if (searchstring) {
        if (typeof searchstring == "string" && searchstring.includes("=") && searchstring.includes("||")) {
            searchstring = searchstring.split("=").join(" ").split("||").join(" ").split(" ");
            if (searchstring.includes("fullname")) {
                prefilldata.fullname = searchstring[searchstring.indexOf("firstname") + 1] + " " + searchstring[searchstring.indexOf("lastname") + 1];
            }
            if (searchstring.includes("firstname")) {
                prefilldata.firstname = searchstring[searchstring.indexOf("firstname") + 1];
            }
            if (searchstring.includes("lastname")) {
                prefilldata.lastname = searchstring[searchstring.indexOf("lastname") + 1];
            }
            if (searchstring.includes("telephone1")) {
                prefilldata.telephone1 = searchstring[searchstring.indexOf("telephone1") + 1];
            }
        }
        else if (typeof searchstring == "object") {
            prefilldata = searchstring;
        }
        else {
            if (Global.fieldNameForPrefillData != "") {
                prefilldata[Global.fieldNameForPrefillData] = searchstring;
            }
            else {
                prefilldata["telephone1"] = searchstring;
            }
        }
        const interactionData = getInteractionData(getCurrentInteractionId());
        if (interactionData) {
            const interactionType = checkInteractionTypeIsMessage(interactionData);
            if (interactionType && (interactionType.toLowerCase() == "webmessaging" ? true : interactionType.toLowerCase() == "facebook" ? true : interactionType.toLowerCase() == "line" ? true : interactionType.toLowerCase() == "open" ? true : false)) {
                const fieldsData = ScreenPopPrefillFiledNameForMessaging(entityName, searchstring);
                if (fieldsData) {
                    prefilldata = fieldsData;
                }
            }
            if (Object.keys(prefilldata).length > 0) {
                prefilldata = JSON.stringify(prefilldata);
                return prefilldata;
            }
        }
    }
    return null;
}
export function getFocusedSession() {
    const deferred = $.Deferred();
    if (!isRunningOnUSD()) {
        window.Microsoft.CIFramework.getFocusedSession().then(function (results) {
            deferred.resolve(results);
        }, function (error) {
            error = returnErrorMessageForApiFailed(error);
            log(error);
            return deferred.reject(error);
        });
    }
    else {
        deferred.resolve("");
    }
    return deferred.promise();
}
export function addHandlerForCifV2() {
    window.Microsoft.CIFramework.addHandler("onSessionSwitched", sessionSwitched);
    window.Microsoft.CIFramework.addHandler("onSessionClosed", sessionClosed);
}
function canCreateSession() {
    const deferred = $.Deferred();
    window.Microsoft.CIFramework.canCreateSession().then(function (result) {
        return deferred.resolve(result);
    }, function (error) {
        error = returnErrorMessageForApiFailed(error);
        log(error);
        return deferred.reject(error);
    });
    return deferred.promise();
}
export function createSession(inputSessionData) {
    const deferred = $.Deferred();
    window.Microsoft.CIFramework.createSession(inputSessionData).then(function success(sessionId) {
        return deferred.resolve(sessionId);
    }, function (error) {
        error = returnErrorMessageForApiFailed(error);
        log(error);
        return deferred.reject(error);
    });
    return deferred.promise();
}
function manageCreatedSessionRecordIdIntoSessionStorage(sessionId, interactionId) {
    setStorageItem(Constant.ssFocusedSessionId, sessionId);
    const sessionObj = {
        sessionId: sessionId,
        interactionId: interactionId
    };
    let sessionInteractionMapping = getStorageItem(Constant.ssSessionMapping);
    if (sessionInteractionMapping == null) {
        sessionInteractionMapping = [];
    }
    else {
        sessionInteractionMapping = JSON.parse(sessionInteractionMapping);
    }
    sessionInteractionMapping.push(sessionObj);
    setStorageItem(Constant.ssSessionMapping, JSON.stringify(sessionInteractionMapping));
}
function sendInfoMessageForCIFV2(message) {
    sendNotification(message, "warning");
}
export function openRecordBasedOnFormParameters(interaction, newPage, screenPopConfig) {
    if (Object.hasOwn(interaction.attributes.is_popformoptions, "entityId") && screenPopConfig.oneRecord.openDetail) {
        if (!newPage) {
            newPage = isPopFromOptionHasOpenInNewWindow(interaction);
        }
        if (isCIFV2()) {
            //opening record in same window for CIFV2 "openInNewWindow" functionality...
            openRecordForOmnichannel(interaction.attributes.is_popformoptions.entityName, interaction.attributes.is_popformoptions.entityId, interaction.id, interaction.attributes.is_popformparameters);
        }
        else {
            openForm(interaction.attributes.is_popformoptions, interaction.attributes.is_popformparameters, newPage, interaction);
        }
    }
    else if (!screenPopConfig.noRecord.noPop) {
        if (isCIFV2()) {
            if (interaction.attributes["is_popformoptions"] != undefined && Object.hasOwn(interaction.attributes["is_popformoptions"], "formId")) {
                const formParameters = {};
                formParameters.formId = interaction.attributes.is_popformoptions.formId;
                if (isPopFromOptionHasOpenInNewWindow(interaction)) {
                    //opening record in same window for CIFV2 "openInNewWindow" functionality...
                    openRecordForOmnichannel(interaction.attributes.is_popformoptions.entityName, formParameters, interaction.id, interaction.attributes.is_popformparameters);
                }
            }
            else if (!Object.hasOwn(interaction.attributes.is_popformoptions, "useQuickCreateForm")) {
                openRecordForOmnichannel(interaction.attributes.is_popformoptions.entityName, null, interaction.id, interaction.attributes.is_popformparameters);
            }
            else {
                sendInfoMessageForCIFV2("Omnichannel for Customer Service does not Support 'useQuickCreateForm' functionality");
            }
        }
        else {
            if (isPopFromOptionHasOpenInNewWindow(interaction)) {
                newPage = true;
            }
            openForm(interaction.attributes.is_popformoptions, interaction.attributes.is_popformparameters, newPage, interaction);
        }
    }
}
function createOrUpdateActivityTab(interactionDetails, bits) {
    let interactionId = null;
    interactionId = checkIsInteractionIdNullOrUndefined(interactionId, interactionDetails);
    const shouldFocusTab = true;
    createTabForActivity(Constant.applicationTabTemplateName, interactionId, bits[0], bits[1], shouldFocusTab);
}
export function shouldPopWebResource(interaction) {
    if (interaction.attributes.is_popwebresource && !Object.hasOwn(interaction.attributes, "is_tabarray")) {
        return true;
    }
    else {
        return false;
    }
}
export function popWebResource(interaction) {
    const IS_PopWebResource = interaction.attributes.is_popwebresource;
    let IS_PopWebResourceData = null;
    if (interaction.attributes.is_popwebresourcedata !== null && interaction.attributes.is_popwebresourcedata !== undefined) {
        IS_PopWebResourceData = interaction.attributes.is_popwebresourcedata;
    }
    const input = {
        templateName: Constant.webresourceSessionTemplate,
        // name of the configured template
        templateParameters: {
            webresourceName: IS_PopWebResource,
            isgcData: IS_PopWebResourceData
        }
    };
    shouldCreateSession(interaction.id, interaction, function callback(result) {
        if (result) {
            const isPresent = searchInJSON(Global.interactionIdsArray, "interactionId", interaction.id);
            if (isPresent && isPresent.length == 0) {
                if (interaction.id == null) {
                    interaction.id = getCurrentInteractionId();
                }
                Global.interactionIdsArray.push({ interactionId: interaction.id });
                input.templateParameters[Constant.sessionContextInteractionData] = JSON.stringify(interaction);
                createSession(input)
                    .done(function success(sessionId) {
                    if (sessionId && sessionId.toLowerCase().includes("session")) {
                        manageCreatedSessionRecordIdIntoSessionStorage(sessionId, interaction.id);
                        addAssociation();
                    }
                })
                    .fail(function (error) {
                    error = returnErrorMessageForApiFailed(error);
                    log(error);
                });
            }
        }
    });
}
function clicktodialForActionRequest(requestData) {
    log("Invoked clicktodialForActionRequest, requestData : " + JSON.stringify(requestData));
    if (isSidebarHtmlLoad()) {
        miniClientTabOpen();
        FunctionsVariable.makeCallFromScriptId = FunctionsVariable.scriptId;
        if (!requestData.attributes) {
            requestData.attributes = {};
            requestData.attributes.IS_SearchPop = "I";
        }
        log("Sending PostMessage to Framework.js from clicktodialForActionRequest for making a call, dialData : " + JSON.stringify(requestData));
        sendPostMessage(requestData);
    }
    else {
        getEnvironment().done(function (data) {
            data = JSON.parse(data);
            const newRequestID = Math.floor(100000 + Math.random() * 900000);
            const data2 = {
                number: requestData.number,
                pageInfo: {
                    etn: data.etn,
                    id: data.id
                },
                callRequestID: newRequestID
            };
            setStorageItem(Constant.lsSendMakeCallToSoftphoneTab, JSON.stringify(data2));
            return false;
        });
    }
}
function hasEntityIdTypeAttributes(attributes) {
    if (attributes && ((Object.hasOwn(attributes, "IS_EntityType") && Object.hasOwn(attributes, "IS_EntityId")) || (Object.hasOwn(attributes, "is_entitytype") && Object.hasOwn(attributes, "is_entityid")))) {
        FunctionsVariable.flagNewRecordPop = false;
        return true;
    }
    else {
        return false;
    }
}
function updateSessionBasedOnInteractionId(sessionId, interactionId) {
    manageCreatedSessionRecordIdIntoSessionStorage(sessionId, interactionId);
    addAssociation();
}
function replaceEntityIdAdditionalChar(entityId) {
    if (entityId.includes("{")) {
        entityId = entityId.replace(/[{}]/g, "");
    }
    return entityId;
}
function notifyEvent(input) {
    log("notifyEvent: " + input);
    const deferred = $.Deferred();
    window.Microsoft.CIFramework.notifyEvent(input).then(function success(result) {
        return deferred.resolve(result);
    }, function (error) {
        log("notifyEvent error:" + returnErrorMessageForApiFailed(error));
        return deferred.reject(error);
    });
    return deferred.promise();
}
function manageSessionStorageForCreatedTab(tabId, interactionId, activityName, activityId) {
    setTabTitle(returnEntityDisplayName(activityName), tabId)
        .done(function (results) {
        log(results);
    })
        .fail(function (error) {
        error = returnErrorMessageForApiFailed(error);
        log(error);
    });
    const TabObject = {
        interactionId: interactionId,
        activityId: activityId,
        activityTabId: tabId
    };
    getFocusedSession()
        .done(function success(result) {
        const focusedSessionId = result;
        if (focusedSessionId != null) {
            TabObject.sessionId = focusedSessionId;
        }
        let tabIdArray = getStorageItem(Constant.ssTabMapping);
        if (tabIdArray == null) {
            tabIdArray = [];
        }
        else {
            //find update exisiting array
            tabIdArray = JSON.parse(tabIdArray);
            const isCreateTabFound = searchInJSON(tabIdArray, "interactionId", interactionId);
            if (isCreateTabFound && isCreateTabFound.length == 1) {
                for (let i = 0; i < tabIdArray.length; i++) {
                    if (tabIdArray[i].interactionId == interactionId) {
                        tabIdArray[i].activityId = activityId;
                        tabIdArray[i].activityTabId = tabId;
                    }
                }
                setStorageItem(Constant.ssTabMapping, JSON.stringify(tabIdArray));
                return;
            }
        }
        tabIdArray.push(TabObject);
        setStorageItem(Constant.ssTabMapping, JSON.stringify(tabIdArray));
    })
        .fail(function (error) {
        error = returnErrorMessageForApiFailed(error);
        log(error);
    });
}
export function returnErrorMessageForApiFailed(error) {
    try {
        if (isJSON(error)) {
            error = JSON.parse(error);
            if (typeof error == "object") {
                if (Object.hasOwn(error, "value") && Object.hasOwn(error.value, "errorMsg")) {
                    if (error.value.errorMsg.includes("read-only") || error.value.errorMsg.includes("The requested record was not found")) {
                        error.value.errorMsg = "The activity is unable to be updated because the activity has already been closed.  If this is unexpected, contact your system administrator";
                    }
                    error = error.value.errorMsg;
                }
                else if (Object.hasOwn(error, "message")) {
                    error = error.message;
                }
                else if (Object.hasOwn(error, "value")) {
                    if (JSON.stringify(error.value) == "{}") {
                        error = "Unknown Reason";
                    }
                }
            }
        }
        else if (error instanceof Map) {
            error = error.get("message");
        }
        return error;
    }
    catch (ex) {
        return JSON.stringify(ex.message);
    }
}
function isJSON(data) {
    let ret = true;
    try {
        JSON.parse(data);
    }
    catch (e) {
        ret = false;
    }
    return ret;
}
function createSessionForSingleMatchRecord(results, is_popqueryentity, screenPopConfig, prefillData, interaction, newPage) {
    const keys = Object.keys(results[0]);
    if (!screenPopConfig.oneRecord.noPop && screenPopConfig.oneRecord.openDetail) {
        if (isCIFV2()) {
            if (prefillData) {
                openRecordForOmnichannel(results[0].recordType, results[0].recordid, interaction.id, null);
            }
            else {
                openRecordForOmnichannel(is_popqueryentity, results[0][keys[1]], interaction.id, null);
            }
        }
        else {
            if (prefillData) {
                openEntityForm(is_popqueryentity, results[0].recordid, newPage, interaction);
            }
            else {
                openEntityForm(is_popqueryentity, results[0][keys[1]], newPage, interaction);
            }
            if (FunctionsVariable.recordNewInteraction && !newPage) {
                FunctionsVariable.flagNewRecordPop = false;
            }
            FunctionsVariable.setNewInteractionBelongToNewTab = false;
            if (shouldShowSoftphoneOnSingleTab() && !newPage) {
                removelsNewBlankRecordPopForSoftPhoneToAllTabFalseHasObject();
            }
        }
    }
    else {
        if (isCIFV2()) {
            createSessionForInteractionHaveNoPop(interaction);
        }
    }
}
function createSessionForNoMatchRecord(screenPopConfig, is_popqueryentity, is_popquerysearchstring, interaction, prefillData, isEmail, newPage) {
    if (!screenPopConfig.noRecord.noPop) {
        if (screenPopConfig.noRecord.openSearch) {
            if (isCIFV2()) {
                const searchString = is_popquerysearchstring;
                const interactionId = interaction.id;
                performGlobalSearchForOmnichannel(searchString, interactionId);
            }
            else {
                renderSearchPage(is_popqueryentity, "", newPage);
            }
        }
        else if (screenPopConfig.noRecord.openNew) {
            if (isCIFV2()) {
                let entityName = "";
                const entityId = null;
                if (screenPopConfig.noRecord.newEntity.entity != (null && undefined)) {
                    entityName = screenPopConfig.noRecord.newEntity.entity;
                    if (isEmail) {
                        Global.fieldNameForPrefillData = "emailaddress1";
                    }
                    else {
                        Global.fieldNameForPrefillData = screenPopConfig.noRecord.newEntity.field;
                    }
                }
                else {
                    entityName = is_popqueryentity;
                }
                const interactionId = interaction.id;
                if (prefillData) {
                    openRecordForOmnichannel(entityName, null, interactionId, is_popquerysearchstring);
                }
                else {
                    openRecordForOmnichannel(entityName, entityId, interactionId, null);
                }
            }
            else {
                const entityFormOptions = {};
                let parameters = {};
                entityFormOptions["entityName"] = is_popqueryentity;
                if (prefillData) {
                    entityFormOptions["entityName"] = screenPopConfig.noRecord.newEntity.entity;
                    if (isEmail) {
                        parameters["emailaddress1"] = is_popquerysearchstring;
                    }
                    else {
                        parameters[screenPopConfig.noRecord.newEntity.field] = is_popquerysearchstring;
                    }
                }
                else {
                    parameters = null;
                }
                const interactionType = checkInteractionTypeIsMessage(interaction);
                if (interactionType && (interactionType.toLowerCase() == "webmessaging" ? true : interactionType.toLowerCase() == "facebook" ? true : interactionType.toLowerCase() == "line" ? true : interactionType.toLowerCase() == "open" ? true : false)) {
                    const fieldsData = ScreenPopPrefillFiledNameForMessaging(is_popqueryentity, is_popquerysearchstring);
                    if (fieldsData) {
                        parameters = fieldsData;
                    }
                }
                openForm(entityFormOptions, parameters, newPage, interaction);
                if (!newPage && interaction.isInternal == false) {
                    FunctionsVariable.recordNewInteraction = {
                        scriptId: FunctionsVariable.scriptId,
                        interactionId: interaction.id
                    };
                    FunctionsVariable.flagNewRecordPop = true;
                    FunctionsVariable.setNewInteractionBelongToNewTab = false;
                    addlsNewBlankRecordPopForSoftPhoneToAllTabFalse(FunctionsVariable.recordNewInteraction);
                }
                else if (newPage) {
                    if (getNewInteractionId() && getCurrentInteractionId() && getNewInteractionId() != getCurrentInteractionId()) {
                        setStorageItem(Constant.lsnewBlankRecordPop, "getNewInteractionId");
                        FunctionsVariable.setNewInteractionBelongToNewTab = true;
                    }
                    else {
                        setStorageItem(Constant.lsnewBlankRecordPop, "getCurrentInteractionId");
                    }
                    if (shouldShowSoftphoneOnSingleTab()) {
                        setStorageItem(Constant.lsNewBlankRecordPopForSoftPhoneToAllTabFalse, true);
                    }
                }
            }
        }
    }
    else {
        if (isCIFV2()) {
            createSessionForInteractionHaveNoPop(interaction);
        }
    }
}
function createSessionForMultipleMatchesRecord(screenPopConfig, is_popqueryentity, is_popquerysearchstring, interaction, newPage) {
    if (!screenPopConfig.multirecord.noPop && screenPopConfig.multirecord.openSearch) {
        if (isCIFV2()) {
            const searchString = is_popquerysearchstring;
            const interactionId = interaction.id;
            performGlobalSearchForOmnichannel(searchString, interactionId);
        }
        else {
            renderSearchPage(is_popqueryentity, is_popquerysearchstring, newPage);
            if (FunctionsVariable.recordNewInteraction && !newPage) {
                FunctionsVariable.flagNewRecordPop = false;
            }
        }
        FunctionsVariable.setNewInteractionBelongToNewTab = false;
    }
    else {
        if (isCIFV2()) {
            createSessionForInteractionHaveNoPop(interaction);
        }
    }
}
function checkIsInteractionIdNullOrUndefined(interactionId, interactionDetails) {
    interactionDetails = interactionDetails.data.interaction;
    if (interactionDetails == (null || undefined)) {
        interactionId = getCurrentInteractionId();
    }
    else {
        interactionId = interactionDetails.id;
    }
    return interactionId;
}
function checkInteractionIdForNewInteraction(interactionId, sendPageData, data) {
    if ((interactionId != null && interactionId != "") || interactionId == getCurrentInteractionId()) {
        sendPageData.interaction = interactionId;
    }
    else {
        sendPageData.interaction = getNewInteractionId();
    }
    if (data) {
        sendPageData.pageInfo = {};
        sendPageData.pageInfo.etn = data.etn;
        sendPageData.pageInfo.id = data.id;
        sendPageData.pageInfo.scriptId = FunctionsVariable.scriptId;
    }
    return sendPageData;
}
function doesPrerequisiteAvailableForAssociation(data) {
    if (data) {
        const checkAttributes = JSON.parse(data);
        if (checkAttributes.etn && checkAttributes.id) {
            return true;
        }
        else {
            return false;
        }
    }
    else {
        return false;
    }
}
function checkForIS_ActivityTabIdAttributeForOpenCallLog(data) {
    if (data && Object.hasOwn(data, "attributes")) {
        if (data.attributes != undefined && Object.hasOwn(data.attributes, "is_activitytabid")) {
            return true;
        }
        else {
            return false;
        }
    }
    else {
        return false;
    }
}
function checkForIS_ActivityTabIdAttributeForProcessCallLog(data) {
    if (data) {
        if (Object.hasOwn(data.data, "interaction") && Object.hasOwn(data.data.interaction, "attributes")) {
            if (data.data.interaction.attributes != undefined && Object.hasOwn(data.data.interaction.attributes, "is_activitytabid")) {
                return true;
            }
        }
        else if (Object.hasOwn(data.data, "callLog")) {
            return false;
        }
        return false;
    }
}
function manageAssociationFromNewPage(interactionId, data) {
    let sendPageData = {};
    sendPageData = checkInteractionIdForNewInteraction(interactionId, sendPageData, data);
    if (sendPageData.pageInfo.etn != undefined && sendPageData.pageInfo.id != undefined) {
        let isAssociationAlreadyPresent = getStorageItem(Constant.ssCheckAssociationObject);
        if (isAssociationAlreadyPresent == null) {
            setAssociationBasedOnPreviousObjectIntoSession(Constant.ssCheckAssociationObject, sendPageData);
            setAssociationBasedOnPreviousObjectIntoSession(Constant.lsSendPageChangeToSoftphoneTab, sendPageData);
        }
        else {
            isAssociationAlreadyPresent = JSON.parse(isAssociationAlreadyPresent);
            const isPresent = isAssociationAlreadyPresent.pageInfo.id === sendPageData.pageInfo.id;
            if (!isPresent) {
                setAssociationBasedOnPreviousObjectIntoSession(Constant.ssCheckAssociationObject, sendPageData);
                setAssociationBasedOnPreviousObjectIntoSession(Constant.lsSendPageChangeToSoftphoneTab, sendPageData);
            }
        }
    }
}
export function setIsCifV2Value(results) {
    if (results) {
        if (results.toLowerCase().includes("session")) {
            setStorageItem(Constant.lsIsCIFv2, "true");
        }
        else {
            setStorageItem(Constant.lsIsCIFv2, "false");
        }
    }
    else {
        setStorageItem(Constant.lsIsCIFv2, "false");
    }
}
function checkIsActivityTabAlreadyPresentInActivityArray(interactionId) {
    let isPresent = null;
    const activityTabMapping = getStorageItem(Constant.ssTabMapping);
    if (activityTabMapping && interactionId) {
        isPresent = searchInJSON(JSON.parse(activityTabMapping), "interactionId", interactionId);
    }
    return isPresent;
}
function executeAssociationBasedOnEventDataValue(eventData) {
    try {
        let data = {};
        const etn = getQueryStringParameterByName("etn", eventData);
        const id = getQueryStringParameterByName("id", eventData);
        if (etn && id) {
            data.etn = etn;
            data.id = id.replace(/[{}]/g, "");
            data = JSON.stringify(data);
            if (FunctionsVariable.flagNewRecordPop) {
                FunctionsVariable.flagNewRecordPop = false;
                FunctionsVariable.recordNewInteraction = null;
                FunctionsVariable.newPopRecordNotSaved = true;
            }
            checklsNewBlankRecordPopForSoftPhoneToAllTabFalseHasObject();
            if (isSidebarHtmlLoad()) {
                executeAssociation(data, null, null);
            }
            else {
                sendCurrentPageToMainTab(data);
            }
        }
    }
    catch (error) {
        log("error in get entity from eventData - " + error.message);
    }
}
export function isActivityAttributePresentIntoInteraction(e, bits) {
    Global.isCustomActivity = false;
    const activityType = bits[0];
    let activityTabId = null;
    const defaultSessionId = "session-id-1";
    const currentInteractioID = getCurrentInteractionId();
    if (checkIsActivityTabAlreadyPresentInActivityArray(currentInteractioID)) {
        const tabArray = JSON.parse(getStorageItem(Constant.ssTabMapping));
        let interactionTabDetail = null;
        interactionTabDetail = searchInJSON(tabArray, "interactionId", currentInteractioID);
        if (interactionTabDetail && interactionTabDetail.length == 1) {
            activityTabId = interactionTabDetail[0].activityTabId;
        }
    }
    if (!activityTabId) {
        if (e.data != undefined && checkForIS_ActivityTabIdAttributeForOpenCallLog(e.data.callLog)) {
            activityTabId = e.data.callLog.attributes.is_activitytabid;
        }
        else if (e && checkForIS_ActivityTabIdAttributeForProcessCallLog(e)) {
            activityTabId = e.data.interaction.attributes.is_activitytabid;
        }
    }
    if (activityTabId && activityType) {
        getFocusedTab()
            .done(function (focusTabId) {
            if (focusTabId) {
                let activityTabMapping = getStorageItem(Constant.ssTabMapping);
                if (activityTabMapping) {
                    activityTabMapping = JSON.parse(activityTabMapping);
                }
                const isPresent = searchInJSON(activityTabMapping, "activityTabId", focusTabId);
                if (isPresent && isPresent.length > 0) {
                    getEnvironment()
                        .done(function (data) {
                        if (data) {
                            data = JSON.parse(data);
                            if (!checkInteractionAttributesBasedOnType(e, data.etn)) {
                                if (data.etn && checkActivityEntityType(data.etn)) {
                                    selectAndRefreshActivityTab(activityTabId, activityType, e, bits);
                                }
                                else {
                                    createOrUpdateActivityTab(e, bits);
                                }
                            }
                            else if (Global.isCustomActivity) {
                                selectAndRefreshActivityTab(activityTabId, activityType, e, bits);
                            }
                            else {
                                createOrUpdateActivityTab(e, bits);
                            }
                        }
                    })
                        .fail(function (error) {
                        log("error in getEnvironment with " + error);
                    });
                }
                else {
                    getFocusedSession().done(function success(result) {
                        if (result == defaultSessionId) {
                            createOrUpdateActivityTab(e, bits);
                        }
                        else {
                            selectAndRefreshActivityTab(activityTabId, activityType, e, bits);
                        }
                    });
                }
            }
        })
            .fail(function (error) {
            log("error in getFocusedTab with " + error);
        });
    }
    else {
        createOrUpdateActivityTab(e, bits);
    }
}
export function compareCallLogObjectValue(callLog) {
    let result = false;
    const callLogObject = getStorageItem(Constant.ssCompareDataObject);
    if (callLogObject == null || callLogObject == "") {
        setStorageItem(Constant.ssCompareDataObject, JSON.stringify(callLog));
    }
    else {
        result = callLogObject === JSON.stringify(callLog);
        setStorageItem(Constant.ssCompareDataObject, JSON.stringify(callLog));
        return result;
    }
    return result;
}
function isAssociationExistForCurrentTab(data) {
    if (data) {
        data = JSON.parse(data);
        if (data.etn != undefined && data.id != undefined) {
            const associationObject = { entityName: data.etn, entityId: data.id, interactionId: getCurrentInteractionId() };
            let isAssociationAlreadyPresentForSidebarPresent = getStorageItem(Constant.ssCurrentTabAssociation);
            let isAssociationAlreadyPresentFromSibebarNotPresent = getStorageItem(Constant.ssCheckAssociationObject);
            if (isAssociationAlreadyPresentForSidebarPresent == null) {
                setStorageItem(Constant.ssCurrentTabAssociation, JSON.stringify(associationObject));
                return true;
            }
            else {
                isAssociationAlreadyPresentForSidebarPresent = JSON.parse(isAssociationAlreadyPresentForSidebarPresent);
                isAssociationAlreadyPresentFromSibebarNotPresent = JSON.parse(isAssociationAlreadyPresentFromSibebarNotPresent);
                const isAssociationPresent = checkIsAssociationPresentInSessionStorage(isAssociationAlreadyPresentForSidebarPresent, isAssociationAlreadyPresentFromSibebarNotPresent, associationObject);
                return isAssociationPresent;
            }
        }
    }
}
function checkIsAssociationPresentInSessionStorage(associationValueFromSidebarPresent, associationValueFromSidebarNotLoaded, associationObject) {
    let isPresent = false;
    if (associationValueFromSidebarPresent || associationValueFromSidebarNotLoaded) {
        if (associationValueFromSidebarPresent) {
            isPresent = associationValueFromSidebarPresent.entityId === associationObject.entityId && associationValueFromSidebarPresent.interactionId === associationObject.interactionId;
            if (isPresent) {
                return false;
            }
        }
        if (associationValueFromSidebarNotLoaded) {
            isPresent = associationValueFromSidebarNotLoaded.pageInfo.id === associationObject.entityId;
            if (isPresent) {
                return false;
            }
        }
        setAssociationBasedOnPreviousObjectIntoSession(Constant.ssCurrentTabAssociation, associationObject);
        return true;
    }
}
export function removeAssociationObjectFromNewPopWindow(interactionId) {
    if (interactionId) {
        let isMatchedAssociation = false;
        let removeFromTabPresent = getStorageItem(Constant.ssCurrentTabAssociation);
        let removeFromTabNotPresent = getStorageItem(Constant.ssCheckAssociationObject);
        if (removeFromTabPresent || removeFromTabNotPresent) {
            if (removeFromTabPresent) {
                removeFromTabPresent = JSON.parse(removeFromTabPresent);
                isMatchedAssociation = removeFromTabPresent.interactionId === interactionId;
                if (isMatchedAssociation) {
                    removeStorageItem(Constant.ssCurrentTabAssociation);
                }
            }
            if (removeFromTabNotPresent) {
                removeFromTabNotPresent = JSON.parse(removeFromTabNotPresent);
                isMatchedAssociation = removeFromTabNotPresent.interaction === interactionId;
                if (isMatchedAssociation) {
                    removeStorageItem(Constant.ssCheckAssociationObject);
                }
            }
        }
    }
}
function checkObjectForInteractionDisposition(interactionData) {
    let isMatched = false;
    let objForDispositionValue = {};
    const dispositionObj = getStorageItem(Constant.ssCheckDispositionObj);
    if (dispositionObj == null && interactionData) {
        objForDispositionValue = returnDispositionObject(objForDispositionValue, interactionData);
        setStorageItem(Constant.ssCheckDispositionObj, JSON.stringify(objForDispositionValue));
    }
    else {
        objForDispositionValue = returnDispositionObject(objForDispositionValue, interactionData);
        isMatched = dispositionObj === JSON.stringify(objForDispositionValue);
        setStorageItem(Constant.ssCheckDispositionObj, JSON.stringify(objForDispositionValue));
        if (interactionData.isMessage || interactionData.isCallback) {
            isMatched = false;
        }
        return isMatched;
    }
    return isMatched;
}
function returnDispositionObject(objForDispositionValue, interactionData) {
    objForDispositionValue.interactionId = interactionData.id;
    objForDispositionValue.dispositionValue = interactionData.disposition;
    return objForDispositionValue;
}
export function removeAssociationFromSessionForCTD() {
    removeStorageItem(Constant.ssCurrentTabAssociation);
    removeStorageItem(Constant.ssCheckAssociationObject);
}
function checkCanCreateSessionLimitForUpcomingInteraction() {
    canCreateSession().done(function success(result) {
        if (!result) {
            sendInfoMessageForCIFV2("Maximum session limit reached, Please close any session for next interaction.");
        }
    });
}
function isInteractionTypeEmailOutbound(interaction) {
    if (interaction && interaction.direction.toLowerCase() == "outbound" && interaction.isEmail) {
        if (isRunningOnUSD()) {
            PartnerVariable.newOutboundInteraction = true;
        }
        else {
            setTimeout(() => {
                PartnerVariable.newOutboundInteraction = true;
            }, 100);
        }
        selectInteractionBasedOnSession(interaction.id, "pickup");
    }
}
export function removeAllSessionAndRelatedTabs() {
    removeStorageItem(Constant.ssSessionMapping);
    removeStorageItem(Constant.ssTabMapping);
    removeStorageItem(Constant.ssSessionSwitchedInteractionState);
    Global.interactionIdsArray = [];
    removeStorageItem(Constant.ssSessionTabArrayInfo);
}
function sessionClosed(eventData) {
    log("sessionClosed:" + eventData);
    try {
        if (eventData) {
            eventData = JSON.parse(eventData);
            const defaultSessionId = getStorageItem(Constant.ssDefaultSessionId);
            if (defaultSessionId == eventData.sessionId) {
                createDefaultSessionIfClosed();
            }
            removeClosedSessionIdFromSessionStorage(eventData.sessionId);
        }
    }
    catch (e) {
        log(e.message);
    }
}
function createDefaultSessionIfClosed() {
    let inputSessionData = null;
    inputSessionData = createInputForDefaultSession(inputSessionData);
    createSession(inputSessionData)
        .done(function success(sessionId) {
        if (sessionId && sessionId.toLowerCase().includes("session")) {
            log("createDefaultSession: " + sessionId);
            setStorageItem(Constant.ssDefaultSessionId, sessionId);
        }
        else {
            callSessionCreate(inputSessionData, null);
        }
    })
        .fail(function (error) {
        log(error);
    });
}
export function checkInteractionTypeToHoldActivityTab(interaction) {
    if (interaction) {
        return true;
    }
}
function executeAssociationBasedOnEntityGuid(entityName, guidId, isNewInteraction, interactionId) {
    log("executeAssociationBasedOnEntityGuid: " + entityName + " , " + guidId + " , " + isNewInteraction + " , " + interactionId);
    if (guidId !== null) {
        if (guidId == "00000000-0000-0000-0000-000000000000" || guidId == "")
            return true;
        let qry = "";
        if (entityName == "incident") {
            qry = "?$select=_customerid_value";
        }
        if (entityName == "opportunity") {
            qry = "?$select=_parentaccountid_value,_parentcontactid_value";
        }
        if (isNewInteraction && qry != "") {
            retrieveRecord(entityName, guidId, qry).done(function (result) {
                result = JSON.parse(result);
                let enId = null, enName = null;
                if (entityName == "incident") {
                    enId = result["_customerid_value"];
                    enName = result["_customerid_value@Microsoft.Dynamics.CRM.lookuplogicalname"];
                }
                if (entityName == "opportunity") {
                    if (result["_parentcontactid_value"] != null) {
                        enId = result["_parentcontactid_value"];
                        enName = result["_parentcontactid_value@Microsoft.Dynamics.CRM.lookuplogicalname"];
                    }
                    else {
                        if (result["_parentaccountid_value"] != null) {
                            enId = result["_parentaccountid_value"];
                            enName = result["_parentaccountid_value@Microsoft.Dynamics.CRM.lookuplogicalname"];
                        }
                    }
                }
                if (enId != null && enName != null) {
                    addWhoAssociation(enId, enName, interactionId);
                }
            });
        }
        else {
            addWhoAssociation(guidId, entityName, interactionId);
        }
        addRegardingAssociation(guidId, entityName, interactionId);
    }
}
function diff_seconds(t1, t2) {
    if (t1 != null && t2 != null) {
        const dif = t1.getTime() - t2.getTime();
        const Seconds_from_T1_to_T2 = dif / 1000;
        const Seconds_Between_Dates = Math.abs(Seconds_from_T1_to_T2);
        return Seconds_Between_Dates;
    }
    else {
        return 0;
    }
}
function getUserInfo() {
    const deferred = $.Deferred();
    const apiInstance = new platformClient.UsersApi();
    const opts = {
        expand: ["organization", "email", "username"]
    };
    apiInstance
        .getUsersMe(opts)
        .then((data) => {
        deferred.resolve(data);
    })
        .catch((err) => {
        deferred.reject(err);
    });
    return deferred.promise();
}
function getQueueId(queueName) {
    const queues = JSON.parse(getStorageItem(Constant.ssUserQueueList)) || [];
    const queue = queues.find((q) => {
        return q.name === queueName;
    });
    if (!queue) {
        return null;
    }
    else {
        return queue.id;
    }
}
export function isPopOnPickup(interaction, popOnPickup, isOffered) {
    const trigger = getScreenPopTriggerByQueue(interaction);
    if (trigger === null) {
        return popOnPickup && !isOffered;
    }
    else {
        return trigger === "connect" && !isOffered;
    }
}
export function getScreenPopTriggerByQueue(interaction) {
    const config = getScreenPopConfigurationByQueue(interaction);
    if (config === null) {
        return null;
    }
    return config.popOnEvent;
}
export function getScreenPopConfigurationByQueue(interaction) {
    const queueName = interaction.queueName;
    const queueId = getQueueId(queueName);
    const queueConfig = JSON.parse(getParticipantCustomAttribute("queueConfig")) || [];
    const queue = queueConfig.find((q) => {
        return q.queueIdField === queueId;
    });
    if (!queue || !queue.screenPop) {
        return null;
    }
    if (interaction.isCallback) {
        return queue.screenPop.callback;
    }
    else if (interaction.isChat) {
        return queue.screenPop.chat;
    }
    else if (interaction.isEmail) {
        return queue.screenPop.email;
    }
    else if (interaction.isMessage) {
        return queue.screenPop.message;
    }
    else {
        return queue.screenPop.phonecall;
    }
}
async function storeQueues() {
    const queues = [];
    let pageCount = 1;
    const apiInstance = new platformClient.RoutingApi();
    const opts = {
        pageSize: 25,
        pageNumber: 1,
        sortBy: "name",
        sortOrder: "asc"
    };
    while (opts.pageNumber <= pageCount) {
        const data = await apiInstance.getRoutingQueuesMe(opts);
        pageCount = data.pageCount;
        opts.pageNumber++;
        for (let i = 0; i < data.entities.length; i++) {
            queues.push({
                id: data.entities[i].id,
                name: data.entities[i].name
            });
        }
    }
    setStorageItem(Constant.ssUserQueueList, JSON.stringify(queues));
}
export function initilizePlateformClient() {
    platformClient.ApiClient.setEnvironment(getStorageItem(Constant.lsClientRegion));
    platformClient.ApiClient.instance.setAccessToken(getStorageItem(Constant.ssAuthToken));
    storeQueues();
    getUserInfo()
        .then((data) => {
        storeGenesysOrgNameInWindowObject(data);
    })
        .catch((e) => {
        log("Failed to get user info", e);
    });
}
function trackEventBeforSaveActivity(entityData, entityName) {
    const listOfEntityAttributes = Object.keys(entityData);
    const pattern = "parties";
    const filtered = Object.keys(entityData).filter(function (str) {
        return str.includes(pattern);
    });
    let listOfActivityParties = [];
    const partyProperties = [];
    if (filtered && filtered.length > 0) {
        const filterAttribute = filtered[0];
        listOfActivityParties = entityData[filterAttribute];
        for (let i = 0; i < listOfActivityParties.length; i++) {
            const tempPartyObject = listOfActivityParties[i];
            for (const [key] of Object.entries(tempPartyObject)) {
                partyProperties.push(key);
            }
        }
    }
    const dictionary = {
        interactionId: entityData.isgc_interactionid,
        activityType: entityName,
        listOfEntityAttributes: listOfEntityAttributes,
        listOfActivityParties: partyProperties
    };
    log("preSaveActivity " + JSON.stringify(dictionary));
}
function trackEventAfterSaveActivity(result) {
    const currentInteractionId = getCurrentInteractionId();
    const dictionary = {
        interactionId: currentInteractionId,
        activityType: result.entityType,
        activityId: result.id
    };
    log("postSaveActivity " + JSON.stringify(dictionary));
}
function trackEventBeforUpdateActivity(entityData, entityName, entityId) {
    const listOfEntityAttributes = Object.keys(entityData);
    const pattern = "parties";
    const filtered = Object.keys(entityData).filter(function (str) {
        return str.includes(pattern);
    });
    let listOfActivityParties = [];
    const partyProperties = [];
    if (filtered && filtered.length > 0) {
        const filterAttribute = filtered[0];
        listOfActivityParties = entityData[filterAttribute];
        for (let i = 0; i < listOfActivityParties.length; i++) {
            const tempPartyObject = listOfActivityParties[i];
            for (const [key] of Object.entries(tempPartyObject)) {
                partyProperties.push(key);
            }
        }
    }
    const dictionary = {
        activityId: entityId,
        activityType: entityName,
        listOfEntityAttributes: listOfEntityAttributes,
        listOfActivityParties: partyProperties
    };
    log("preUpdateActivity " + JSON.stringify(dictionary));
}
function trackEventAfterUpdateActivity(result) {
    const currentInteractionId = getCurrentInteractionId();
    const dictionary = {
        interactionId: currentInteractionId,
        activityType: result.entityType,
        activityId: result.id
    };
    log("postUpdateActivity " + JSON.stringify(dictionary));
}
export function checkInteractionTypeAndPreventToCreateActivity(interaction) {
    if (isInternal(interaction) && !shouldScreenPopInternalInteractions()) {
        return true;
    }
    return false;
}
export function createSessionForInteractionHaveNoPop(interaction) {
    let inputSessionData = null;
    inputSessionData = createInputForDefaultSession(inputSessionData);
    createSessionForOmniChannel(inputSessionData, interaction.id);
}
function createInputForDefaultSession(inputSessionData) {
    inputSessionData = {
        templateName: Constant.defaultSessionTemplate,
        templateParameters: {
            entityName: "contact"
        }
    };
    return inputSessionData;
}
function removeInteractionIdsFromGlobalArray(interactionId) {
    Global.interactionIdsArray = Global.interactionIdsArray.filter(function (ele) {
        return ele.interactionId != interactionId;
    });
}
function checkInteractionHaveInternalValue(formParameters, interaction) {
    const screenPopConfig = getScreenPopConfiguration(interaction);
    const fieldName = screenPopConfig.noRecord.newEntity.field;
    if (Object.hasOwn(formParameters, fieldName) && formParameters[fieldName].toLowerCase() == "internal") {
        formParameters[fieldName] = null;
    }
}
function showMessageBasedOnCreateAndUpdateActivityFailed(error, errorType, entityType, recordid) {
    let message = "";
    if (errorType && errorType.toLowerCase() == "createrecord") {
        message = "createRecord failed: " + entityType + " error:" + returnErrorMessageForApiFailed(error);
    }
    else {
        message = "updateRecord failed: " + entityType + " id:" + recordid + " error:" + returnErrorMessageForApiFailed(error);
        log(message);
        if (message && !message.toLowerCase().includes("timeout occurred")) {
            errorType = "error";
            sendNotification(message, errorType);
        }
    }
}
function checkLsNewBlankRecordPop() {
    const isPresent = getStorageItem(Constant.lsnewBlankRecordPop);
    if (isPresent) {
        if (isPresent == "getNewInteractionId") {
            FunctionsVariable.recordNewInteraction = {
                scriptId: FunctionsVariable.scriptId,
                interactionId: getNewInteractionId()
            };
        }
        else {
            FunctionsVariable.recordNewInteraction = {
                scriptId: FunctionsVariable.scriptId,
                interactionId: getCurrentInteractionId()
            };
        }
        FunctionsVariable.flagNewRecordPop = true;
        removeStorageItem(Constant.lsnewBlankRecordPop);
    }
}
function checkAndRemoveRecordNewInteractionForOutboundOnNotDirty() {
    isDirtyCurrentPage()
        .done(function success(isDirty) {
        if (!isDirty) {
            FunctionsVariable.recordNewInteraction = null;
            FunctionsVariable.flagNewRecordPop = false;
        }
    })
        .fail(function (error) {
        error = returnErrorMessageForApiFailed(error);
        log(error);
    });
}
function checkForInteractionIdFromLsCollectionInteractionIdsForSoftPhToAllTabFalse(data, interactionId) {
    if (isJSON(data)) {
        const isPresentData = JSON.parse(data);
        if (isPresentData.scriptId == undefined) {
            isPresentData.scriptId = FunctionsVariable.scriptId;
        }
        let isPresentLsCollectionInteractionIdsForSoftPhToAllTabFalse = getStorageItem(Constant.lsCollectionInteractionIdsForSoftPhToAllTabFalse);
        if (isPresentData.scriptId && isPresentLsCollectionInteractionIdsForSoftPhToAllTabFalse && isJSON(isPresentLsCollectionInteractionIdsForSoftPhToAllTabFalse) && isPresentLsCollectionInteractionIdsForSoftPhToAllTabFalse !== "[]") {
            isPresentLsCollectionInteractionIdsForSoftPhToAllTabFalse = JSON.parse(isPresentLsCollectionInteractionIdsForSoftPhToAllTabFalse);
            const isPresent = searchInJSON(isPresentLsCollectionInteractionIdsForSoftPhToAllTabFalse, "scriptId", isPresentData.scriptId);
            if (isPresent && isPresent.length > 0) {
                removeItemDataOnInteractionId(isPresent[0].interactionId, Constant.lsCollectionInteractionIdsForSoftPhToAllTabFalse);
                return isPresent[0].interactionId;
            }
            else {
                return interactionId;
            }
        }
        else {
            return interactionId;
        }
    }
    else {
        return interactionId;
    }
}
export function checklsNewBlankRecordPopForSoftPhoneToAllTabFalse() {
    const isPresent = getStorageItem(Constant.lsNewBlankRecordPopForSoftPhoneToAllTabFalse);
    if (isPresent) {
        if (isPresent == "true" || isPresent == true) {
            setStorageItem(Constant.lsNewBlankRecordPopForSoftPhoneToAllTabFalse, false);
            const scriptInteractionId = {
                scriptId: FunctionsVariable.scriptId,
                interactionId: getNewInteractionId()
            };
            const isPresentScriptInteractionCollection = getStorageItem(Constant.lsCollectionInteractionIdsForSoftPhToAllTabFalse);
            if (isPresentScriptInteractionCollection == null || isPresentScriptInteractionCollection == "[]") {
                const collectionScriptInteraction = [];
                collectionScriptInteraction.push(scriptInteractionId);
                setStorageItem(Constant.lsCollectionInteractionIdsForSoftPhToAllTabFalse, JSON.stringify(collectionScriptInteraction));
            }
            else if (isJSON(isPresentScriptInteractionCollection)) {
                const collectionScriptInteraction = JSON.parse(isPresentScriptInteractionCollection);
                collectionScriptInteraction.push(scriptInteractionId);
                setStorageItem(Constant.lsCollectionInteractionIdsForSoftPhToAllTabFalse, JSON.stringify(collectionScriptInteraction));
            }
        }
    }
}
function updateAttributeIsScreenpop(interactionId) {
    if (interactionId != getCurrentInteractionId()) {
        interactionId = getCurrentInteractionId();
    }
    const attributes = { is_searchpop: "I" };
    setInteractionAttributes(interactionId, attributes);
}
function addProcessedNewInteractionIdForAssociation(setInteractionId) {
    const isPresentInteractionCollection = getStorageItem(Constant.lsProcessedNewInteractionIds);
    const interactionId = {
        interactionId: setInteractionId
    };
    if (isPresentInteractionCollection == null || isPresentInteractionCollection == "[]") {
        const collectionInteraction = [];
        collectionInteraction.push(interactionId);
        setStorageItem(Constant.lsProcessedNewInteractionIds, JSON.stringify(collectionInteraction));
    }
    else if (isJSON(isPresentInteractionCollection)) {
        const collectionInteraction = JSON.parse(isPresentInteractionCollection);
        collectionInteraction.push(interactionId);
        setStorageItem(Constant.lsProcessedNewInteractionIds, JSON.stringify(collectionInteraction));
    }
}
function removeProcessedNewInteractionIdForAssociation(interactionId) {
    let dataArray = getStorageItem(Constant.lsProcessedNewInteractionIds);
    if (dataArray != null && dataArray != "[]") {
        dataArray = JSON.parse(dataArray);
        if (dataArray.length > 0) {
            dataArray = dataArray.filter(function (ele) {
                return ele.interactionId != interactionId;
            });
            setStorageItem(Constant.lsProcessedNewInteractionIds, JSON.stringify(dataArray));
        }
    }
}
function getFocusedTab() {
    const deferred = $.Deferred();
    window.Microsoft.CIFramework.getFocusedTab().then(function success(result) {
        deferred.resolve(result);
    }, function (error) {
        error = returnErrorMessageForApiFailed(error);
        log(error);
        return deferred.reject(error);
    });
    return deferred.promise();
}
function checkActivityEntityType(entityName) {
    if (entityName) {
        switch (entityName) {
            case Constant.phonecallName:
                return true;
            case Constant.callbackName:
                return true;
            case Constant.chatName:
                return true;
            case Constant.emailName:
                return true;
            case Constant.messageName:
                return true;
            case Constant.webMessageName:
                return true;
            default:
                return false;
        }
    }
}
function selectAndRefreshActivityTab(activityTabId, activityType, e, bits) {
    getSelectedTabAndRefresh(activityTabId, activityType)
        .done(function () {
        // nop
    })
        .fail(function () {
        createOrUpdateActivityTab(e, bits);
    });
}
function checkInteractionAttributesBasedOnType(e, entityName) {
    if (e) {
        if (e.data.type.toLowerCase() == "opencalllog") {
            const attributeData = {};
            attributeData.attributes = e.data.callLog.attributes;
            if (isInteractionForCustomActivity(attributeData)) {
                if (attributeData.attributes.is_activitytype == entityName) {
                    Global.isCustomActivity = true;
                }
                return true;
            }
        }
        else if (e.data.type.toLowerCase() == "processcalllog") {
            if (isInteractionForCustomActivity(e.data.interaction)) {
                if (e.data.interaction.attributes.is_activitytype == entityName) {
                    Global.isCustomActivity = true;
                }
                return true;
            }
        }
        return false;
    }
}
function closeActivityBasedOnConfigSettingValue(interactionId, requestedCloseOnEvent) {
    const setting = getSettingFromConfig("closeactivitiesonevent");
    if (!setting)
        return;
    if (interactionId && setting.toLowerCase() == requestedCloseOnEvent) {
        setTimeout(() => closeActivity(interactionId), 500);
    }
}
function addlsNewBlankRecordPopForSoftPhoneToAllTabFalse(scriptInteractionIdObj) {
    const scriptInteractionId = scriptInteractionIdObj;
    const isPresentScriptInteractionCollection = getStorageItem(Constant.lsCollectionInteractionIdsForSoftPhToAllTabFalse);
    if (isPresentScriptInteractionCollection == null || isPresentScriptInteractionCollection == "[]") {
        const collectionScriptInteraction = [];
        collectionScriptInteraction.push(scriptInteractionId);
        setStorageItem(Constant.lsCollectionInteractionIdsForSoftPhToAllTabFalse, JSON.stringify(collectionScriptInteraction));
    }
    else if (isJSON(isPresentScriptInteractionCollection)) {
        const collectionScriptInteraction = JSON.parse(isPresentScriptInteractionCollection);
        collectionScriptInteraction.push(scriptInteractionId);
        setStorageItem(Constant.lsCollectionInteractionIdsForSoftPhToAllTabFalse, JSON.stringify(collectionScriptInteraction));
    }
}
function checklsNewBlankRecordPopForSoftPhoneToAllTabFalseHasObject() {
    let isPresentLsCollectionInteractionIdsForSoftPhToAllTabFalse = getStorageItem(Constant.lsCollectionInteractionIdsForSoftPhToAllTabFalse);
    if (isPresentLsCollectionInteractionIdsForSoftPhToAllTabFalse && isJSON(isPresentLsCollectionInteractionIdsForSoftPhToAllTabFalse) && isPresentLsCollectionInteractionIdsForSoftPhToAllTabFalse !== "[]") {
        isPresentLsCollectionInteractionIdsForSoftPhToAllTabFalse = JSON.parse(isPresentLsCollectionInteractionIdsForSoftPhToAllTabFalse);
        for (let i = 0; i < isPresentLsCollectionInteractionIdsForSoftPhToAllTabFalse.length; i++) {
            if (isPresentLsCollectionInteractionIdsForSoftPhToAllTabFalse[i].scriptId == FunctionsVariable.scriptId) {
                isPresentLsCollectionInteractionIdsForSoftPhToAllTabFalse[i].interactionId = getCurrentInteractionId();
            }
        }
        setStorageItem(Constant.lsCollectionInteractionIdsForSoftPhToAllTabFalse, JSON.stringify(isPresentLsCollectionInteractionIdsForSoftPhToAllTabFalse));
    }
}
function removelsNewBlankRecordPopForSoftPhoneToAllTabFalseHasObject() {
    const lsItemData = getStorageItem(Constant.lsCollectionInteractionIdsForSoftPhToAllTabFalse);
    if (lsItemData == null || lsItemData == "[]")
        return;
    const itemDataList = JSON.parse(lsItemData);
    const result = itemDataList.filter((obj) => {
        return obj.scriptId === FunctionsVariable.scriptId;
    });
    if (result.length >= 1) {
        itemDataList.splice(itemDataList.indexOf(result[0]), 1);
        setStorageItem(Constant.lsCollectionInteractionIdsForSoftPhToAllTabFalse, JSON.stringify(itemDataList));
    }
}
function forceScreenpopForOutbound(interaction) {
    PartnerVariable.newOutboundInteraction = false;
    const changeDataFormate = {
        interaction: interaction,
        searchString: interaction.calledNumber
    };
    log("forceScreenpopForOutbound: " + JSON.stringify(changeDataFormate));
    const interactionType = checkInteractionTypeIsMessage(interaction);
    if (!changeDataFormate.searchString && interactionType && interactionType.toLowerCase() == "sms") {
        changeDataFormate.searchString = interaction.remoteName;
        FunctionsVariable.processingInteractionId = null;
    }
    if (!FunctionsVariable.flagCallForScreenpop && FunctionsVariable.processingInteractionId == null && !getSettingFromConfig("activityscreenpopforoutboundinteraction")) {
        FunctionsVariable.flagCallForScreenpop = true;
        screenpop(changeDataFormate, false, true);
    }
}
function getSearchAttributesAndReturnString(attrArray) {
    return attrArray.map((item) => item.logicalName).join(",");
}
function createEntityAttributesLabelArray(entityAttr) {
    if (!entityAttr) {
        return [];
    }
    return entityAttr.map((attr) => ({ [attr.logicalName]: attr.displayName }));
}
async function searchInCRMDefault(searchString) {
    log("searchInCRM. search: " + searchString);
    FunctionsVariable.searchInProgress = searchString;
    try {
        const [foundContacts, foundSystemUsers, foundLeads, foundAccounts] = await Promise.all([searchInContact(searchString), searchInSystemUser(searchString), searchInLead(searchString), searchInAccount(searchString)]);
        log("searchInCRM.  when completed for search: " + searchString);
        if (isSearchInProgressCancelled("searchInCRM", searchString)) {
            return;
        }
        const results = [foundContacts, foundSystemUsers, foundLeads, foundAccounts].filter((v) => Array.isArray(v)).flat();
        showResultToSidebar(searchString, results);
        log("searchInCRM.  contacts:" + Object.keys(foundContacts).length + " systemUsers: " + Object.keys(foundSystemUsers).length + " Leads: " + Object.keys(foundLeads).length + " Accounts: " + Object.keys(foundAccounts).length);
    }
    catch (error) {
        log(returnErrorMessageForApiFailed(error));
    }
}
function entitySearch(searchString, entityAttrArray, entityName, entityLabel, query) {
    log(`Searching for: ${searchString}, entity: ${entityName}`);
    const deferred = $.Deferred();
    const searchOnly = true;
    searchAndOpenRecords(entityName, query, searchOnly)
        .done(function (results) {
        if (results != "{}") {
            results = JSON.parse(results);
            const resultArr = [];
            for (let i = 0; i < Object.keys(results).length; i++) {
                const searchResultObj = { type: "external", name: null, phone: [], attributes: { IS_EntityType: null, IS_EntityId: null, IS_SearchPop: "Y" } };
                for (let j = 0; j < Object.keys(results[i]).length; j++) {
                    if (!Object.keys(results[i])[j].includes("@odata")) {
                        if (results[i][Object.keys(results[i])[j]] != null && isGuid(results[i][Object.keys(results[i])[j]])) {
                            if (Object.keys(results[i])[j].includes(entityName)) {
                                searchResultObj.attributes.IS_EntityType = entityName;
                                searchResultObj.attributes.IS_EntityId = results[i][Object.keys(results[i])[j]];
                            }
                        }
                        else if (results[i][Object.keys(results[i])[j]] != null && Object.keys(results[i])[j] == entityLabel) {
                            searchResultObj.name = results[i][Object.keys(results[i])[j]];
                        }
                        else if (results[i][Object.keys(results[i])[j]] != null) {
                            let attrLabel = null;
                            entityAttrArray.forEach(function (value) {
                                if (Object.hasOwn(value, Object.keys(results[i])[j])) {
                                    attrLabel = value[Object.keys(results[i])[j]];
                                }
                            });
                            searchResultObj.phone.push({ number: results[i][Object.keys(results[i])[j]], label: attrLabel });
                        }
                    }
                }
                resultArr.push(searchResultObj);
            }
            deferred.resolve(resultArr);
        }
        else {
            deferred.resolve([]);
        }
    })
        .fail(function (error) {
        error = returnErrorMessageForApiFailed(error);
        log(error);
        deferred.resolve(0);
    });
    return deferred.promise();
}
export const debounceCallFunctionForsearchInCRM = debounce(function (searchString) {
    searchInCRM(searchString);
}, 1500);
export async function searchInCRM(searchString) {
    const searchFunctions = [];
    const newInteractionJson = getParticipantCustomAttribute("newInteractionSettingJson");
    let useDefaultSearch = true;
    let qry = "";
    if (newInteractionJson) {
        const newInteraction = JSON.parse(newInteractionJson);
        if (newInteraction.length > 0) {
            useDefaultSearch = false;
            for (let i = 0; i < newInteraction.length; i++) {
                const entityName = newInteraction[i].entityName;
                const attributes = getSearchAttributesAndReturnString(newInteraction[i].attributes);
                const entityAttrArray = createEntityAttributesLabelArray(newInteraction[i].attributes);
                const additionalParameter = newInteraction[i].additionalParameter;
                const entityLabel = newInteraction[i].entityLabel;
                if (searchString.includes("+")) {
                    const searchValue = searchString.replace(/\+/g, "");
                    qry = (newInteraction[i].attributes ?? []).map((v) => `contains(${v.logicalName},%20%27%2B${searchValue}%27)`).join(" or ");
                }
                else {
                    qry = (newInteraction[i].attributes ?? []).map((v) => `contains(${v.logicalName}, %20%27${searchString}%27)`).join(" or ");
                }
                let query = "?$select=" + attributes + "&$filter=";
                if (additionalParameter != "none" && additionalParameter != "") {
                    query += "(" + qry + ") and " + additionalParameter;
                }
                else {
                    query += qry;
                }
                query += "&$top=5";
                searchFunctions.push(entitySearch(searchString, entityAttrArray, entityName, entityLabel, query));
            }
        }
    }
    FunctionsVariable.makeCallFromScriptId = FunctionsVariable.scriptId;
    if (useDefaultSearch) {
        return searchInCRMDefault(searchString);
    }
    log(`searchInCRM. search: ${searchString}`);
    FunctionsVariable.searchInProgress = searchString;
    Global.searchResultArry = [];
    try {
        const results = (await Promise.all(searchFunctions)).flat();
        log(`Completed for search: ${searchString} (searchInCRM)`);
        if (isSearchInProgressCancelled("searchInCRM", searchString)) {
            return;
        }
        const shouldOverrideNewInteraction = getParticipantCustomAttribute("overrideNewinteraction");
        if (shouldOverrideNewInteraction) {
            Global.searchResultArry = results;
            return Global.searchResultArry.length;
        }
        showResultToSidebar(searchString, results);
    }
    catch (e) {
        const error = returnErrorMessageForApiFailed(e);
        log(error);
        throw error;
    }
}
export function createDynamicSceenpopSearchFunctionArray() {
    FunctionsVariable.screenpopForPhonecall = [];
    FunctionsVariable.screenpopForChat = [];
    FunctionsVariable.screenpopForEmail = [];
    FunctionsVariable.screenpopForCallback = [];
    FunctionsVariable.screenpopForMessage = [];
    const searchData = JSON.stringify(defaultScreenPopSearchConfiguration);
    let screenpopSearchData = getParticipantCustomAttribute("screenpopsettingjson");
    if (screenpopSearchData == null || screenpopSearchData == undefined) {
        screenpopSearchData = searchData;
    }
    if (isJSON(screenpopSearchData)) {
        screenpopSearchData = JSON.parse(screenpopSearchData);
        const mediaTypes = ["Phonecall", "Chat", "Email", "Callback", "Message"];
        for (const mediaType of mediaTypes) {
            const createScreenPopArrayBasedOnValue = checkAllowScreenpop(mediaType, screenpopSearchData);
            if (createScreenPopArrayBasedOnValue.length > 0) {
                for (let i = 0; i < createScreenPopArrayBasedOnValue.length; i++) {
                    const entityName = createScreenPopArrayBasedOnValue[i].entityName;
                    const qry = createDynamicQueryFilterForScreenpop(createScreenPopArrayBasedOnValue[i].attributes);
                    const attribute = createScreenPopArrayBasedOnValue[i].entityName + "id";
                    const additionalParameter = createScreenPopArrayBasedOnValue[i].additionalParameter;
                    let query = "'?$select=" + attribute + "&$filter=(" + qry + ")";
                    if (additionalParameter != "none" && additionalParameter != "") {
                        query += " and " + additionalParameter + " '";
                    }
                    else {
                        query += " '";
                    }
                    // Careful!
                    // "searchType" and "data" are used in eval below
                    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
                    FunctionsVariable[`screenpopFor${mediaType}`].push((data, searchType) => {
                        return new Promise((res, rej) => {
                            data = encodeURIComponent(data);
                            // eslint-disable-next-line no-unused-vars
                            data = addSingleQuoteToString(data);
                            searchEntity(entityName, attribute, eval(query)).then(res, rej);
                        });
                    });
                }
            }
        }
    }
}
function getQueueScreenPopFunctionArray(interaction) {
    const screenPopForInteraction = [];
    const queueScreenPopConfig = getScreenPopConfigurationByQueue(interaction);
    if (queueScreenPopConfig !== null) {
        const createScreenPopArrayBasedOnValue = checkAllowScreenpopQueue(queueScreenPopConfig);
        if (createScreenPopArrayBasedOnValue.length > 0) {
            for (let i = 0; i < createScreenPopArrayBasedOnValue.length; i++) {
                const entityName = createScreenPopArrayBasedOnValue[i].entityName;
                const qry = createDynamicQueryFilterForScreenpop(createScreenPopArrayBasedOnValue[i].attributes);
                const attribute = createScreenPopArrayBasedOnValue[i].entityName + "id";
                const additionalParameter = createScreenPopArrayBasedOnValue[i].additionalParameter;
                let query = "'?$select=" + attribute + "&$filter=(" + qry + ")";
                if (additionalParameter != "none" && additionalParameter != "") {
                    query += " and " + additionalParameter + " '";
                }
                else {
                    query += " '";
                }
                // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
                screenPopForInteraction.push((data, searchType) => {
                    return new Promise((res, rej) => {
                        data = encodeURIComponent(data);
                        // eslint-disable-next-line no-unused-vars
                        data = addSingleQuoteToString(data);
                        searchEntity(entityName, attribute, eval(query)).then(res, rej);
                    });
                });
            }
        }
        return screenPopForInteraction;
    }
    return null;
}
function createDynamicQueryFilterForScreenpop(attrArray) {
    if (attrArray.length > 0) {
        let qry = "";
        for (let i = 0; i < attrArray.length; i++) {
            if (i != attrArray.length - 1) {
                qry = qry + attrArray[i] + " eq '+ data +' or ";
            }
            else {
                qry = qry + attrArray[i] + " eq '+ data +' ";
            }
        }
        return qry;
    }
}
function addSingleQuoteToString(data) {
    data = "'" + data + "'";
    return data;
}
export function getScreenpopFunctionArrayinteraction(interaction) {
    const queueScreenPopFunctionArray = getQueueScreenPopFunctionArray(interaction);
    if (queueScreenPopFunctionArray !== null) {
        return queueScreenPopFunctionArray;
    }
    if (interaction.isCallback && !interaction.isDialerPreview) {
        return FunctionsVariable.screenpopForCallback;
    }
    else if (interaction.isChat) {
        return FunctionsVariable.screenpopForChat;
    }
    else if (interaction.isEmail) {
        return FunctionsVariable.screenpopForEmail;
    }
    else if (interaction.isMessage) {
        return FunctionsVariable.screenpopForMessage;
    }
    else {
        return FunctionsVariable.screenpopForPhonecall;
    }
}
function showHideCallButton(show) {
    $("#callButton").prop("disabled", !show);
}
export function showNewCustomDialpad() {
    const shouldOverrideNewInteraction = getParticipantCustomAttribute("overrideNewinteraction");
    if (shouldOverrideNewInteraction) {
        const isPromptForQueueValue = getStorageItem(Constant.isPromptForQueue);
        if (JSON.parse(isPromptForQueueValue)) {
            $("#queue").show();
            if ($("#nameOrNumber").val()) {
                showHideCallButton(true);
            }
        }
        else {
            $("#queue").hide();
        }
        hideTheNumberForm();
        document.body.style.overflowX = "hidden";
        $("#closeButton").css("visibility", "visible");
        $("#customDialpad").children().first().css("margin-top", "90px");
        $("#customDialpad").children().first().css("height", "50%");
        $("#customDialpad").show();
    }
}
export function closeDialpad(delayTime) {
    const isVisible = $("#customDialpad").is(":visible");
    if (isVisible) {
        setViewToInteractionList();
        FunctionsVariable.delayTimeForHideCustomDial = delayTime;
        Global.searchResultArry = [];
        $("#closeButton").css("visibility", "hidden");
        $("#customDialpad").children().first().css("margin-top", "75px");
        $("#customDialpad").children().first().css("height", "65%");
        const queueInputData = document.getElementById("queue");
        if (queueInputData) {
            queueInputData.value = "";
        }
        removeSearchedRecordForQueue();
        const nameOrNumberInputData = document.getElementById("nameOrNumber");
        if (nameOrNumberInputData) {
            nameOrNumberInputData.value = "";
        }
        removeSearchedRecordForNameOrNumber();
        setTimeout(function () {
            document.body.style.overflowX = "";
        }, delayTime + 5);
        hideLoader();
        showHideCallButton(false);
    }
}
function getRoutingQueuesValue() {
    let searchedValue = $("#queue").val();
    if (Global.routingQueuesArray.length > 0) {
        searchedValue = searchedValue.substring(searchedValue.length, 3);
        Global.routingQueuesArray.forEach(function (routingQueue) {
            if (routingQueue == searchedValue) {
                $("#queue").val(searchedValue);
                searchedValue = null;
                Global.routingQueuesArray = [];
            }
        });
    }
    FunctionsVariable.doneTypingIntervalForQueue = 1000;
    if (searchedValue) {
        const apiInstance = new platformClient.RoutingApi();
        const opts = {
            pageSize: 25,
            pageNumber: 1,
            sortBy: "name",
            sortOrder: "asc"
        };
        const queueDataList = $("#queueListRecord");
        if (queueDataList && queueDataList[0]) {
            queueDataList[0].innerHTML = "";
        }
        if (queueDataList && queueDataList.children().length == 0) {
            apiInstance
                .getRoutingQueuesMe(opts)
                .then((data) => {
                let option = "";
                queueDataList[0].innerHTML = "";
                Global.routingQueuesArray = [];
                FunctionsVariable.doneTypingIntervalForQueue = 0;
                for (let i = 0; i < data.entities.length; i++) {
                    option = "";
                    option = "<option value='&#127987 " + data.entities[i].name + "'>";
                    queueDataList.append($(option));
                    Global.routingQueuesArray.push(data.entities[i].name);
                }
            })
                .catch(() => {
                log("Failed to getRoutingQueuesDivisionviews");
            });
        }
    }
    else {
        Global.routingQueuesArray = [];
        debounceCallFunctionForRemoveSearchedRecordForQueue();
    }
}
function checkEnteredNameOrNumberValue() {
    setSearchTargetsValues();
    hideTheNumberForm();
    const searchString = $("#nameOrNumber").val();
    if (!searchString || searchString == "")
        return;
    if (searchString) {
        const searchTargets = getSettingFromConfig("searchTargets");
        if (searchTargets) {
            if (searchTargets.indexOf("people") != -1) {
                Global.people = true;
            }
            if (searchTargets.indexOf("queues") != -1) {
                Global.queues = true;
            }
            if (searchTargets.indexOf("frameworkContacts") != -1) {
                Global.frameworkContacts = true;
            }
            if (searchTargets.indexOf("externalContacts") != -1) {
                Global.externalContacts = true;
            }
        }
        $.when(checkForExternalContacts(searchString, Global.externalContacts), checkForQueues(searchString, Global.queues), checkForPeople(searchString, Global.people))
            .done(function () {
            if (Global.frameworkContacts) {
                searchInCRM(searchString)
                    .then(function () {
                    showSearchedResultToCustomDropDown();
                })
                    .catch(function (error) {
                    error = returnErrorMessageForApiFailed(error);
                    log(error);
                });
            }
            else {
                FunctionsVariable.searchInProgress = searchString;
                showSearchedResultToCustomDropDown();
            }
        })
            .fail(function (error) {
            error = returnErrorMessageForApiFailed(error);
            log(error);
            hideLoader();
        });
    }
}
function setSearchTargetsValues() {
    Global.people = false;
    Global.queues = false;
    Global.frameworkContacts = false;
    Global.externalContacts = false;
}
function showSearchedResultToCustomDropDown() {
    closeListRow();
    const dynamicList = document.createElement("DIV");
    dynamicList.setAttribute("class", "autocomplete-items");
    $(dynamicList).css("max-height", window.innerHeight - 232 + "px");
    const searchInput = document.getElementById("nameOrNumber");
    searchInput.parentNode.appendChild(dynamicList);
    const searchString = searchInput.value;
    let resultRows = "";
    if (searchString) {
        if (Global.searchResultArry && Global.searchResultArry.length > 0) {
            for (let i = 0; i < Global.searchResultArry.length; i++) {
                if (!Global.searchResultArry[i].name)
                    Global.searchResultArry[i].name = "----------";
                resultRows += "<div class='autocompleteitemsdiv'>";
                resultRows += "<textarea style='display:none' data_recordtype='frameworkContacts'>" + JSON.stringify(Global.searchResultArry[i]) + "</textarea>";
                resultRows += "<div id='lblname'>" + getHighlightedTextForCustomDialSearch(Global.searchResultArry[i].name, searchString) + "</div>";
                for (let j = 0; j < Global.searchResultArry[i].phone.length; j++) {
                    if (Global.searchResultArry[i].phone[j].label && !isEmailAddress(Global.searchResultArry[i].phone[j].number) && !isNaN(replaceSpecialCharacterFromString(Global.searchResultArry[i].phone[j].number))) {
                        resultRows += "<div style='font-size:13px'>" + getHighlightedTextForCustomDialSearch(Global.searchResultArry[i].phone[j].number, searchString) + " (" + Global.searchResultArry[i].phone[j].label + ")" + "</div>";
                    }
                    else if (Global.searchResultArry[i].phone[j].label && Global.searchResultArry[i].phone[j].number.toLowerCase().includes(searchString.toLowerCase())) {
                        resultRows += "<div style='font-size:13px'>" + getHighlightedTextForCustomDialSearch(Global.searchResultArry[i].phone[j].number, searchString) + " (" + Global.searchResultArry[i].phone[j].label + ")" + "</div>";
                    }
                }
                resultRows += "</div>";
            }
        }
        if (Global.peopleArray && Global.peopleArray.length > 0) {
            for (let i = 0; i < Global.peopleArray.length; i++) {
                if (!Global.peopleArray[i].name)
                    Global.peopleArray[i].name = "----------";
                resultRows += "<div class='autocompleteitemsdiv'>";
                resultRows += "<textarea style='display:none' data_recordtype='people'>" + JSON.stringify(Global.peopleArray[i]) + "</textarea>";
                resultRows += "<div id='lblname'>" + getHighlightedTextForCustomDialSearch(Global.peopleArray[i].name, searchString) + "</div>";
                resultRows += "<div style='font-size:13px'>" + Global.peopleArray[i].status + "</div>";
                resultRows += "</div>";
            }
        }
        if (Global.queuesArray && Global.queuesArray.length > 0) {
            for (let i = 0; i < Global.queuesArray.length; i++) {
                resultRows += "<div class='autocompleteitemsdiv'><textarea style='display:none' data_recordtype='queues'>" + JSON.stringify(Global.queuesArray[i]) + "</textarea><div id='lblname'>" + getHighlightedTextForCustomDialSearch(Global.queuesArray[i], searchString) + "</div></div>";
            }
        }
        if (Global.externalContactsArray && Global.externalContactsArray.length > 0) {
            for (let i = 0; i < Global.externalContactsArray.length; i++) {
                let number = "";
                if (Global.externalContactsArray[i].number.length > 0) {
                    for (let j = 0; j < Global.externalContactsArray[i].number.length; j++) {
                        if (Global.externalContactsArray[i].number[j].workPhone) {
                            number = Global.externalContactsArray[i].number[j].workPhone;
                        }
                        if (number.includes(searchString)) {
                            break;
                        }
                        else if (Global.externalContactsArray[i].number[j].cellPhone && Global.externalContactsArray[i].number[j].cellPhone.includes(searchString)) {
                            number = Global.externalContactsArray[i].number[j].cellPhone;
                            break;
                        }
                        else if (Global.externalContactsArray[i].number[j].homePhone && Global.externalContactsArray[i].number[j].homePhone.includes(searchString)) {
                            number = Global.externalContactsArray[i].number[j].homePhone;
                            break;
                        }
                    }
                }
                if (!Global.externalContactsArray[i].name)
                    Global.externalContactsArray[i].name = "----------";
                resultRows += "<div class='autocompleteitemsdiv' onclick='onSelectResult(this)'>";
                resultRows += "<textarea style='display:none' data_recordtype='externalContacts'>" + JSON.stringify(Global.externalContactsArray[i]) + "</textarea>";
                resultRows += "<div id='lblname'>" + getHighlightedTextForCustomDialSearch(Global.externalContactsArray[i].name, searchString) + "</div>";
                if (number) {
                    resultRows += "<div style='font-size:13px'>" + getHighlightedTextForCustomDialSearch(number, searchString) + "</div>";
                }
                resultRows += "</div>";
            }
        }
        if (isSearchInProgressCancelled("showSearchedResultToCustomDropDown", searchString)) {
            return;
        }
        if (resultRows != "") {
            dynamicList.innerHTML = resultRows;
        }
        else {
            dynamicList.innerHTML = "<label>No Record Found</label>";
            setTimeout(closeListRow, 2000);
        }
    }
    hideLoader();
}
export function initiateCall() {
    const data = $("#recordNumbers > input");
    let number = null;
    let selectedRecord = null;
    if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
            const e = $("#recordNumbers > input")[i];
            if (e.checked) {
                number = e.value;
                break;
            }
        }
    }
    else if (Global.queuesArray && Global.queuesArray.length > 0) {
        for (let j = 0; j < Global.queuesArray.length; j++) {
            if (Global.queuesArray[j] == $("#nameOrNumber").val()) {
                number = Global.queuesArray[j];
                break;
            }
        }
    }
    else if (Global.peopleArray && Global.peopleArray.length > 0) {
        for (let k = 0; k < Global.peopleArray.length; k++) {
            if (Global.peopleArray[k].name == $("#nameOrNumber").val()) {
                number = Global.peopleArray[k].name;
                break;
            }
        }
    }
    else {
        number = $("#nameOrNumber").val();
    }
    if (Global.searchResultArry && Object.hasOwn(Global.searchResultArry, "attributes") && data.length > 0) {
        selectedRecord = Global.searchResultArry;
    }
    removeSearchedRecordForNameOrNumber();
    removeSearchedRecordForQueue();
    closeDialpad(500);
    //makeCall(number);
    log("selected number from the record is : " + number);
    const callObject = {
        number: number,
        type: "makecall"
    };
    if (selectedRecord && Object.hasOwn(selectedRecord, "attributes")) {
        callObject.attributes = selectedRecord.attributes;
    }
    log("callObject information to send postmessage to webresource to perform makecall: " + JSON.stringify(callObject));
    window.top.postMessage(callObject, window.location.ancestorOrigins[0]);
}
function setViewToInteractionList() {
    const setviewData = {
        messageType: "setView",
        type: "main",
        view: { name: "interactionList" }
    };
    queuePostMessage(setviewData);
}
function checkForExternalContacts(value, externalContacts) {
    const deferred = $.Deferred();
    if (externalContacts) {
        const apiInstance = new platformClient.ExternalContactsApi();
        const opts = {
            pageSize: 20,
            pageNumber: 1,
            q: value
        };
        apiInstance
            .getExternalcontactsContacts(opts)
            .then((data) => {
            Global.externalContactsArray = [];
            if (data.entities.length > 0) {
                for (let i = 0; i < data.entities.length; i++) {
                    const extObj = {};
                    extObj.name = data.entities[i].firstName + " " + data.entities[i].middleName;
                    extObj.number = [];
                    if (Object.hasOwn(data.entities[i], "workPhone") && data.entities[i].workPhone.userInput) {
                        extObj.number.push({ workPhone: data.entities[i].workPhone.userInput });
                    }
                    if (Object.hasOwn(data.entities[i], "cellPhone") && data.entities[i].cellPhone.userInput) {
                        extObj.number.push({ cellPhone: data.entities[i].cellPhone.userInput });
                    }
                    if (Object.hasOwn(data.entities[i], "homePhone") && data.entities[i].homePhone.userInput) {
                        extObj.number.push({ homePhone: data.entities[i].homePhone.userInput });
                    }
                    Global.externalContactsArray.push(extObj);
                }
                log("foundInExternalContacts: " + Global.externalContactsArray.length);
            }
            else {
                log("No external contacts found. Search:" + value);
            }
            deferred.resolve(Global.externalContactsArray.length);
        })
            .catch((err) => {
            log("There was a failure calling getExternalcontactsContacts");
            deferred.reject(err);
        });
    }
    else {
        deferred.resolve(0);
    }
    return deferred.promise();
}
function checkForQueues(value, queues) {
    const deferred = $.Deferred();
    if (queues) {
        const apiInstance = new platformClient.RoutingApi();
        const opts = {
            pageSize: 25,
            pageNumber: 1,
            sortBy: "name",
            sortOrder: "asc",
            name: value
        };
        apiInstance
            .getRoutingQueuesDivisionviews(opts)
            .then((data) => {
            Global.queuesArray = [];
            if (data && data.entities.length > 0) {
                for (let i = 0; i < data.entities.length; i++) {
                    Global.queuesArray.push(data.entities[i].name);
                }
            }
            else {
                log("No queues found. Search:" + value);
            }
            log("foundInQueues: " + Global.queuesArray.length);
            deferred.resolve(Global.queuesArray.length);
        })
            .catch((err) => {
            log("getRoutingQueuesDivisionviews failure");
            deferred.reject(err);
        });
    }
    else {
        deferred.resolve(0);
    }
    return deferred.promise();
}
function checkForPeople(searchString, people) {
    const deferred = $.Deferred();
    if (people) {
        const apiInstance = new platformClient.UsersApi();
        const body = {
            sortOrder: "asc",
            sortBy: "name",
            pageSize: 25,
            pageNumber: 1,
            expand: ["authorization", "routingStatus", "presence", "conversationSummary", "outOfOffice"],
            query: [
                { fields: ["name", "email"], value: searchString, operator: "OR", type: "QUERY_STRING" },
                { fields: ["addresses.voice"], value: searchString, operator: "OR", type: "CONTAINS" }
            ] // [String],
        };
        apiInstance
            .postUsersSearch(body)
            .then((data) => {
            Global.peopleArray = [];
            if (Object.hasOwn(data, "results")) {
                if (data.results.length > 0) {
                    for (let i = 0; i < data.results.length; i++) {
                        const peopleInfo = {};
                        peopleInfo.name = data.results[i].name;
                        peopleInfo.status = data.results[i].presence.presenceDefinition.systemPresence;
                        Global.peopleArray.push(peopleInfo);
                    }
                    log("foundInPeople: " + data.results.length);
                }
                else {
                    log("No record found in people with search string: " + searchString);
                }
            }
            deferred.resolve(Global.peopleArray.length);
        })
            .catch((err) => {
            console.log("There was a failure calling postUsersSearch");
            deferred.reject(err);
        });
    }
    else {
        deferred.resolve(0);
    }
    return deferred.promise();
}
function removeSearchedRecordForNameOrNumber() {
    closeListRow();
    $("#recordNumbers").hide();
    $("#recordNumbers").html("");
}
function removeSearchedRecordForQueue() {
    const queueDataList = $("#queueListRecord");
    queueDataList.empty();
}
function checkSelectedRecordInsideSearchResultArry(recordType, recordArray, searchedString) {
    let shouldDisplayForm = false;
    $("#recordNumbers").html("");
    hideLoader();
    if (recordType == "frameworkContacts") {
        if (recordArray.phone.length > 0) {
            Global.searchResultArry = [];
            shouldDisplayForm = true;
            showSelectedRecordInfo(recordArray.name, recordArray.type, "&#x1F464", recordArray.attributes.IS_EntityType);
            let selectedItemId = null;
            for (let i = 0; i < recordArray.phone.length; i++) {
                if (!isEmailAddress(recordArray.phone[i].number) && !isNaN(replaceSpecialCharacterFromString(recordArray.phone[i].number))) {
                    if (!selectedItemId) {
                        selectedItemId = "[id='" + recordArray.phone[i].label + "']";
                    }
                    else if (recordArray.phone[i].number.includes(searchedString)) {
                        selectedItemId = "[id='" + recordArray.phone[i].label + "']";
                    }
                    showSelectedRecordPhoneNumberList(recordArray.phone[i].label, recordArray.phone[i].number, false);
                }
            }
            $(selectedItemId).prop("checked", true);
            Global.searchResultArry = recordArray;
        }
    }
    else if (recordType == "externalContacts") {
        showSelectedRecordNumberForExternalContacts(recordArray.name);
        if (recordArray.number.length > 0) {
            shouldDisplayForm = true;
            let selectedItemId = null;
            for (let j = 0; j < recordArray.number.length; j++) {
                let numberType = null;
                let numberValue = "";
                if (Object.hasOwn(recordArray.number[j], "workPhone")) {
                    numberType = "workPhone";
                    numberValue = recordArray.number[j].workPhone;
                }
                else if (Object.hasOwn(recordArray.number[j], "cellPhone")) {
                    numberType = "cellPhone";
                    numberValue = recordArray.number[j].cellPhone;
                }
                else if (Object.hasOwn(recordArray.number[j], "homePhone")) {
                    numberType = "homePhone";
                    numberValue = recordArray.number[j].homePhone;
                }
                if (!selectedItemId) {
                    selectedItemId = "[id='" + numberType + "']";
                }
                else if (numberValue.includes(searchedString)) {
                    selectedItemId = "[id='" + numberType + "']";
                }
                showSelectedRecordPhoneNumberListForExternalContacts(numberType, numberValue, false);
            }
            $(selectedItemId).prop("checked", true);
        }
    }
    else if (recordType == "queues") {
        shouldDisplayForm = true;
        $("#recordNumbers").append($("<label>")
            .prop({
            for: "interactionSyncQueue"
        })
            .html("&#127988" + "&nbsp" + recordArray));
    }
    else if (recordType == "people") {
        shouldDisplayForm = true;
        $("#recordNumbers").append($("<label>")
            .prop({
            for: "interactionSyncUser"
        })
            .html("&#x1F464" + "&nbsp" + recordArray.name));
    }
    if (shouldDisplayForm) {
        showSelectedRecordNumbers();
    }
}
function showSelectedRecordInfo(recordName, recordType, Identity, entityType) {
    $("#recordNumbers")
        .append($("<label>")
        .prop({
        for: recordType
    })
        .html(Identity + "&nbsp" + recordName + " (" + entityType + ")"))
        .append($("<hr>"));
}
function showSelectedRecordPhoneNumberList(recordType, recordValue, isChecked) {
    $("#recordNumbers")
        .append($("<input>").prop({
        type: "radio",
        id: recordType,
        value: recordValue,
        checked: isChecked,
        name: "crm_number"
    }))
        .append($("<label>")
        .prop({
        for: recordType,
        title: recordType
    })
        .html("&nbsp;&nbsp" + "&#9990" + "&nbsp" + recordValue))
        .append($("<br>"));
}
function showSelectedRecordNumberForExternalContacts(recordName) {
    $("#recordNumbers")
        .append($("<div>")
        .prop({
        id: "extCtn"
    })
        .html("EN"))
        .append($("<label>")
        .prop({
        for: "externalContact"
    })
        .html("&nbsp" + recordName))
        .append($("<hr>"));
}
function showSelectedRecordPhoneNumberListForExternalContacts(recordType, recordValue, isChecked) {
    $("#recordNumbers")
        .append($("<input>").prop({
        type: "radio",
        id: recordType,
        value: recordValue,
        checked: isChecked,
        name: "ext_number"
    }))
        .append($("<label>")
        .prop({
        for: recordType,
        title: recordType
    })
        .html("&nbsp;&nbsp" + "&#9990" + "&nbsp" + recordValue + " (" + recordType + ")"))
        .append($("<br>"));
}
function showSelectedRecordNumbers() {
    if ($("#recordNumbers").length > 0) {
        $("#recordNumbers").show();
    }
}
function hideTheNumberForm() {
    if ($("#recordNumbers").length > 0) {
        $("#recordNumbers").hide();
    }
}
export function checkPromtForQueueValue() {
    const checkQueue = {
        messageType: "promptForQueue",
        value: "clickToDialQueue"
    };
    sendPostMessage(checkQueue);
}
export function fillNumberAndQueueOutboundCall(number, queue) {
    if (isSidebarHtmlLoad()) {
        if (JSON.parse(getStorageItem(Constant.isPromptForQueue))) {
            if (!$("#customDialpad").is(":visible")) {
                showNewCustomDialpad();
            }
            if (queue) {
                getRoutingQueueBasedOnQueueId(queue)
                    .done(function (queueName) {
                    if (queueName) {
                        $("#queue").val(queueName);
                        $("#nameOrNumber").val(number);
                        showHideCallButton(true);
                    }
                })
                    .fail(function (error) {
                    error = returnErrorMessageForApiFailed(error);
                    log(error);
                });
            }
            else if (number) {
                $("#nameOrNumber").val(number);
                showHideCallButton(true);
            }
        }
        else {
            closeDialpad(100);
        }
    }
}
export function customDialSearch(event) {
    const keycode = event.which || event.keyCode;
    const blockedKeys = [37, 38, 39, 40];
    if (blockedKeys.indexOf(keycode) == -1) {
        clearTimeout(FunctionsVariable.typingTimer);
        $(".autocomplete-items").hide();
        removeSearchedRecordForNameOrNumber();
        resetAllSearchingArrayValues();
        showHideLoaderOnSearchValue();
        const searchString = $("#nameOrNumber").val();
        if (!searchString || searchString == "") {
            showHideCallButton(false);
        }
        else {
            showHideCallButton(true);
        }
        FunctionsVariable.typingTimer = setTimeout(checkEnteredNameOrNumberValue, FunctionsVariable.doneTypingInterval);
    }
}
export function customDialSearchTxtKeyUpForQueue() {
    clearTimeout(FunctionsVariable.typingTimerForQueue);
    const searchedValue = $("#queue").val();
    if (!searchedValue) {
        FunctionsVariable.doneTypingIntervalForQueue = 0;
    }
    FunctionsVariable.typingTimerForQueue = setTimeout(getRoutingQueuesValue, FunctionsVariable.doneTypingIntervalForQueue);
}
export function customDialSearchTxtKeyDownForQueue() {
    clearTimeout(FunctionsVariable.typingTimerForQueue);
}
function showHideLoaderOnSearchValue() {
    const e = $("#nameOrNumber").val();
    if (e.length == 0) {
        hideLoader();
    }
    else {
        $("#searchLoader").show();
    }
}
function hideLoader() {
    $("#searchLoader").hide();
}
const debounceCallFunctionForRemoveSearchedRecordForQueue = debounce(function () {
    removeSearchedRecordForQueue();
}, 500);
function getRoutingQueueBasedOnQueueId(queueId) {
    const deferred = $.Deferred();
    const apiInstance = new platformClient.RoutingApi();
    apiInstance
        .getRoutingQueue(queueId)
        .then((data) => {
        if (data) {
            log("selected queue based on Id is: " + data.name);
            deferred.resolve(data.name);
        }
        else {
            deferred.resolve(null);
        }
    })
        .catch((err) => {
        log("There was a failure calling getRoutingQueue");
        deferred.reject(err);
    });
    return deferred.promise();
}
function resetAllSearchingArrayValues() {
    Global.searchResultArry = [];
    Global.externalContactsArray = [];
    Global.queuesArray = [];
    Global.peopleArray = [];
}
function checkValueTypeSearchResultArry(searchResultArry) {
    let newPhoneArray = [];
    for (let j = 0; j < searchResultArry.length; j++) {
        newPhoneArray = [];
        for (let i = 0; i < searchResultArry[j].phone.length; i++) {
            if (isNaN(replaceSpecialCharacterFromString(searchResultArry[j].phone[i].number))) {
                delete searchResultArry[j].phone[i];
            }
            else {
                newPhoneArray.push(searchResultArry[j].phone[i]);
            }
        }
        searchResultArry[j].phone = newPhoneArray;
    }
}
function replaceSpecialCharacterFromString(actualString) {
    if (actualString) {
        if (/\s/g.test(actualString)) {
            actualString = actualString.replace(/ /g, "");
        }
        return actualString.replace(/[^\w\s]/gi, "");
    }
}
export function hideNewCustomDialpad() {
    const shouldOverrideNewInteraction = getParticipantCustomAttribute("overrideNewinteraction");
    if (shouldOverrideNewInteraction) {
        $("#customDialpad").delay(FunctionsVariable.delayTimeForHideCustomDial).hide(0);
    }
    else {
        const searchTargets = getSettingFromConfig("searchTargets");
        if (searchTargets.length == 0 || searchTargets.indexOf("frameworkContacts") == -1) {
            FunctionsVariable.makeCallFromScriptId = FunctionsVariable.scriptId;
            setTimeout(function () {
                FunctionsVariable.makeCallFromScriptId = null;
            }, 2000);
        }
    }
}
function showHideEmbeddedClient(showClient, showIsyncMessagePanel) {
    if ($("#isynccif").length != 0) {
        if (showClient) {
            $("#isynccif").show();
        }
        else {
            $("#isynccif").hide();
        }
    }
    else {
        if (showIsyncMessagePanel) {
            $("#isyncmessagepanel").show();
        }
        else {
            $("#isyncmessagepanel").hide();
        }
    }
    const isUserLoggedIn = getStorageItem(Constant.lsIsSidebarLogIn);
    if (isUserLoggedIn == true || isUserLoggedIn == "true") {
        /*on click of ISync minimise/expand button, we need to hide or show the chat, embedded, rocket icons */
        if (ShouldEmbeddedInteractionWindow() == true && showClient == true) {
            showHideChatAndPopOutIconsDiv(true);
        }
        else {
            showHideChatAndPopOutIconsDiv(false);
        }
        /* on click of Isync minimise/expand button, we need to hide or show release info button and popup*/
        showHideReleaseInfoIconAndPopup(showClient);
        /* on click of Isync minimise/expand button, we need to hide or show survey button/icon*/
        showHideSurveyIcon(showClient);
    }
}
export function setSoftPhonePanelMode(setModeValue) {
    setMode(setModeValue)
        .done(function success() {
        // nop
    })
        .fail(function (error) {
        error = returnErrorMessageForApiFailed(error);
        log(error);
    });
}
function performScreenPopOrAssociationBasedOnInteraction(interaction) {
    if (interaction) {
        PartnerVariable.newOutboundInteraction = false;
        if (interaction.attributes && interaction.attributes.is_searchpop == "N") {
            addAssociationByCurrentPage(interaction.id);
        }
        else {
            forceScreenpopForOutbound(interaction);
        }
    }
}
export function updateFlagAndSetInteractionId(interaction) {
    if (FunctionsVariable.flagCallForScreenpop) {
        FunctionsVariable.flagCallForScreenpop = false;
        FunctionsVariable.processingInteractionId = interaction.id;
    }
}
export function onSelectResult(rowElement) {
    resetAllSearchingArrayValues();
    let searchedString = "";
    if ($(rowElement).find("#lblname").length > 0) {
        const inp = $("#nameOrNumber");
        searchedString = inp.val();
        inp.val($(rowElement).find("#lblname").text());
    }
    else {
        log("Not found custom-div element - lblname");
    }
    if ($(rowElement).find("textarea").length > 0) {
        const e = $(rowElement).find("textarea").val();
        const recordArray = JSON.parse(e);
        const recordType = $(rowElement).find("textarea").attr("data_recordtype");
        checkSelectedRecordInsideSearchResultArry(recordType, recordArray, searchedString);
    }
    else {
        log("Not found custom-div element - textarea of JSON");
    }
    closeListRow();
}
function hideListRow(elmnt) {
    const inp = document.getElementById("nameOrNumber");
    const rowx = document.getElementsByClassName("autocomplete-items");
    for (let i = 0; i < rowx.length; i++) {
        if (elmnt != rowx[i] && elmnt != inp) {
            $(".autocomplete-items").hide();
        }
    }
}
function closeListRow() {
    try {
        const inp = document.getElementById("nameOrNumber");
        const rowx = document.getElementsByClassName("autocomplete-items");
        for (let i = 0; i < rowx.length; i++) {
            inp.parentNode.removeChild(rowx[i]);
        }
    }
    catch (e) {
        // nop
    }
}
export function checkingMouseClickElement(e) {
    hideListRow(e);
}
function getHighlightedTextForCustomDialSearch(resultStr, searchStr) {
    const subStrIndex = resultStr.toLowerCase().indexOf(searchStr.toLowerCase());
    if (subStrIndex != -1) {
        const matchedSubString = resultStr.substr(subStrIndex, searchStr.length);
        return resultStr.replace(matchedSubString, "<strong>" + matchedSubString + "</strong>");
    }
    else {
        return resultStr;
    }
}
export function showExistingListOnFocusIn() {
    FunctionsVariable.nameOrNumberFocused = true;
    $(".autocomplete-items").show();
}
export function hideListOnFocusOut() {
    FunctionsVariable.nameOrNumberFocused = false;
    setTimeout(function () {
        if (!FunctionsVariable.nameOrNumberFocused) {
            $(".autocomplete-items").hide();
        }
    }, 850);
}
function isSearchInProgressCancelled(functionName, searchString) {
    const shouldOverrideNewInteraction = getParticipantCustomAttribute("overrideNewinteraction");
    if (shouldOverrideNewInteraction) {
        searchString = $("#nameOrNumber").val();
    }
    if (FunctionsVariable.searchInProgress != searchString) {
        log(functionName + ". cancelled search: " + searchString);
        if (shouldOverrideNewInteraction)
            closeListRow();
        return true;
    }
    else {
        return false;
    }
}
const debounceCallFunctionForClicktodialForActionRequest = debounce(function (jsonData) {
    clicktodialForActionRequest(jsonData);
}, 200);
function checkAndManageSessionTabArrayForWST(entityData) {
    if (entityData) {
        entityData = JSON.parse(entityData);
        getFocusedSession()
            .done(function success(sessionId) {
            if (sessionId) {
                let checkTabArray = getStorageItem(Constant.ssSessionTabArrayInfo);
                if (checkTabArray) {
                    checkTabArray = JSON.parse(checkTabArray);
                    const isMatch = searchInJSON(checkTabArray, "sessionId", sessionId);
                    if (isMatch.length > 0) {
                        if (Object.hasOwn(entityData, "etn") && Object.hasOwn(entityData, "id")) {
                            const isEntityIdFound = searchInJSON(isMatch[0].tabArray, "IS_EntityId", entityData.id);
                            if (isEntityIdFound.length < 1) {
                                isMatch[0].tabArray.push({ IS_EntityType: entityData.etn, IS_EntityId: entityData.id, IsFocused: false });
                                setStorageItem(Constant.ssSessionTabArrayInfo, JSON.stringify(checkTabArray));
                            }
                        }
                    }
                    else {
                        updateSessionTabArrayValue(checkTabArray, sessionId, entityData);
                    }
                }
                else {
                    const newTabArray = [];
                    updateSessionTabArrayValue(newTabArray, sessionId, entityData);
                }
            }
        })
            .fail(function (error) {
            error = returnErrorMessageForApiFailed(error);
            log(error);
        });
    }
}
function updateSessionTabArrayValue(tabArray, sessionId, entityData) {
    let interactionId = null;
    if (getNewInteractionId()) {
        interactionId = getNewInteractionId();
    }
    else {
        interactionId = getCurrentInteractionId();
    }
    if (Global.transferSessionTabsArray.length > 0) {
        storeTransferTabDataArrayBasedOnValue(tabArray, sessionId, interactionId, Global.transferSessionTabsArray);
        Global.transferSessionTabsArray = [];
    }
    else if (Object.hasOwn(entityData, "etn") && Object.hasOwn(entityData, "id")) {
        storeTransferTabDataArrayBasedOnValue(tabArray, sessionId, interactionId, [{ IS_EntityType: entityData.etn, IS_EntityId: entityData.id, IsFocused: false }]);
    }
}
function createTranferTabs() {
    for (let i = 1; i < Global.transferSessionTabsArray.length; i++) {
        const input = createTabInput(Constant.applicationTabTemplateName, Global.transferSessionTabsArray[i].IS_EntityType, Global.transferSessionTabsArray[i].IS_EntityId);
        if (Global.transferSessionTabsArray[i].IsFocused) {
            input.isFocused = Global.transferSessionTabsArray[i].IsFocused;
        }
        createTab(input)
            .done(function success() {
            // nop
        })
            .fail(function error(err) {
            err = returnErrorMessageForApiFailed(err);
            log(err);
        });
    }
}
function removeSessionTabsForWSTFromSession(key, value) {
    let sessionTabArrayInfo = getStorageItem(Constant.ssSessionTabArrayInfo);
    if (sessionTabArrayInfo != null && sessionTabArrayInfo != "[]") {
        sessionTabArrayInfo = JSON.parse(sessionTabArrayInfo);
        const isPresent = searchInJSON(sessionTabArrayInfo, key, value);
        if (isPresent.length > 0) {
            sessionTabArrayInfo = sessionTabArrayInfo.filter(function (ele) {
                return ele.interactionId != isPresent[0].interactionId;
            });
            setStorageItem(Constant.ssSessionTabArrayInfo, JSON.stringify(sessionTabArrayInfo));
        }
    }
}
function updateSessionMappingTabArrayBasedOnEventData(eventData) {
    let data = {};
    const etn = getQueryStringParameterByName("etn", eventData);
    const id = getQueryStringParameterByName("id", eventData);
    if (etn && id) {
        data.etn = etn;
        data.id = id.replace(/[{}]/g, "");
        data = JSON.stringify(data);
        checkAndManageSessionTabArrayForWST(data);
    }
}
export function saveMessagingTranscriptToActivity(interaction, activityId) {
    const deferred = $.Deferred();
    if (shouldSaveTranscript(interaction) && interaction.messageTranscript) {
        if (activityId == interaction.id) {
            deferred.resolve();
        }
        if (checkInteractionTypeIsMessage(interaction)) {
            let contentData = "";
            const messages = interaction.messageTranscript.messages;
            if (messages.length > 0 && hasHandledChatTranscriptBasedOnInteractionId(interaction.id) == null) {
                for (let i = 0; i < messages.length; i++) {
                    contentData = contentData + messages[i].role + ": " + messages[i].body + "\n";
                }
            }
            if (contentData) {
                if (shouldCreateActivityOnUserSelection() && activityId == interaction.id) {
                    setActivityMessagingTranscriptCache(contentData, interaction.id);
                }
                else {
                    updateExistingMessagingActivityTranscriptBasedOnAnnotationId(interaction, contentData)
                        .done(function (result) {
                        if (result) {
                            createMessagingTranscriptForSaveActivity(contentData, activityId, interaction);
                        }
                    })
                        .fail(function (error) {
                        log("Failed in saveEntity with Messaging Transcript");
                        deferred.reject(error);
                    });
                }
                deferred.resolve();
            }
        }
    }
    else {
        deferred.resolve();
    }
    return deferred.promise();
}
function assignDirectionCodeForMessageTypeInteraction(direction, initilizeMappingDetectCreateObject) {
    if (direction == "Inbound") {
        initilizeMappingDetectCreateObject.entity.isgc_directioncode = false;
    }
    else {
        initilizeMappingDetectCreateObject.entity.isgc_directioncode = true;
    }
    return initilizeMappingDetectCreateObject;
}
function ScreenPopPrefillFiledNameForMessaging(entityName, searchString) {
    let fields = null;
    if (searchString && entityName == "contact") {
        const splitName = searchString.split(" ");
        fields = {};
        fields["firstname"] = splitName[0];
        if (splitName.length > 1) {
            fields["lastname"] = splitName[1];
        }
    }
    return fields;
}
function removeInteractionAttributesForMessaging(interactionId) {
    const attributes = { IS_AnnotationId: "" };
    setInteractionAttributes(interactionId, attributes);
}
function setActivityMessagingTranscriptCache(activityEntity, interactionId) {
    const currentActivityCache = getActivityChatTranscriptCache(interactionId);
    let combinedActivityCache;
    if (currentActivityCache != null) {
        combinedActivityCache = $.extend({}, currentActivityCache, activityEntity);
    }
    else {
        combinedActivityCache = activityEntity;
    }
    setItemDataOnInteractionId(combinedActivityCache, interactionId, Constant.ssActivityWebMessagingTranscriptCache);
}
function getActivityWebMessagingTranscriptCache(interactionId) {
    return getItemBasedOnInteractionId(interactionId, Constant.ssActivityWebMessagingTranscriptCache);
}
function createMessagingTranscriptForSaveActivity(contentData, activityId, interaction) {
    const deferred = $.Deferred();
    const entity = {
        notetext: contentData
    };
    const interactionType = checkInteractionTypeIsMessage(interaction);
    if (interactionType) {
        entity["subject"] = interactionType + " Conversation";
        if (interactionType.toLowerCase() == "webmessaging") {
            entity["objectid_" + Constant.webMessageName + "@odata.bind"] = "/" + Constant.webMessagesName + "(" + activityId + ")";
        }
        else {
            entity["objectid_" + Constant.messageName + "@odata.bind"] = "/" + Constant.messagesName + "(" + activityId + ")";
        }
    }
    if (interaction.isChat) {
        entity["subject"] = "Chat Conversation";
        entity["objectid_" + Constant.chatName + "@odata.bind"] = "/" + Constant.chatsName + "(" + activityId + ")";
    }
    trackEventBeforSaveActivity(entity, "annotation");
    saveEntity("annotation", entity)
        .done(function (result) {
        result = JSON.parse(result);
        if (interaction.isMessage) {
            const attributes = { IS_AnnotationId: result.id };
            setInteractionAttributes(interaction.id, attributes);
        }
        trackEventAfterSaveActivity(result);
        deferred.resolve(result.id);
    })
        .fail(function (error) {
        log("Failed in saveEntity with Messaging Transcript");
        deferred.reject(error);
    });
    return deferred.promise();
}
function removeMessagingTranscriptCache(interactionId) {
    removeItemDataOnInteractionId(interactionId, Constant.ssActivityWebMessagingTranscriptCache);
}
export function saveChatOrMessagingTranscriptFromCache(activityData, interaction) {
    if (interaction.isMessage) {
        const currentActivityCacheForWebMessaging = getActivityWebMessagingTranscriptCache(interaction.id);
        if (currentActivityCacheForWebMessaging != null) {
            const activityId = activityData.substr(activityData.indexOf("|") + 1);
            createMessagingTranscriptForSaveActivity(currentActivityCacheForWebMessaging, activityId, interaction);
        }
    }
    if (interaction.isChat) {
        const currentActivityCache = getActivityChatTranscriptCache(interaction.id);
        if (currentActivityCache != null) {
            const newInteractionObj = {};
            newInteractionObj.chatTranscript = currentActivityCache;
            newInteractionObj.id = interaction.id;
            newInteractionObj.isChat = true;
            const activityId = activityData.substr(activityData.indexOf("|") + 1);
            saveChatTranscriptToActivity(newInteractionObj, activityId);
        }
    }
}
function updateExistingMessagingActivityTranscriptBasedOnAnnotationId(interaction, contentData) {
    const deferred = $.Deferred();
    const entity = {};
    let ssAnnotationIdForTranscript = null;
    if (interaction.attributes && Object.hasOwn(interaction.attributes, "is_annotationid") && interaction.attributes.is_annotationid != null) {
        ssAnnotationIdForTranscript = interaction.attributes.is_annotationid;
    }
    if (ssAnnotationIdForTranscript) {
        entity.notetext = contentData;
        updateEntity("annotation", ssAnnotationIdForTranscript, entity)
            .done(function (result) {
            result = JSON.parse(result);
            trackEventAfterSaveActivity(result);
            deferred.resolve(false);
        })
            .fail(function (error) {
            log("Failed in updateEntity with WebMessaging Transcript", error);
        });
    }
    else {
        deferred.resolve(true);
    }
    return deferred.promise();
}
export function shouldSaveTranscript(interaction) {
    const storeConversationTranscript = getSettingFromConfig("storeconversationtranscript");
    if (storeConversationTranscript && storeConversationTranscript.length > 0) {
        const interactionType = checkInteractionTypeIsMessage(interaction);
        if (interactionType && storeConversationTranscript.indexOf(interactionType) != -1) {
            return true;
        }
        if (interaction.isChat && storeConversationTranscript.indexOf("Chat") != -1) {
            return true;
        }
    }
    return false;
}
function createMessagingSubjectLabel(startTime, interaction) {
    let a_p = "", subjectPrifix = "";
    const d = new Date(startTime);
    let curr_hour = d.getHours();
    if (curr_hour < 12) {
        a_p = "AM";
    }
    else {
        a_p = "PM";
    }
    if (curr_hour == 0) {
        curr_hour = 12;
    }
    if (curr_hour > 12) {
        curr_hour = curr_hour - 12;
    }
    let curr_min = d.getMinutes().toString();
    curr_min = curr_min + "";
    if (curr_min.length == 1) {
        curr_min = "0" + curr_min;
    }
    let curr_second = d.getSeconds().toString();
    curr_second = curr_second + "";
    if (curr_second.length == 1) {
        curr_second = "0" + curr_second;
    }
    const time = curr_hour + ":" + curr_min + ":" + curr_second + " " + a_p;
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2)
        month = "0" + month;
    if (day.length < 2)
        day = "0" + day;
    const interactionType = checkInteractionTypeIsMessage(interaction);
    if (interactionType) {
        subjectPrifix = interactionType + " ";
    }
    return subjectPrifix + [month, day, year].join("/") + ", " + time;
}
export function processingStartTimeSubjectAnnotationForMessaging(startTime, callLog, interaction) {
    if (checkInteractionTypeIsMessage(interaction)) {
        if (!getItemBasedOnInteractionId(interaction.id, Constant.ssMessagingInteractionStartTime)) {
            setItemDataOnInteractionId(startTime, interaction.id, Constant.ssMessagingInteractionStartTime);
        }
        if (!getItemBasedOnInteractionId(interaction.id, Constant.ssMessagingInteractionSubject)) {
            setItemDataOnInteractionId(createMessagingSubjectLabel(startTime, interaction), interaction.id, Constant.ssMessagingInteractionSubject);
        }
        if (!(Object.hasOwn(callLog, "id") && (callLog.id.includes(Constant.webMessageName) || callLog.id.includes(Constant.messageName)))) {
            removeInteractionAttributesForMessaging(interaction.id);
        }
    }
}
function getSubjectIfMessagingInteraction(interaction, subject) {
    if (checkInteractionTypeIsMessage(interaction)) {
        subject = getItemBasedOnInteractionId(interaction.id, Constant.ssMessagingInteractionSubject);
    }
    return subject;
}
export function getDurationIfMessagingInteraction(interaction, d) {
    if (checkInteractionTypeIsMessage(interaction)) {
        d = Math.round(diff_seconds(new Date(getItemBasedOnInteractionId(interaction.id, Constant.ssMessagingInteractionStartTime)), new Date()));
    }
    return secondsToTime(d);
}
function removeSubjectAndDurationForMessaging(interactionId) {
    removeItemDataOnInteractionId(interactionId, Constant.ssMessagingInteractionStartTime);
    removeItemDataOnInteractionId(interactionId, Constant.ssMessagingInteractionSubject);
}
function returnDisplayNameBasedOnEntityType() {
    //set activity tab title for new introduced entity.
    const interactionData = getInteractionData(getCurrentInteractionId());
    if (interactionData) {
        const interactionType = checkInteractionTypeIsMessage(interactionData);
        if (interactionType && interactionType.toLowerCase() != "webmessaging") {
            return interactionType;
        }
    }
    return "Message";
}
//Handling open tabs for session transfer workspace.
function storeTransferTabDataArrayBasedOnValue(tabArray, sessionId, interactionId, entityData) {
    tabArray.push({ sessionId: sessionId, interactionId: interactionId, tabArray: entityData });
    setStorageItem(Constant.ssSessionTabArrayInfo, JSON.stringify(tabArray));
    log("Added popped entity tab into tabArray");
}
//Embedded interaction window code--------------
function loadEmbeddedInteractionWindow() {
    const EmbeddedInteractionWindowUrl = getParticipantCustomAttribute("embeddedWindowUrl");
    if (EmbeddedInteractionWindowUrl != null && Global.flagEmbeddedInteractionWindow && ShouldEmbeddedInteractionWindow()) {
        $("#isyncEmbeddedInteractionWindow").attr("src", EmbeddedInteractionWindowUrl);
        $("#openEmbeddedWindow").unbind("click");
        $("#openEmbeddedWindow").on("click", () => hideshowIframeInteractiveWindow());
        $("#popOutEmbeddedWindow").unbind("click");
        $("#popOutEmbeddedWindow").on("click", () => popOutInteractionWindow());
        $("#isyncEmbeddedInteractionWindow").css("display", "none");
        Global.flagEmbeddedInteractionWindow = false;
        /* Do not show chat and popuout icons incase of Isync is in minimized mode */
        if (getStorageItem(Constant.lsCheckSetModeValue) != "0") {
            showHideChatAndPopOutIconsDiv(true);
        }
    }
}
export function showHideChatAndPopOutIconsDiv(needToShow) {
    if (needToShow == true) {
        $("#divChatIconEmbeddedWindow").css("display", "block");
        $("#divPopOutIconEmbeddedWindow").css("display", "block");
    }
    else {
        $("#divChatIconEmbeddedWindow").css("display", "none");
        $("#divPopOutIconEmbeddedWindow").css("display", "none");
    }
}
function hideshowIframeInteractiveWindow() {
    if ($("#embeddedMainDiv").css("display") == "none") {
        showEmbeddedInteractionWindow();
    }
    else {
        hideEmbeddedInteractionWindow();
    }
}
export function hideEmbeddedInteractionWindow() {
    $("#isyncEmbeddedInteractionWindow").css("display", "none");
    $("#embeddedMainDiv").css("display", "none");
    $("#isyncEmbeddedInteractionWindowDiv").css("display", "none");
}
function showEmbeddedInteractionWindow() {
    $("#isyncEmbeddedInteractionWindow").css("display", "block");
    $("#embeddedMainDiv").css("display", "block");
    $("#isyncEmbeddedInteractionWindowDiv").css("display", "block");
    hitStorageHandlerToCloseEmbeddedWindow();
    if (Global.openedWindow) {
        Global.openedWindow.close();
    }
}
const debounceCallFunctionForloadEmbeddedInteractionWindow = debounce(function () {
    // All the taxing stuff you do
    loadEmbeddedInteractionWindow();
}, 200);
function ShouldEmbeddedInteractionWindow() {
    const embeddedInteractionWindow = getSettingFromConfig("embeddedInteractionWindow");
    return embeddedInteractionWindow == true || embeddedInteractionWindow == "true";
}
const debounceCallFunctionForHideshowIframeInteractiveWindow = debounce(function () {
    hideshowIframeInteractiveWindow();
}, 200);
export function showHideReleaseInfoIconAndPopup(needToShow) {
    if (needToShow == true) {
        $("#divReleaseInfo").css("display", "block");
    }
    else {
        $("#divReleaseInfo").css("display", "none");
        $("#divReleaseInfoPopup").css("display", "none");
    }
}
export function loadPostCallSurveyIcon() {
    const marginLeftForSurveyIcon = ShouldEmbeddedInteractionWindow() ? "marginLeft90" : "marginLeft50";
    $("#divSurvey").removeClass("marginLeft90");
    $("#divSurvey").addClass(marginLeftForSurveyIcon);
    $("#btnSurveyIcon").on("click", () => callTransferForSurvey());
}
export function getPostCallSurveySettingFromConfig(settingName) {
    if (isConfigurationLoaded()) {
        const jsonSettingData = getParticipantCustomAttribute("settingsJson");
        if (jsonSettingData) {
            const settingJsonValue = JSON.parse(jsonSettingData);
            if (Object.hasOwn(settingJsonValue, "postCallSurvey")) {
                const postCallSurveyObj = settingJsonValue["postCallSurvey"];
                if (Object.hasOwn(postCallSurveyObj, settingName)) {
                    return postCallSurveyObj[settingName];
                }
            }
        }
    }
    return null;
}
function getCurrentInteractionType() {
    const currentInteractionId = getCurrentInteractionId();
    let typeOfInteraction = "";
    if (currentInteractionId != null) {
        const interaction = getInteractionData(currentInteractionId);
        if (interaction) {
            if (interaction.isCallback && !interaction.isDialerPreview) {
                typeOfInteraction = "callback";
            }
            else if (interaction.isChat) {
                typeOfInteraction = "chat";
            }
            else if (interaction.isEmail) {
                typeOfInteraction = "email";
            }
            else if (interaction.isMessage) {
                typeOfInteraction = "Messaging(sms,web,facebook,whatsapp,line,open)";
            }
            else {
                typeOfInteraction = "phonecall";
            }
        }
    }
    return typeOfInteraction;
}
function isInteractionTypeVoiceAndActive() {
    let isValidRequest = false;
    const currentInteraction = getCurrentInteractionId();
    if (currentInteraction != null) {
        if (getCurrentInteractionType() == "phonecall") {
            const currentInteractionState = getStorageItem(Constant.ssInteractionStates);
            if (currentInteractionState != null) {
                const JsonObj = JSON.parse(currentInteractionState);
                const isInteractionPresent = JsonObj.filter(function (JsonObj) {
                    return JsonObj["interactionId"] == currentInteraction && JsonObj["state"] == "disconnect";
                });
                if (isInteractionPresent.length == 0) {
                    isValidRequest = true;
                }
            }
        }
    }
    return isValidRequest;
}
export function isActiveInteractionExists() {
    let isValidRequest = false;
    const results = JSON.parse(getStorageItem(Constant.ssInteractionStates));
    if (results.length > 0) {
        const disconnectedInteractions = results.filter(({ state }) => state == "disconnect");
        if (disconnectedInteractions.length == 0)
            return (isValidRequest = true);
        else {
            const interactionIdsToFilter = disconnectedInteractions.map(({ interactionId }) => interactionId);
            const idsToFilter = new Set(interactionIdsToFilter);
            const activeInteractions = results.filter((obj) => !idsToFilter.has(obj.interactionId));
            if (activeInteractions.length > 0)
                return (isValidRequest = true);
        }
    }
    else
        isValidRequest;
}
export function showHideSurveyIcon(needToShow) {
    if (needToShow == true && isInteractionTypeVoiceAndActive() == true && getPostCallSurveySettingFromConfig("allowPostCallSurvey") == true) {
        $("#divSurvey").css("display", "block");
    }
    else {
        $("#divSurvey").css("display", "none");
    }
}
export function callTransferForSurvey() {
    platformClient.ApiClient.setEnvironment(getStorageItem(Constant.lsClientRegion));
    platformClient.ApiClient.instance.setAccessToken(getStorageItem(Constant.ssAuthToken));
    const currentInteractionId = getCurrentInteractionId(); /* String | conversationId */
    const ivrFlowName = getPostCallSurveySettingFromConfig("postCallSurveyIVRFlowName").trim();
    const transferErrorMessage = "Post Call Survey-Transfer to post call survey is failed. Contact your system administrator.";
    const ivrFlowNameMessage = "Post Call Survey-IVR flow name: " + ivrFlowName;
    const notificationType = "error";
    log(ivrFlowNameMessage);
    if (currentInteractionId != null) {
        let surveyFrequency = getPostCallSurveySettingFromConfig("postCallSurveyFrequency");
        const apiInstance = new platformClient.ConversationsApi();
        if (surveyFrequency != null) {
            surveyFrequency = surveyFrequency.trim();
            const surveyFrequencyValue = surveyFrequency == "N/A" ? surveyFrequency : surveyFrequency.split(" ")[0].trim();
            const surveyFrequencyErrorMessage = "Call cannot be transferred to survey due to frequency limitation.";
            const currentInteractionData = getInteractionData(currentInteractionId);
            const phoneNumber = encodeSearchString(currentInteractionData.ani);
            let getPostCallSurveyDetails = "?$select=isgc_iscalltransferredtopostcallsurvey,createdon,isgc_phonenumber&$filter=";
            getPostCallSurveyDetails += "contains(isgc_phonenumber, '" + phoneNumber + "') and isgc_iscalltransferredtopostcallsurvey eq true&$orderby=createdon desc&$top=1";
            searchAndOpenRecords("isgc_postcallsurvey", getPostCallSurveyDetails, true)
                .done(function (results) {
                const result = JSON.parse(results);
                let diffInDays;
                if (results != "{}") {
                    const currentDate = new Date();
                    const surveyDate = new Date(result[0].createdon);
                    diffInDays = currentDate.getDate() - surveyDate.getDate();
                }
                if (surveyFrequencyValue == "N/A" || results == "{}" || surveyFrequencyValue <= diffInDays) {
                    /* Get call conversation */
                    apiInstance
                        .getConversationsCall(currentInteractionId)
                        .then((data) => {
                        log(`getConversationsCall success! data: ${JSON.stringify(data, null, 2)}`);
                        const customerParticipantObj = data.participants.filter((p) => p.purpose == "agent" && p.state == "connected");
                        const participantId = customerParticipantObj[0].id;
                        const body = { address: ivrFlowName }; // Object | Transfer request
                        log("Post Call Survey-Participaction data:" + JSON.stringify(customerParticipantObj[0]) + "body:" + JSON.stringify(body));
                        /* Transfer the call from participant(agent) to IVR flow/address/number specified in the body */
                        apiInstance
                            .postConversationsCallParticipantReplace(currentInteractionId, participantId, body)
                            .then(() => {
                            /*Insert an entry into session storage to identify if call is transferred to survey, so that we can read it and insert into CRM phone call activity.*/
                            const currentInteractionId = getCurrentInteractionId();
                            setStorageItem(Constant.ssIsCallTransferredToPostCallSurvey + currentInteractionId, true);
                            const entity = {
                                isgc_iscalltransferredtopostcallsurvey: true,
                                isgc_phonenumber: currentInteractionData.ani
                            };
                            createRecord("isgc_postcallsurvey", entity)
                                .done(function (result) {
                                const results = JSON.parse(result);
                                log("Create Post Call Survey Details saved successfully-isgc_postcallsurvey. " + results);
                            })
                                .fail(function (error) {
                                log("Create Post Call Survey Details Failed." + error);
                            });
                            log("Post Call Survey-Sucessfully transfered the call to survey.");
                        })
                            .catch((err) => {
                            sendNotification(transferErrorMessage, notificationType);
                            log(transferErrorMessage + JSON.stringify(err));
                        });
                    })
                        .catch((err) => {
                        sendNotification(transferErrorMessage, notificationType);
                        log(transferErrorMessage + JSON.stringify(err));
                    });
                }
                else {
                    sendNotification(surveyFrequencyErrorMessage, notificationType);
                    log(surveyFrequencyErrorMessage);
                }
            })
                .fail(function (error) {
                log("Post call survey frequency check " + error);
                sendNotification("Post Call Survey Frequency. : " + error, notificationType);
            });
        }
        else {
            /* Get call conversation */
            apiInstance
                .getConversationsCall(currentInteractionId)
                .then((data) => {
                log(`getConversationsCall success! data: ${JSON.stringify(data, null, 2)}`);
                const customerParticipantObj = data.participants.filter((p) => p.purpose == "agent" && p.state == "connected");
                const participantId = customerParticipantObj[0].id;
                const body = { address: ivrFlowName }; // Object | Transfer request
                log("Post Call Survey-Participaction data:" + JSON.stringify(customerParticipantObj[0]) + "body:" + JSON.stringify(body));
                /* Transfer the call from participant(agent) to IVR flow/address/number specified in the body */
                apiInstance
                    .postConversationsCallParticipantReplace(currentInteractionId, participantId, body)
                    .then(() => {
                    /*Insert an entry into session storage to identify if call is transferred to survey, so that we can read it and insert into CRM phone call activity.*/
                    const currentInteractionId = getCurrentInteractionId();
                    setStorageItem(Constant.ssIsCallTransferredToPostCallSurvey + currentInteractionId, true);
                    log("Post Call Survey-Sucessfully transfered the call to survey.");
                })
                    .catch((err) => {
                    sendNotification(transferErrorMessage, notificationType);
                    log(transferErrorMessage + JSON.stringify(err));
                });
            })
                .catch((err) => {
                sendNotification(transferErrorMessage, notificationType);
                log(transferErrorMessage + JSON.stringify(err));
            });
        }
    }
    else {
        sendNotification("Post Call Survey-There is no active interaction.", "warning");
        log("Post Call Survey-There is no active interaction.");
    }
    /*Hide the survey button/icon once agent clicked on it*/
    showHideSurveyIcon(false);
}
function showHideReleaseInfoPopup(event) {
    if ($("#divReleaseInfoPopup").css("display") == "none") {
        $("#divReleaseInfoPopup").css("display", "block");
    }
    else {
        $("#divReleaseInfoPopup").css("display", "none");
    }
    event.stopPropagation();
}
export function hideReleaseInfoPopup() {
    if ($("#divReleaseInfoPopup").css("display") == "block") {
        $("#divReleaseInfoPopup").css("display", "none");
    }
}
export function loadReleaseInfoControls() {
    const isyncSolutionIdFull = getStorageItem(Constant.ssSolutionVersionFull);
    log(`ISync solution full id: ${isyncSolutionIdFull}, Git hash id: ${Constant.commitHash}`);
    const releaseVersion = `Version: ${isyncSolutionIdFull}-${Constant.commitHash}`;
    const marginLeft = ShouldEmbeddedInteractionWindow() ? "marginLeft70" : "marginLeft30";
    $("#divReleaseInfo").removeClass("marginLeft70");
    $("#divReleaseInfo").addClass(marginLeft);
    $("#btnReleaseIcon").unbind("click");
    $("#btnReleaseIcon").on("click", (e) => showHideReleaseInfoPopup(e));
    /*hide release info popup incase of onclick of Isync iframe or outside Isync area*/
    $(window).on("focusout", function () {
        setTimeout(() => {
            hideReleaseInfoPopup();
        }, 500);
    });
    const link = '<a id="saveLogs" href="./StepsToSaveBrowserLogs.html" target="_blank"> Click here for help saving log files</a>'; // eslint-disable-line
    const minimizeSoftphoneCheckbox = '<br><label> Minimize Softphone : </label><input class= minimizesoftphonecheckbox type="checkbox" id="minimizesoftphone"/><br>'; // eslint-disable-line
    if (isCIFV2() && enableMinimizeSoftphoneChecbox()) {
        $("#divReleaseInfoPopup").html(releaseVersion + "<br>" + link + minimizeSoftphoneCheckbox);
    }
    else {
        $("#divReleaseInfoPopup").html(releaseVersion + "<br>" + link);
    }
    const minimizeSoftphoneForCIFV2 = getStorageItem(Constant.lsMinimizeSoftphoneForCIFV2);
    if (minimizeSoftphoneForCIFV2) {
        $("#minimizesoftphone").prop("checked", true);
    }
    /*Do not show rocket icon incase of Isync is in minimized mode*/
    if (getStorageItem(Constant.lsCheckSetModeValue) != "0") {
        showHideReleaseInfoIconAndPopup(true);
    }
    $("#saveLogs").on("click", function () {
        log("saveLogs clicked");
        hideReleaseInfoPopup();
    });
    $("#minimizesoftphone").on("click", function () {
        const minimizeSoftphoneCheck = $("#minimizesoftphone").is(":checked");
        if (minimizeSoftphoneCheck) {
            setStorageItem(Constant.lsMinimizeSoftphoneForCIFV2, true);
        }
        else {
            removeStorageItem(Constant.lsMinimizeSoftphoneForCIFV2);
        }
    });
}
function SpecifiedTypeOfSearchBasedOnConfigSetting() {
    const searchType = getSettingFromConfig("globalSearchType");
    if (searchType) {
        if (searchType.toLowerCase() == "categorized search") {
            return 1;
        }
        return 0;
    }
    return null;
}
export function hideEmbeddedChatIcon(shouldHide) {
    if (!shouldHide) {
        $("#openEmbeddedWindow > svg").hide();
        $("#popOutEmbeddedWindow > svg").hide();
    }
    else if (ShouldEmbeddedInteractionWindow()) {
        $("#openEmbeddedWindow > svg").show();
        $("#popOutEmbeddedWindow > svg").show();
    }
}
export function closeNotification(tokenid) {
    try {
        if (isCIFV2()) {
            window.Microsoft.CIFramework.cancelEvent(tokenid);
        }
    }
    catch {
        // nop
    }
}
export function manageInteractionSequence(interactionId, e) {
    const category = e.data.category;
    let data = getStorageItem(Constant.ssintStateSequence);
    if (data) {
        data = JSON.parse(data);
        let isFound = false;
        for (let i = 0; i < data.length; i++) {
            if (data[i].interactionId == interactionId) {
                isFound = true;
                if (data[i].interactionState.at(-1).toLowerCase() != category.toLowerCase()) {
                    if (e.data.interaction.disposition && data[i].interactionState.at(-1).toLowerCase() != "wrapup") {
                        data[i].interactionState.push("WrapUp");
                    }
                    else {
                        data[i].interactionState.push(category);
                    }
                    setStorageItem(Constant.ssintStateSequence, JSON.stringify(data));
                    log("interactionId state data : " + JSON.stringify(data));
                }
            }
        }
        if (!isFound) {
            data.push({ interactionId: interactionId, interactionState: [category] });
            setStorageItem(Constant.ssintStateSequence, JSON.stringify(data));
            log("interactionId state data : " + JSON.stringify(data));
        }
    }
    else {
        const intStates = [{ interactionId: interactionId, interactionState: [category] }];
        setStorageItem(Constant.ssintStateSequence, JSON.stringify(intStates));
        log("interactionId state data : " + JSON.stringify(data));
    }
}
function removeInteractionStateSequenceMapping(key, value) {
    let interactionStateMapping = getStorageItem(Constant.ssintStateSequence);
    if (interactionStateMapping != null && interactionStateMapping != "[]") {
        const isPresent = searchInJSON(JSON.parse(interactionStateMapping), key, value);
        if (isPresent.length > 0) {
            interactionStateMapping = JSON.parse(interactionStateMapping).filter(function (ele) {
                return ele.interactionId != isPresent[0].interactionId;
            });
            setStorageItem(Constant.ssintStateSequence, JSON.stringify(interactionStateMapping));
        }
    }
}
const debounceCallFunctionForupdateSessionContext = debounce(function (obj, interactionId) {
    FunctionsVariable.updateContextAttemptCount++;
    let isSessionIdPresent = null;
    let sessionInteractionMapping = getStorageItem(Constant.ssSessionMapping);
    sessionInteractionMapping = JSON.parse(sessionInteractionMapping);
    if (sessionInteractionMapping && sessionInteractionMapping.length > 0) {
        isSessionIdPresent = searchInJSON(sessionInteractionMapping, "interactionId", interactionId);
        if (isSessionIdPresent.length > 0) {
            window.Microsoft.CIFramework.updateContext(obj);
            FunctionsVariable.updateContextAttemptCount = 0;
        }
    }
    if (FunctionsVariable.updateContextAttemptCount > 0 && FunctionsVariable.updateContextAttemptCount < 20) {
        updateSessionContext(obj, interactionId);
    }
}, 2500);
function updateSessionContext(obj, interactionId) {
    debounceCallFunctionForupdateSessionContext(obj, interactionId);
}
export function setScriptIdInLS() {
    setStorageItem(Constant.lsForEmailOutbound, FunctionsVariable.scriptId);
}
function setClientIdBasedOnSelectedRegion(regionURL) {
    switch (regionURL) {
        case "https://apps.mypurecloud.com.au":
            setStorageItem(Constant.lsClientId, "cf581632-a0e6-4f09-8f72-8155f15d2040");
            setStorageItem(Constant.lsClientRegion, "mypurecloud.com.au");
            break;
        case "https://apps.mypurecloud.jp":
            setStorageItem(Constant.lsClientId, "8bc3f2fd-a2ac-4fcc-9f1b-04581acfdcfb");
            setStorageItem(Constant.lsClientRegion, "mypurecloud.jp");
            break;
        case "https://apps.mypurecloud.de":
            setStorageItem(Constant.lsClientId, "0f9ef94f-fa04-4318-863d-966268416131");
            setStorageItem(Constant.lsClientRegion, "mypurecloud.de");
            break;
        case "https://apps.mypurecloud.ie":
            setStorageItem(Constant.lsClientId, "8bf0eafa-b5c2-4fa2-8f05-db17418dd7d2");
            setStorageItem(Constant.lsClientRegion, "mypurecloud.ie");
            break;
        case "https://apps.mypurecloud.com":
            setStorageItem(Constant.lsClientId, "c641aa1d-9e06-4ffa-afca-44034b223ac2");
            setStorageItem(Constant.lsClientRegion, "mypurecloud.com");
            break;
        case "https://apps.usw2.pure.cloud":
            setStorageItem(Constant.lsClientId, "8cda3730-67e1-4457-a8c0-c49122e9e813");
            setStorageItem(Constant.lsClientRegion, "usw2.pure.cloud");
            break;
        case "https://apps.euw2.pure.cloud":
            setStorageItem(Constant.lsClientId, "8cda3730-67e1-4457-a8c0-c49122e9e813");
            setStorageItem(Constant.lsClientRegion, "euw2.pure.cloud");
            break;
        case "https://apps.apne2.pure.cloud":
            setStorageItem(Constant.lsClientId, "8cda3730-67e1-4457-a8c0-c49122e9e813");
            setStorageItem(Constant.lsClientRegion, "apne2.pure.cloud");
            break;
        case "https://apps.cac1.pure.cloud":
            setStorageItem(Constant.lsClientId, "8cda3730-67e1-4457-a8c0-c49122e9e813");
            setStorageItem(Constant.lsClientRegion, "cac1.pure.cloud");
            break;
        case "https://apps.aps1.pure.cloud":
            setStorageItem(Constant.lsClientId, "8cda3730-67e1-4457-a8c0-c49122e9e813");
            setStorageItem(Constant.lsClientRegion, "aps1.pure.cloud");
            break;
    }
}
function removewrapup(interactionId, ssItem) {
    const ssItemData = getStorageItem(ssItem);
    if (ssItemData == null || ssItemData == "[]")
        return;
    const itemData = JSON.parse(ssItemData);
    const itemDataList = Object.values(itemData);
    for (let i = 0; i < itemDataList.length; i++) {
        if (itemDataList[i] == interactionId) {
            delete itemData.dispositionValue;
            console.log(itemData);
            setStorageItem(ssItem, JSON.stringify(itemData));
            break;
        }
    }
}
function popOutInteractionWindow() {
    const EmbeddedInteractionWindowUrl = getParticipantCustomAttribute("embeddedWindowUrl");
    Global.openedWindow = window.open(EmbeddedInteractionWindowUrl, "targetWindow", "resizable=yes,width=680,height=579");
    hideEmbeddedInteractionWindow();
    hitStorageHandlerToCloseEmbeddedWindow();
}
function hitStorageHandlerToCloseEmbeddedWindow() {
    const embeddedWindowCheckValue = Math.random();
    Global.embeddedWindowkRequestValue = embeddedWindowCheckValue;
    setStorageItem(Constant.lsEmbeddedWindowOpenRequest, embeddedWindowCheckValue);
}
function checkAllowScreenpopQueue(screenPopSearchData) {
    let createScreenPopArrayBasedOnValue = [];
    if (screenPopSearchData.allowscreenpop) {
        createScreenPopArrayBasedOnValue = screenPopSearchData.entity;
    }
    return createScreenPopArrayBasedOnValue;
}
function checkAllowScreenpop(interactionType, screenpopSearchData) {
    let createScreenPopArrayBasedOnValue = [];
    switch (interactionType.toLowerCase()) {
        case "phonecall":
            if (Object.hasOwn(screenpopSearchData.phonecall, "allowscreenpop")) {
                if (screenpopSearchData.phonecall.allowscreenpop) {
                    createScreenPopArrayBasedOnValue = screenpopSearchData.phonecall.entity;
                }
            }
            else {
                createScreenPopArrayBasedOnValue = screenpopSearchData.phonecall;
            }
            return createScreenPopArrayBasedOnValue;
        case "chat":
            if (Object.hasOwn(screenpopSearchData.chat, "allowscreenpop")) {
                if (screenpopSearchData.chat.allowscreenpop) {
                    createScreenPopArrayBasedOnValue = screenpopSearchData.chat.entity;
                }
            }
            else {
                createScreenPopArrayBasedOnValue = screenpopSearchData.chat;
            }
            return createScreenPopArrayBasedOnValue;
        case "callback":
            if (Object.hasOwn(screenpopSearchData.callback, "allowscreenpop")) {
                if (screenpopSearchData.callback.allowscreenpop) {
                    createScreenPopArrayBasedOnValue = screenpopSearchData.callback.entity;
                }
            }
            else {
                createScreenPopArrayBasedOnValue = screenpopSearchData.callback;
            }
            return createScreenPopArrayBasedOnValue;
        case "email":
            if (Object.hasOwn(screenpopSearchData.email, "allowscreenpop")) {
                if (screenpopSearchData.email.allowscreenpop) {
                    createScreenPopArrayBasedOnValue = screenpopSearchData.email.entity;
                }
            }
            else {
                createScreenPopArrayBasedOnValue = screenpopSearchData.email;
            }
            return createScreenPopArrayBasedOnValue;
        case "message":
            if (Object.hasOwn(screenpopSearchData.message, "allowscreenpop")) {
                if (screenpopSearchData.message.allowscreenpop) {
                    createScreenPopArrayBasedOnValue = screenpopSearchData.message.entity;
                }
            }
            else {
                createScreenPopArrayBasedOnValue = screenpopSearchData.message;
            }
            return createScreenPopArrayBasedOnValue;
    }
}
function setLastAssociationObjInSessionStorage(associationObj) {
    let objData = getStorageItem(Constant.sSEntityLastAssociationObj);
    const interactionId = associationObj.interactionID.replace(/[{}]/g, "");
    const regardingEntityType = associationObj.regardingentityname.toString().trim();
    if (objData == "" || objData == null) {
        const associationArrayForDeletion = [];
        associationArrayForDeletion.push([{ entityType: regardingEntityType, interactionId: interactionId }]);
        setStorageItem(Constant.sSEntityLastAssociationObj, JSON.stringify(associationArrayForDeletion));
    }
    else {
        let isFound = false;
        objData = JSON.parse(objData);
        for (let i = 0; i < objData.length; i++) {
            if (objData[i][0].interactionId == interactionId) {
                objData[i][0].entityType = regardingEntityType;
                isFound = true;
                break;
            }
        }
        if (!isFound) {
            objData.push([{ entityType: regardingEntityType, interactionId: interactionId }]);
        }
        setStorageItem(Constant.sSEntityLastAssociationObj, JSON.stringify(objData));
    }
}
function getEntityTypeByInteractionIdForDisAssociation(associationObj) {
    let objData = getStorageItem(Constant.sSEntityLastAssociationObj);
    const interactionId = associationObj.interactionID.replace(/[{}]/g, "");
    objData = JSON.parse(objData);
    if (objData) {
        for (let i = 0; i < objData.length; i++) {
            const isSelectedInteraction = searchInJSON(objData[i], "interactionId", interactionId);
            if (isSelectedInteraction.length > 0) {
                return isSelectedInteraction[0].entityType;
            }
        }
        return null;
    }
}
function removeRegardingAssociationFromSessionStorage(interactionId) {
    const getAssociationData = getStorageItem(Constant.sSEntityLastAssociationObj);
    if (getAssociationData) {
        const getJsonAssociationData = JSON.parse(getAssociationData);
        for (let i = 0; i < getJsonAssociationData.length; i++) {
            if (interactionId == getJsonAssociationData[i][0].interactionId)
                getJsonAssociationData.splice(i, 1);
        }
        setStorageItem(Constant.sSEntityLastAssociationObj, JSON.stringify(getJsonAssociationData));
    }
}
export function createActivityTabBeforeDeallocation(interactionId, entityType, entityId, isFocused) {
    getFocusedSession().done(function success(sessionId) {
        manageCreatedSessionRecordIdIntoSessionStorage(sessionId, interactionId);
        createTabForActivity(Constant.applicationTabTemplateName, interactionId, entityType, entityId, isFocused);
    });
}
function checkInteractionTypeIsMessage(interaction) {
    if (interaction.isMessage && Object.hasOwn(interaction, "messageType")) {
        switch (interaction.messageType.toLowerCase()) {
            case "webmessaging":
                return "WebMessaging";
            case "facebook":
                return "Facebook";
            case "whatsapp":
                return "WhatsApp";
            case "sms":
                return "SMS";
            case "line":
                return "Line";
            case "open":
                return "Open";
            default:
                return null;
        }
    }
}
export function storeGenesysOrgNameInWindowObject(e) {
    window.ISYNCANALYTICS = {
        userId: e?.id,
        organizationId: e?.organization?.id,
        organizationName: e?.organization?.name
    };
    dispatchAnalyticsStartEvent();
}
export function dispatchAnalyticsStartEvent() {
    window.dispatchEvent(new CustomEvent("ISYNCANALYTICS_START"));
}
export function dispatchAnalyticsStopEvent() {
    window.dispatchEvent(new CustomEvent("ISYNCANALYTICS_STOP"));
}
export { debounceCallFunctionForloadEmbeddedInteractionWindow, debounceCallFunctionForHideshowIframeInteractiveWindow, debounceCallFunctionForClicktodialForActionRequest };
