import { log } from "./functions";
import { addAssociation, addHandlerForCifV2, checkIsRenderSearchInCache, checkingMouseClickElement, checklsNewBlankRecordPopForSoftPhoneToAllTabFalse, createDynamicSceenpopSearchFunctionArray, createSession, executeAssociation, executeOnUsd, fetchItemsFromGetEnvironmentForLogs, fillNumberAndQueueOutboundCall, getEnvironment, getFocusedSession, getSettingFromConfig, getStorageItem, getUserDynamicsInstanceInformation, isActiveInteractionExists, isCIFV2, isRunningOnUSD, loadEmbeddableFrameworkPage, loadISyncSolution, makeCall, miniClientTabOpen, onClickToAct, onModeChanged, onPageNavigate, removeAllSessionAndRelatedTabs, removeAssociationObjectFromNewPopWindow, removeStorageItem, returnErrorMessageForApiFailed, setIsCifV2Value, setScriptIdInLS, setSoftPhonePanelMode, setStorageItem, shouldAssociateForNewPage, shouldRemoveSidebarFromAnotherTab } from "./functions";
import { isConfigurationLoaded } from "./partner";
import Constant from "./variables/constants";
import FunctionVariable from "./variables/functions";
import Global from "./variables/global";
import RootVariable from "./variables/root";
import $ from "jquery";
function is3rdpartycookieblocked() {
    try {
        localStorage;
        return false;
    }
    catch (e) {
        return true;
    }
}
function loadScripts() {
    let srcValue = "";
    RootVariable.CrmOrigin = "";
    if (location.ancestorOrigins != null && location.ancestorOrigins != undefined && location.ancestorOrigins[0] != undefined) {
        RootVariable.CrmOrigin = location.ancestorOrigins[0];
    }
    else {
        const filtered = document.referrer.split("/").filter(function (el) {
            return el != null && el != "";
        });
        RootVariable.CrmOrigin = filtered[0] + "//" + filtered[1];
    }
    srcValue = RootVariable.CrmOrigin + "//WebResources/Widget/msdyn_ciLibrary.js";
    $.ajaxSetup({
        cache: true
    });
    log("loading msdyn script:" + srcValue);
    const ajaxSettings = {
        dataType: "script",
        url: srcValue,
        attrs: { "data-crmurl": RootVariable.CrmOrigin, "data-cifid": "CIFMainLibrary" }
    };
    $.ajax(ajaxSettings)
        .done(function () {
        log("msdyn script getScript complete");
    })
        .fail(function () {
        showLoadScriptErrors("getScript exception thrown loading url: " + srcValue);
    });
}
export function CIFrameworkLoaded() {
    log("checking ciframework ready");
    if (window.Microsoft && window.Microsoft.CIFramework && window.Microsoft.CIFramework.setClickToAct) {
        RootVariable.ciLoaded = true;
        log("ciframework ready");
        processInitAll();
    }
    else {
        if (RootVariable.loadScriptsCounter < 100) {
            setTimeout(function () {
                RootVariable.loadScriptsCounter++;
                CIFrameworkLoaded();
            }, 100);
        }
        else {
            showLoadScriptErrors("CIFramework libary not defined");
        }
    }
}
function showLoadScriptErrors(exceptionMessage) {
    showMessagePanel(true, "Failed to load cilibrary numerous times. Please contact your system administrator. " + exceptionMessage);
}
export function showMessagePanel(error, message) {
    $("#isyncmessagepanel").html(message);
    let className = null;
    if (error) {
        className = $("#isyncmessagepanel")[0].className;
        $("#isyncmessagepanel").removeClass(className).addClass("errorMessage");
    }
    else {
        className = $("#isyncmessagepanel")[0].className;
        $("#isyncmessagepanel").removeClass(className).addClass("infoMessage");
    }
    log("showMessagePanel:" + error + ":" + message);
    $("#isyncmessagepanel").show();
}
export function addDynamicsCrmURLtoParams(url) {
    let newUrl = url;
    if (url.indexOf("?") != -1) {
        newUrl += "&dynamicsurl=";
    }
    else {
        newUrl += "?dynamicsurl=";
    }
    newUrl += encodeURIComponent(window.location.href);
    return newUrl;
}
function initAll() {
    try {
        window.Microsoft.CIFramework.setClickToAct(true);
        window.Microsoft.CIFramework.addHandler("onclicktoact", onClickToAct);
        window.Microsoft.CIFramework.addHandler("onpagenavigate", onPageNavigate);
        window.Microsoft.CIFramework.addHandler("onmodechanged", onModeChanged);
        fetchItemsFromGetEnvironmentForLogs();
        if (isConfigurationLoaded()) {
            log("loaded config other tab. ISync Version:" + getStorageItem(Constant.ssSolutionVersion));
            if (isSidebarHtmlLoad()) {
                loadEmbeddableFrameworkPage();
                miniClientTabOpen();
            }
            if (getStorageItem(Constant.lsCheckSetModeValue) != "0") {
                setSoftPhonePanelMode(1);
            }
            else {
                if ($("#isyncmessagepanel").is(":visible")) {
                    $("#isyncmessagepanel").hide();
                }
                setSoftPhonePanelMode(0);
            }
            checkIsRenderSearchInCache();
            createDynamicSceenpopSearchFunctionArray();
        }
        else {
            getFocusedSession()
                .done(function success(results) {
                setIsCifV2Value(results);
                if (isCIFV2()) {
                    log("CIFv2");
                    addHandlerForCifV2();
                    // TODO: I think this was intended to be called in "beforeunload" but was actually working like this.  We'll leave as is for now.
                    removeAllSessionAndRelatedTabs();
                    // window.addEventListener("beforeunload", removeAllSessionAndRelatedTabs());
                    const inputSessionData = {
                        templateName: "isgc_defaultSessionTemplate",
                        templateParameters: {}
                    };
                    createSession(inputSessionData)
                        .done(function success(sessionId) {
                        sessionStorage.setItem(Constant.ssDefaultSessionId, sessionId);
                        loadISyncSolution();
                    })
                        .fail(function (error) {
                        error = returnErrorMessageForApiFailed(error);
                        log(error);
                    });
                }
                else {
                    log("CIFv1");
                    loadISyncSolution();
                }
            })
                .fail(function (error) {
                error = returnErrorMessageForApiFailed(error);
                log(error);
            });
        }
        getUserDynamicsInstanceInformation();
    }
    catch (e) {
        log(e.message);
    }
}
//cache--start
function storageHandler(e) {
    switch (e.key) {
        case Constant.lsSidebarCheckRequest:
            if (isSidebarHtmlLoad() === true) {
                setStorageItem(Constant.lsSidebarCheckResponse, e.newValue);
                if (isConfigurationLoaded()) {
                    setStorageItem(Constant.lsConfigurationCache, JSON.stringify(window.participantCustomAttribute));
                }
                if (getStorageItem(Constant.ssSolutionVersion)) {
                    setStorageItem(Constant.lsVersionCache, getStorageItem(Constant.ssSolutionVersion));
                }
            }
            break;
        case Constant.lsSidebarCheckResponse:
            if (FunctionVariable.sideBarCheckValue == e.newValue) {
                if (getStorageItem(Constant.lsConfigurationCache) != null) {
                    window.participantCustomAttribute = JSON.parse(getStorageItem(Constant.lsConfigurationCache));
                }
                if (getStorageItem(Constant.lsVersionCache) != null) {
                    setStorageItem(Constant.ssSolutionVersion, getStorageItem(Constant.lsVersionCache));
                }
                FunctionVariable.sideBarCheckValue = 0;
                clearTimeout(FunctionVariable.noResponseTimeout);
                if (shouldShowSoftphoneOnSingleTab()) {
                    const isPopNewPage = getStorageItem(Constant.lsPopNewPage);
                    if (shouldAssociateForNewPage(isPopNewPage)) {
                        addAssociation();
                    }
                    shouldRemoveSidebarFromAnotherTab();
                }
            }
            break;
        case Constant.lsSendMakeCallToSoftphoneTab:
            //Make call from another softphone tab
            if (e.newValue != null && e.newValue != "") {
                setStorageItem(Constant.lsSendMakeCallToSoftphoneTab, "");
                if (isSidebarHtmlLoad()) {
                    const message = JSON.parse(e.newValue);
                    FunctionVariable.makeCallFromOtherTabPageInfo = message.pageInfo;
                    const callRequestID = message.callRequestID;
                    fillNumberAndQueueOutboundCall(message.number, null);
                    makeCall(message.number, null, true, callRequestID);
                }
            }
            break;
        case Constant.lsSendMakeCallToSoftphoneResponse:
            //response from primary tab with interaction id
            if (e.newValue != null && e.newValue != "") {
                setStorageItem(Constant.lsSendMakeCallToSoftphoneResponse, "");
                setStorageItem(Constant.ssMakeCallFromOtherTabInteractionId, e.newValue);
            }
            break;
        case Constant.lsSendPageChangeToSoftphoneTab:
            if (e.newValue != null && e.newValue != "") {
                setStorageItem(Constant.lsSendPageChangeToSoftphoneTab, "");
                const message = JSON.parse(e.newValue);
                if (isSidebarHtmlLoad()) {
                    executeAssociation(JSON.stringify(message.pageInfo), message.interaction, false);
                }
            }
            break;
        case Constant.lsClearMakeCallInteractionId:
            //Request sent from primary tab to remove the interaction from the cache of the related session
            if (e.newValue != null && e.newValue != "") {
                if (getStorageItem(Constant.ssMakeCallFromOtherTabInteractionId) == e.newValue) {
                    setStorageItem(Constant.ssMakeCallFromOtherTabInteractionId, null);
                    setStorageItem(Constant.lsClearMakeCallInteractionId, "");
                }
            }
            break;
        case Constant.lsRemovedAssociationFromSession:
            //Remove association from session storage from new popped window...
            if (e.newValue) {
                const interactionId = e.newValue;
                removeAssociationObjectFromNewPopWindow(interactionId);
            }
            break;
        case Constant.lsEmbeddedWindowOpenRequest:
            if (isSidebarHtmlLoad() === true && Global.openedWindow && Global.embeddedWindowkRequestValue == e.oldValue) {
                Global.openedWindow.close();
            }
            break;
    }
}
export function isSidebarHtmlLoad() {
    let foundIsyncFrame = true;
    if ($("#isynccif").length == 0) {
        foundIsyncFrame = false;
    }
    return foundIsyncFrame;
}
export function shouldShowSoftphoneOnSingleTab() {
    if (isCIFV2()) {
        return true;
    }
    else {
        const showSoftphoneOnAllTabs = getSettingFromConfig("showsoftphoneonalltabs");
        return showSoftphoneOnAllTabs == true || showSoftphoneOnAllTabs == "true" ? false : true;
    }
}
function sidebarCheck() {
    FunctionVariable.sideBarCheckValue = Math.random();
    setStorageItem(Constant.lsSidebarCheckRequest, FunctionVariable.sideBarCheckValue);
}
export function showSidebar() {
    setNewPageSidebarId();
}
function setNewPageSidebarId() {
    let globalPageId = getGlobalPageId();
    if (globalPageId == "") {
        globalPageId = Date.now();
        const hiddenValue = "<input id='globalpageid' type='hidden' value='" + globalPageId + "'>";
        $("body").append(hiddenValue);
    }
    setStorageItem(Constant.lsSidebarPageId, globalPageId);
}
function getGlobalPageId() {
    const eleStrip = $("body").find("#globalpageid")[0];
    if (eleStrip != null && eleStrip != undefined) {
        return $(eleStrip).val();
    }
    else {
        return "";
    }
}
$(window).on("beforeunload", function () {
    if (shouldShowSoftphoneOnSingleTab()) {
        const sidebarPageId = getStorageItem(Constant.lsSidebarPageId);
        if (sidebarPageId != null && typeof sidebarPageId != "undefined") {
            if (isSidebarHtmlLoad()) {
                const globalPageId = getGlobalPageId();
                if (globalPageId != "") {
                    if (parseInt(globalPageId) == parseInt(sidebarPageId))
                        removeStorageItem(Constant.lsSidebarPageId);
                }
            }
        }
    }
    $("#isyncmessagepanel").hide();
    window.participantCustomAttribute = null;
});
//cache--end
window.addEventListener("beforeunload", function (e) {
    if (isSidebarHtmlLoad() && !isCIFV2() && isActiveInteractionExists()) {
        e.preventDefault();
        e.returnValue = "";
    }
});
export function onDocumentReady() {
    try {
        setIsUSD();
        if (is3rdpartycookieblocked()) {
            showMessagePanel(true, "Cookies are disabled and InteractionSync needs access to cookies to function.  Contact your system administrator");
        }
        else {
            loadScripts();
            setScriptIdInLS();
            window.addEventListener("storage", storageHandler, false);
            window.addEventListener("visibilitychange", setScriptIdInLS);
            sidebarCheck();
            checklsNewBlankRecordPopForSoftPhoneToAllTabFalse();
        }
    }
    catch (error) {
        log("Erorr intializing", error);
    }
}
function getBaseURL(currentUrl) {
    try {
        const pathArray = currentUrl.split("/");
        if (pathArray.length >= 2) {
            return pathArray[0] + "//" + pathArray[2];
        }
    }
    catch (e) {
        // nop
    }
    return null;
}
export function matchesCurrentCRMSite(origin) {
    const hostname = window.location != window.parent.location ? document.referrer : document.location.href;
    return getBaseURL(hostname) == origin;
}
function setIsUSD() {
    if (window.location.search.trim() != "") {
        const separators = ["\\?isusd="];
        const queryStr = window.location.search
            .trim()
            .toLowerCase()
            .split(new RegExp(separators.join("|"), "g"));
        if (queryStr.length > 1) {
            setStorageItem(Constant.ssIsUSD, queryStr[1]);
            document.body.style.overflow = "hidden";
        }
    }
}
export function reloadSidebar() {
    executeOnUsd("http://uii/" + Constant.usdControlSupportWindow + "/RunScript?location.reload()");
    executeOnUsd("http://uii/" + Constant.usdControlSidebar + "/RunScript?location.reload()");
}
function processInitAll() {
    if (isRunningOnUSD()) {
        getEnvironment()
            .done(function (data) {
            log("RunningOnUSD: Successfull getEnvironment - " + data);
            initAll();
        })
            .fail(function (error) {
            if (FunctionVariable.getEnvironmentFailCounter < 5 && error.indexOf("Timeout occurred as no response was received") != -1) {
                log("RunningOnUSD: Failed getEnvironment, Calling Again, FailCounter - " + FunctionVariable.getEnvironmentFailCounter);
                FunctionVariable.getEnvironmentFailCounter++;
                processInitAll();
            }
            else {
                $("#isyncusderror").show();
                log("RunningOnUSD: Failed getEnvironment, error - " + error);
            }
        });
    }
    else {
        initAll();
    }
}
document.addEventListener("click", function (e) {
    //for hide custom dial's reault list if click outside
    checkingMouseClickElement(e.target);
});
