// Dynamics Entity Names
const phonecallName = "phonecall";
const phonecallsName = "phonecalls";
const phonecallSchemaName = "PhoneCall";
const callbackName = "isgc_callback";
const callbacksName = "isgc_callbacks";
const callbackSchemaName = "isgc_callback";
const chatName = "isgc_chat";
const chatsName = "isgc_chats";
const chatSchemaName = "isgc_chat";
const emailName = "isgc_email";
const emailsName = "isgc_emails";
const emailSchemaName = "isgc_email";
const messageName = "isgc_message";
const messagesName = "isgc_messages";
const messageSchemaName = "isgc_message";
const webMessageName = "isgc_webmessaging";
const webMessagesName = "isgc_webmessagings";
const webMessageSchemaName = "isgc_webmessaging";
const defaultEntityCollection = ["phonecall", "isgc_message", "isgc_email", "isgc_chat", "isgc_callback", "isgc_webmessaging"];
// HTML Elements
const isyncCIF = "isynccif";
// Session/Local Storage Names
const ssPostMessages = "isgc_postMessages";
const lsNewInteractionId = "isgc_ls_newInteractionId";
const lsCurrentInteractionId = "isgc_ls_currentInteractionId";
const ssGlobalSearchValue = "isgc_globalSearchValue";
const lsActivityData = "isgc_ls_activityData";
const ssHasChatTranscript = "isgc_hasChatTranscript";
const ssCreatingActivity = "isgc_creatingActivity";
const ssUserId = "isgc_userId";
const ssOrgName = "isgc_orgName";
const ssURL = "isgc_URL";
const ssActivityDescription = "isgc_lastDescription";
const ssSolutionVersion = "isgc_solutionversion";
const ssSolutionVersionFull = "isgc_solutionversionfull";
const lsActivityEntityCache = "isgc_ls_activityEntity";
const ssActivityChatTranscriptCache = "isgc_activityChatTranscript";
const ssAssociationObject = "isgc_associationObject";
const ssAuthToken = "isgc_authToken";
const ssScreenPopEvent = "isgc_screenPopEvent";
const ssRenderSearchParams = "isgc_renderSearchParams";
const ssShouldCallAddAssociation = "isgc_shouldCallAddAssociation";
const lsIsSidebarLogIn = "isgc_ls_isSidebarLogin";
const lsSidebarPageId = "isgc_ls_sidebarpageid";
const lsSidebarCheckRequest = "isgc_ls_sidebarCheckRequest";
const lsSidebarCheckResponse = "isgc_ls_sidebarCheckResponse";
const lsSendMakeCallToSoftphoneTab = "isgc_ls_sendMakeCallToSoftphoneTab";
const lsSendMakeCallToSoftphoneResponse = "isgc_ls_sendMakeCallToSoftphoneResponse";
const lsSendPageChangeToSoftphoneTab = "isgc_ls_sendPageChangeToSoftphoneTab";
const lsClearMakeCallInteractionId = "isgc_ls_clearMakeCallInteractionId";
const ssLastRequestID = "isgc_lastRequestID";
const ssMakeCallFromOtherTabInteractionId = "isgc_makeCallFromOtherTabInteractionId";
const ssNotifyInteractionState = "isgc_notifyInteractionState";
const ssFocusedSessionId = "isgc_focusedSessionId";
const ssSessionSwitchedInteractionState = "isgc_sessionSwitchedInteractionState";
const ssInteractionStates = "isgc_interactionStates";
const lsPopNewPage = "isgc_ls_popnewpage";
const ssPopNewPage = "isgc_popnewpage";
const ssCheckAssociationObject = "isgc_checkAssociationObject";
const lsConfigurationCache = "isgc_ls_configurationCache";
const lsVersionCache = "isgc_ls_versionCache";
const ssDefaultSessionId = "isgc_defaultSessionId";
const lsStoreIntercationIdForAssocitionFromNewWindow = "isgc_ls_storeIntercationIdForAssocitionFromNewWindow";
const entityRecordSessionTemplateName = "isgc_recordSessionTemplate";
const entitySearchSessionTemplateName = "isgc_searchSessionTemplate";
const webresourceSessionTemplate = "isgc_openWebResourceSession";
const notificationTemplate = "isgc_incomingCallNotification";
const applicationTabTemplateName = "isgc_entityRecord";
const openWebResourcTemplate = "isgc_openWebResourceUrl";
const ssTabMapping = "isgc_TabId";
const ssSessionMapping = "isgc_SessionMapping";
const fullClientIframe = "#fullclientiframe";
const fullClient = "#fullclient";
const lsIsCIFv2 = "isgc_ls_cifv2";
const ssCompareDataObject = "isgc_compareDataObject";
const ssActualStartSet = "isgc_actualstartset";
const ssCurrentTabAssociation = "isgc_currentTabAssociation";
const lsRemovedAssociationFromSession = "isgc_ls_removedAssociationFromSession";
const ssCheckDispositionObj = "isgc_checkDispositionObj";
const defaultSessionTemplate = "isgc_defaultSessionTemplate";
const lsCounterDyanmicsPageWithSoftphone = "isgc_ls_counterDyanmicsPageWithSoftphone";
const ssGenesysUserId = "isgc_genesysUserId";
const ssGenesysAccountId = "isgc_genesysAccountId";
const ssGenesysUserName = "isgc_genesysUserName";
const ssLoadToLoginPageLoadTimeInSeconds = "isgc_loadToLoginPageLoadTimeInSeconds";
const lsnewBlankRecordPop = "isgc_ls_newBlankRecordPop";
const lsNewBlankRecordPopForSoftPhoneToAllTabFalse = "isgc_ls_newBlankRecordPopForSoftPhoneToAllTabFalse";
const lsCollectionInteractionIdsForSoftPhToAllTabFalse = "isgc_ls_collectionInteractionIdsForSoftPhToAllTabFalse";
const lsProcessedNewInteractionIds = "isgc_ls_processedNewInteractionIds";
const ssPopActivityOnSave = "isgc_popactivityonsave";
const ssIsCallTransferredToPostCallSurvey = "isgc_iscalltransferredtopostcallsurvey_";
const lsConfigurationSetting = "isgc_ls_configurationsetting";
const lsCheckSetModeValue = "isgc_ls_checkSetModeValue";
const isPromptForQueue = "isgc_ls_isPromptForQueue";
const ssSessionTabArrayInfo = "isgc_sessionTabArrayInfo";
const ssActivityWebMessagingTranscriptCache = "isgc_activityWebMessagingTranscriptCache";
const ssMessagingInteractionStartTime = "isgc_messagingInteractionStartTime";
const ssMessagingInteractionSubject = "isgc_messagingInteractionSubject";
const ssintStateSequence = "isgc_intStateSequence";
const lsClientId = "isgc_ls_clientId";
const lsClientRegion = "isgc_ls_clientRegion";
const sSEntityLastAssociationObj = "isgc_sSEntityLastAssociationObj";
const lsEmbeddedWindowOpenRequest = "isgc_ls_embeddedWindowOpenRequest";
const lsForEmailOutbound = "isgc_ls_forEmailOutbound";
const lsMinimizeSoftphoneForCIFV2 = "isgc_ls_minimizeSoftphoneForCIFV2";
const ssUserQueueList = "isgc_ss_userQueueList";
//USD Session/Control/Event/Action Names
const ssIsUSD = "isgc_IsUsd";
const ssInteractionData = "isgc_InteractionData";
const ssCurrentSessionUSD = "isgc_CurrentSessionUSD";
const usdEventInteractionWindow = "ISUSD_InteractionWindowEvent";
const usdControlSidebar = "IsyncUSD";
const usdControlSupportWindow = "IsyncUSD Support";
const usdControlInteractionWindow = "ISUSD_InteractionWindow";
const usdControlCTIListener = "ISUSD_CTIListener";
const usdEventNewSessionSearch = "ISUSD_NewSessionSearchEvent";
const usdEventNewSessionRecord = "ISUSD_NewSessionRecordEvent";
const usdEventSwitchSessionToOpenActivity = "ISUSD_SwitchSessionToOpenActivityEvent";
const usdEventNewSessionNewRecord = "ISUSD_NewSessionNewRecordEvent";
//CIFv2 Session Context/Slug
const sessionContextInteractionData = "isyncContextInteractionData";
const sessionContextActivityId = "isyncContextActivityId";
const commitHash = __COMMIT_HASH__;
const constants = {
    phonecallName,
    phonecallsName,
    phonecallSchemaName,
    callbackName,
    callbacksName,
    callbackSchemaName,
    chatName,
    chatsName,
    chatSchemaName,
    emailName,
    emailsName,
    emailSchemaName,
    messageName,
    messagesName,
    messageSchemaName,
    webMessageName,
    webMessagesName,
    webMessageSchemaName,
    defaultEntityCollection,
    isyncCIF,
    ssPostMessages,
    lsNewInteractionId,
    lsCurrentInteractionId,
    ssGlobalSearchValue,
    lsActivityData,
    ssHasChatTranscript,
    ssCreatingActivity,
    ssUserId,
    ssOrgName,
    ssURL,
    ssActivityDescription,
    ssSolutionVersion,
    ssSolutionVersionFull,
    lsActivityEntityCache,
    ssActivityChatTranscriptCache,
    ssAssociationObject,
    ssAuthToken,
    ssScreenPopEvent,
    ssRenderSearchParams,
    ssShouldCallAddAssociation,
    lsIsSidebarLogIn,
    lsSidebarPageId,
    lsSidebarCheckRequest,
    lsSidebarCheckResponse,
    lsSendMakeCallToSoftphoneTab,
    lsSendMakeCallToSoftphoneResponse,
    lsSendPageChangeToSoftphoneTab,
    lsClearMakeCallInteractionId,
    ssLastRequestID,
    ssMakeCallFromOtherTabInteractionId,
    ssNotifyInteractionState,
    ssFocusedSessionId,
    ssSessionSwitchedInteractionState,
    ssInteractionStates,
    lsPopNewPage,
    ssPopNewPage,
    ssCheckAssociationObject,
    lsConfigurationCache,
    lsVersionCache,
    ssDefaultSessionId,
    lsStoreIntercationIdForAssocitionFromNewWindow,
    entityRecordSessionTemplateName,
    entitySearchSessionTemplateName,
    webresourceSessionTemplate,
    notificationTemplate,
    applicationTabTemplateName,
    openWebResourcTemplate,
    ssTabMapping,
    ssSessionMapping,
    fullClientIframe,
    fullClient,
    lsIsCIFv2,
    ssCompareDataObject,
    ssActualStartSet,
    ssCurrentTabAssociation,
    lsRemovedAssociationFromSession,
    ssCheckDispositionObj,
    defaultSessionTemplate,
    lsCounterDyanmicsPageWithSoftphone,
    ssGenesysUserId,
    ssGenesysAccountId,
    ssGenesysUserName,
    ssLoadToLoginPageLoadTimeInSeconds,
    lsnewBlankRecordPop,
    lsNewBlankRecordPopForSoftPhoneToAllTabFalse,
    lsCollectionInteractionIdsForSoftPhToAllTabFalse,
    lsProcessedNewInteractionIds,
    ssPopActivityOnSave,
    ssIsCallTransferredToPostCallSurvey,
    lsConfigurationSetting,
    lsCheckSetModeValue,
    isPromptForQueue,
    ssSessionTabArrayInfo,
    ssActivityWebMessagingTranscriptCache,
    ssMessagingInteractionStartTime,
    ssMessagingInteractionSubject,
    ssintStateSequence,
    lsClientId,
    lsClientRegion,
    sSEntityLastAssociationObj,
    lsEmbeddedWindowOpenRequest,
    lsForEmailOutbound,
    ssUserQueueList,
    ssIsUSD,
    ssInteractionData,
    ssCurrentSessionUSD,
    usdEventInteractionWindow,
    usdControlSidebar,
    usdControlSupportWindow,
    usdControlInteractionWindow,
    usdControlCTIListener,
    usdEventNewSessionSearch,
    usdEventNewSessionRecord,
    usdEventSwitchSessionToOpenActivity,
    usdEventNewSessionNewRecord,
    sessionContextInteractionData,
    sessionContextActivityId,
    commitHash,
    lsMinimizeSoftphoneForCIFV2
};
export default constants;
